import React, { useState, useContext } from 'react';
import Classes from './LicenseAssignmentModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const LicenseAssignmentModal = props => {
	const [blackboxSn, setBlackboxSn] = useState(null);
	const [isProcessingRequest, setIsProcessingRequest] = useState(true);
	const { ShowModal } = useContext(LoadingModalContext);

	const handleChange = e => {
		setBlackboxSn(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.assignModalHandler();
		ShowModal(props.license.BindBlackbox(blackboxSn), CurrentDict.LicenseAssign);
	};

	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.assignModalHandler} centered dir='rtl'>
			<Modal.Header className={Classes.close} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>שיוך רישיון לקופסה</div>
				<form className={Classes.modalForm} onSubmit={handleSubmit}>
					<div>
						<select name='blackboxSn' className={Classes.input} defaultValue={blackboxSn ?? ''} placeholder={'מספר סידורי של הקופסה'} onChange={handleChange} required>
							<option value='' disabled>
								{'בחר'}
							</option>
							{props.blackboxesDropdownList()}
						</select>
					</div>
					<Button variant='outline-primary' type='submit' className={Classes.button}>
						{'אישור'}
					</Button>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default LicenseAssignmentModal;
