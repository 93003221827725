import React, { Component } from "react";
import Spinner from "../../UI/Spinner/Spinner";
import { Notification } from "../../../DataTypes/Notification";
import Classes from "./NotificationList.module.scss";

/**
 * Does what NotificationList should do!
 */
class NotificationList extends Component {
	state = {};

	props = {
		/**@type {Notification[]} */
		notifications: null,

		/**@type {(n: Notification) => JSX.Element} */
		notificationToItemViewFunc: null,
	};

	/**
	 * Notifications array to display
	 * If null would show the spinner
	 * @type {Notification[]}
	 */
	notifications = null;

	/**@param {Readonly<any>}  props*/
	constructor(props) {
		super(props);
		this.props = props;
		this.notifications = this.props.notifications;
	}

	shouldComponentUpdate(nextProps) {
		this.notifications = nextProps.notifications;
		return true;
	}

	_PopulateNotifications() {
		if (this.notifications.length == 0) {
			return <div style={{ textAlign: "center", fontWeight: "bold" }}>אין התראות חדשות להצגה</div>;
		}

		let controlNotifications = this.notifications.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime()).map(this.props.notificationToItemViewFunc);
		if (controlNotifications !== null) {
			return controlNotifications
		}
	}

	render() {
		if (this.notifications == null) {
			return <Spinner />;
		}

		return (
			<div className={Classes.main}>
				<div className={Classes.main}>{this._PopulateNotifications()}</div>
			</div>
		);
	}
}

export default NotificationList;
