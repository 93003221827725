import React, { useState, useContext } from 'react';

import Classes from './BBAssignmentModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services//LocaleDictionary';

const BBAssignmentModal = props => {
	const [companyId, setCompanyId] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [craneId, setCraneId] = useState(null);
	const { ShowModal } = useContext(LoadingModalContext);

	const changeHandler = e => {
		setCraneId(e.target.value);
		findCraneCompanyAndSite(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.assignModalHandler();
		ShowModal(props.license.BindCrane(props.blackbox.id, companyId, siteId, craneId), CurrentDict.BBAssign);
	};

	const findCraneCompanyAndSite = craneId => {
		let crane = props.hierarchy.cranes.find(c => c.id === craneId);
		let site = crane?.site.id;
		let company = crane?.site.company.id;
		setCompanyId(company);
		setSiteId(site);
	};

	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.assignModalHandler} centered dir='rtl'>
			<Modal.Header className={Classes.close} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>{'שיוך קופסה למנוף'}</div>
				<form className={Classes.modalForm} onSubmit={handleSubmit}>
					<div>
						<select name='craneId' className={Classes.input} defaultValue='' onChange={changeHandler} required>
							<option value='' disabled>
								{'בחר'}
							</option>
							{props.cranesDropdownList()}
						</select>
					</div>
					<Button variant='outline-primary' type='submit'>
						אישור
					</Button>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default BBAssignmentModal;
