import { Crane } from './Crane';
import { DateToString } from '../services/services';
import axios from 'axios';

/**
 * Represents a single notification
 */
export class Notification {
	/**@type {string} */
	id = null;

	/**@type {string} */
	deviceId = null;

	/**@type {string} */
	type = null;

	/**@type {any} */
	data = null;

	/**@type {string} */
	createdTime = null;

	/**@type {Date} */
	createdDate = null;

	/**@type {Crane} */
	crane = null;

	/**
	 * Creates an instance of Notification
	 * @param {any} data
	 * @param {Crane} crane Reference to the Crane object
	 */
	constructor(data, crane) {
		this.id = data.id;
		this.deviceId = data.deviceId;
		this.type = data.type;
		this.data = this.NotificationDataToString(data);
		this.createdTime = DateToString(new Date(data.createdTime));
		this.createdDate = new Date(data.createdTime);
		this.crane = crane;
	}

	/**
	 * @todo remove text to somewhere in UI
	 * @param {*} data
	 */
	NotificationDataToString(data) {
		switch (data.type) {
			case 'InputAlert':
				let windSpeed = JSON.parse(data.dataJson);
				return `מהירות רוח חריגה (${windSpeed.InputValue.ValueInUnits.toFixed(0)} קמ"ש)`;
				break;
			case 'Disconnected':
				return 'תקלת תקשורת';
				break;
			default:
				return 'התראה כללית';
		}
	}
}
