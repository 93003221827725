import React, { useState, useContext } from 'react';

import Classes from './NewCrane.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { EditCrane } from '../../../DataTypes/Edit/EditCrane';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const NewCrane = props => {
	const [currentModal, setCurrentModal] = useState(1);
	const [editCrane, setEditCrane] = useState(new EditCrane());
	const { ShowModal } = useContext(LoadingModalContext);

	const nextModal = () => {
		const next = currentModal + 1;
		setCurrentModal(next);
	};

	const prevModal = () => {
		const prev = currentModal - 1;
		setCurrentModal(prev);
	};

	const handleChange = e => {
		editCrane.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault;
		props.closeHandler();
		ShowModal(props.hierarchy.CreateCrane(editCrane), CurrentDict.CraneCreate);
	};

	const createSelectCompanies = () => {
		let items = [];
		props.hierarchy.companies.map(c =>
			items.push(
				<option key={c.id} value={c.id}>
					{c.name}
				</option>
			)
		);
		return items;
	};

	const createSelectSites = (compId = null) => {
		if (compId === null) {
			return null;
		}
		let items = [];
		props.hierarchy.companies.filter(c =>
			c.id === compId
				? c.sites.map(s =>
						items.push(
							<option key={s.id} value={s.id}>
								{s.name}
							</option>
						)
				  )
				: null
		);
		return items;
	};

	const craneNumberHandler = (companyVal = null, siteVal = null) => {
		if (companyVal != null && siteVal != null) {
			const comp = props.hierarchy.companies.find(comp => comp.id == companyVal);
			const site = comp.sites.find(site => site.id == siteVal);
			const cranes = site.cranes;
			const numbers = [];
			cranes.forEach(crane => {
				numbers.push(crane.number);
			});
			const items = [];
			for (let i = 1; i < 21; i++) {
				if (numbers.includes(`${i}`)) {
					items.push(
						<option key={i} value={i} disabled>
							{i}
						</option>
					);
				} else {
					items.push(
						<option key={i} value={i}>
							{i}
						</option>
					);
				}
			}
			return items;
		} else {
			return null;
		}
	};

	const modal1 = (
		<div className="h-100">
			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>{'הוספת מנוף'}</div>
				<div className={Classes.form1}>
					<form className={Classes.modalForm} onSubmit={nextModal}>
						<br />
						<div>
							<label className={Classes.detailsKey}>{'מספר רישוי'}</label>
							<input name='sn' className={Classes.input} type='text' onChange={handleChange} required></input>
						</div>
						<div>
							<label className={Classes.detailsKey}>{'מספר דגם'}</label>
							<input name='model' className={Classes.input} type='text' onChange={handleChange} required></input>
						</div>
						<div>
							<label className={Classes.detailsKey}>{'סוג מנוף'}</label>
							<select name='type' onChange={handleChange} required>
								<option value='' selected disabled>
									{'בחר'}
								</option>
								<option value='Tower'>{'עגורן'}</option>
								<option value='Feeder'>{'פידר'}</option>
								<option value='Other'>{'אחר'}</option>
							</select>
						</div>
						<div>
							<label className={Classes.detailsKey}>{'שם היצרן'}</label>
							<input name='manufacturer' className={Classes.input} onChange={handleChange} type='text' required></input>
						</div>
						<br />
						<div className={Classes.modalFooter}>
							<Modal.Header className={Classes.closeMobile} style={{ border: 'none' }} closeButton>
								<p>ביטול</p>
							</Modal.Header>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
								{'הבא'}
							</Button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</div>
	);
	const modal2 = (
		<div className="h-100">
			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>{'הוספת מנוף'}</div>
				<div className={Classes.form2}>
					<form className={Classes.modalForm} onSubmit={nextModal}>
						<br />

						<div>
							<label className={Classes.detailsKey}>{'גובה מנוף'}</label>
							<input name='height' className={Classes.input} type='number' onChange={handleChange} min='0' max='500' required></input>
							<span>{'מטרים'}</span>
						</div>
						<div>
							<label className={Classes.detailsKey}>{'אורך זרוע'}</label>
							<input name='length' className={Classes.input} type='number' onChange={handleChange} min='0' max='100' required></input>
							<span>{'מטרים'}</span>
						</div>
						{/* <div>
								<label className={Classes.detailsKey}>{"גובל כבל"}</label>
								<input
									name='minCableLimit'
									className={Classes.input}
									type='number'
									onChange={handleChange}
									min={0}
									max={editCrane?.maxCableLimit ?? 100}></input>
								<span>{"מטר -"}</span>
								<input
									name='maxCableLimit'
									className={Classes.input}
									type='number'
									onChange={handleChange}
									min={editCrane?.minCableLimit ?? 0}
									max={100}></input>
								<span>{"מטר"}</span>
							</div>
							<div>
								<label className={Classes.detailsKey}>{"גובל עגלה"}</label>
								<input name='minCartLimit' className={Classes.input} type='number' onChange={handleChange} min={0} max={editCrane?.maxCartLimit ?? 100}></input>
								<span>{"מטר -"}</span>
								<input name='maxCartLimit' className={Classes.input} type='number' onChange={handleChange} min={editCrane?.minCartLimit ?? 0} max={100}></input>
								<span>{"מטר"}</span>
							</div>
							<div>
								<label className={Classes.detailsKey}>{"גובל סיבוב"}</label>
								<input name='minTurnLimit' className={Classes.input} type='number' onChange={handleChange} min={-720} max={720}></input>
								<span>{"מעלות -"}</span>
								<input name='maxTurnLimit' className={Classes.input} type='number' onChange={handleChange} min={-720} max={720}></input>
								<span>{"מעלות"}</span>
							</div>
							<hr />
							<div>
								<label className={Classes.detailsKey}>{"טסט אחרון"}</label>
								<input name='lastTest' className={Classes.input} type='date' onChange={handleChange} required></input>
							</div>
							<div>
								<label className={Classes.detailsKey}>{"טסט הבא"}</label>
								<input name='nextTest' className={Classes.input} type='date' onChange={handleChange} required></input>
							</div> */}

						<br></br>
						<div className={Classes.modalFooter}>
							<Modal.Header className={Classes.closeMobile} style={{ border: 'none' }} closeButton>
								<p>ביטול</p>
							</Modal.Header>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
								{'הבא'}
							</Button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</div>
	);
	const modal3 = (
		<div className="h-100">
			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>{'הוספת מנוף'}</div>
				<div className={Classes.form3}>
					<form className={Classes.modalForm} onSubmit={handleSubmit}>
						<br />

						<div>
							<label className={Classes.detailsKey}>{'סטטוס'}</label>
							<select name='status' onChange={handleChange} required>
								<option value='' selected disabled>
									{'בחר'}
								</option>
								<option value='Active'>{'פעיל'}</option>
								<option value='Inactive'>{'לא פעיל'}</option>
								<option value='Fault'>{'תקול'}</option>
							</select>
						</div>
						{/* <div>
								<label className={Classes.detailsKey}>{"בבעלות"}</label>
								<select name='owner' onChange={handleChange} required>
									<option value='' disabled>
										{"בחר"}
									</option>
									{createSelectCompanies()}
								</select>
							</div> */}
						<div>
							<label className={Classes.detailsKey}>{'מושכר ל-'}</label>
							<select name='company' onChange={handleChange}>
								<option value='' selected disabled>
									{'בחר'}
								</option>
								{createSelectCompanies()}
							</select>
						</div>
						<div>
							<label className={Classes.detailsKey}>{'שם האתר'}</label>
							<select name='site' onChange={handleChange}>
								<option value='' selected disabled>
									{'בחר'}
								</option>
								{createSelectSites(editCrane?.company ?? null)}
							</select>
						</div>
						<div>
							<label className={Classes.detailsKey}>מס' מנוף באתר</label>
							<select name='number' onChange={handleChange}>
								<option value='' selected disabled>
									{'בחר'}
								</option>
								{craneNumberHandler(editCrane?.company ?? null, editCrane?.site ?? null)}
							</select>
						</div>
						<br></br>
						<div className={Classes.modalFooter}>
							<Modal.Header className={Classes.closeMobile} style={{ border: 'none' }} closeButton>
								<p>ביטול</p>
							</Modal.Header>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
								{'הוסף מנוף'}
							</Button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</div>
	);

	const prevButton = (
		<span role='button' className={Classes.exitButton + ' material-icons'} onClick={prevModal}>
			navigate_next
		</span>
	);

	return (
		<div>
			<Modal size='sm' animation={false} className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.isModalOpen} onHide={props.closeHandler} centered dir='rtl'>
				{currentModal == 1 ? <Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton /> : prevButton}
				{currentModal == 1 ? modal1 : null}
				{currentModal == 2 ? modal2 : null}
				{currentModal == 3 ? modal3 : null}
			</Modal>
		</div>
	);
};

export default NewCrane;
