import { Site } from "./Site";

export class Manager {
	/**@type {string} */
	name = null;

	/**@type {string} */
	phone = null;

	constructor(data) {
		this.name = data.name;
		this.phone = data.phone;
	}
}
