import React, { useState, useEffect } from 'react';
import { Route, NavLink, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import cn from 'classnames';

import Classes from './Crane.module.css';
import CraneGeneral from '../Crane/CraneGeneral/CraneGeneral';
import CraneActivity from '../Crane/CraneActivity/CraneActivity';
import CraneDetails from '../Crane/CraneDetails/CraneDetails';
import Spinner from '../UI/Spinner/Spinner';
import DistributionListTable from '../Tables/DistributionListTable/DistributionListTable';
import CraneView from '../../ViewModels/CraneView';
import HierarchyView from '../../ViewModels/HierarchyView';
import Snapshots from '../Snapshots/Snapshots';
import SteakyHeader from '../SteakyHeader/SteakyHeader';

const translation = {
	general: 'כללי',
	activity: 'פעילות',
	craneDetails: 'פרטי מנוף',
	distributionList: 'רשימת תפוצה',
	snapshots: 'תמונות',
};

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 * @param {CraneView} props.view
 * @param {any} props.match
 */
const Crane = props => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={Classes.wrapper}>
			<Container fluid>
				<h3 className="container-title"><SteakyHeader>מנוף {props.view.crane.number}</SteakyHeader></h3>
				<Row>
					<Col md={4}>
						<div className={Classes.headerCrane}>
							<ul className="container-tabs">
								<li>
									<NavLink exact to={props.match.url} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										{translation.general}
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/crane-activity'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										{translation.activity}
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/crane-details'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										{translation.craneDetails}
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/crane-distribution-list'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										{translation.distributionList}
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/crane-snapshots'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										{translation.snapshots}
									</NavLink>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
			<Switch>
				<Route exact path={props.match.url} render={() => <CraneGeneral view={props.view} {...props} />} />
				<Route path={props.match.url + '/crane-activity'} render={() => <CraneActivity {...props} />} />
				<Route path={props.match.url + '/crane-details'} render={() => <CraneDetails {...props} />} />
				<Route path={props.match.url + '/crane-distribution-list'} render={() => <DistributionListTable item={props.view.crane} {...props} />} />
				<Route path={props.match.url + '/crane-snapshots'} render={() => <Snapshots snapshotsPromise={() => props.view.crane.GetSnapshotImages()} {...props} />} />
			</Switch>
		</div>
	);
};

export default Crane;
