import { Crane } from "./Crane";
import axios from "axios";
import { Event } from "./Event";
import { Notification } from "./Notification";
import { ReturnCranesDeviceIds } from "../services/services";

/**
 * Manges notifications
 */
export class NotificationManager {
	/**@type {Event} */
	NotificationReceived = new Event();

	/**@type {Event} */
	NewNotificationsCountChanged = new Event();

	/**@type {Notification[]} */
	notifications = [];

	/**@type {Crane[]} */
	allCranes = [];

	_newNotificationsCount = 0;
	get newNotificationsCount() {
		return this._newNotificationsCount;
	}
	set newNotificationsCount(value) {
		if (this._newNotificationsCount == value) return;
		this._newNotificationsCount = value;
		this.NewNotificationsCountChanged.Trigger(this, this._newNotificationsCount);
	}

	/**@type {Date} */
	_lastRealtimePollDate = null;

	/**@returns {Date} */
	get lastViewDate() {
		const stor = localStorage.getItem("_lastViewDate");
		if (stor != null) {
			return new Date(stor);
		} else {
			return null;
		}
	}
	set lastViewDate(value) {
		localStorage.setItem("_lastViewDate", value.toISOString());
	}

	/**
	 * Creates an instance of NotificationManager
	 */
	constructor() {}

	onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	/**
	 * @param {Crane[]} cranes
	 * @returns {number} number of notifications associated with the cranes
	 */
	CountUnique(cranes) {
		let arr = cranes.map((n) => n.deviceId);
		arr = arr.filter(this.onlyUnique);
		return arr.length;
	}

	/**
	 * @param {Crane[]} cranes
	 * @param {number} amount
	 * @returns {Promise<Notification[]>}
	 */
	static async FetchLast(cranes, amount = 10, skip = 0) {
		//Find device ids
		let deviceIds = ReturnCranesDeviceIds(cranes);
		if (deviceIds.length == 0) {
			return [];
		}

		//Call notifications api
		const response = await axios.post(`/Notification/GetLast`, deviceIds, { params: { amount, skip } });

		let notifications = NotificationManager.NotificationsFromData(response.data, cranes);

		return notifications;
	}

	/**
	 * @param {*} data
	 * @param {Crane[]} cranes
	 * @returns {Notification[]}
	 */
	static NotificationsFromData(data, cranes) {
		//Create notification objects
		return data.map((x) => {
			const crane = cranes.find((c) => c.deviceId === x.deviceId);
			const n = new Notification(x, crane);
			return n;
		});
	}

	/**
	 * @returns {Promise}
	 */
	async RealtimePoll() {
		//Find device ids
		let deviceIds = ReturnCranesDeviceIds(this.allCranes);
		if (deviceIds.length == 0) {
			return;
		}

		const now = new Date();

		if (this._lastRealtimePollDate == null) {
			if (this.lastViewDate != null) {
				this._lastRealtimePollDate = new Date(this.lastViewDate);
			} else {
				//one day back
				this._lastRealtimePollDate = new Date(now);
				this._lastRealtimePollDate.setDate(this._lastRealtimePollDate.getDate() - 1);
			}
		}

		const response = await axios.post(`/Notification/GetMultiple`, deviceIds, { params: { startTime: this._lastRealtimePollDate, endTime: now } });
		this._lastRealtimePollDate = new Date(now);

		let newNotifications = NotificationManager.NotificationsFromData(response.data, this.allCranes);

		newNotifications.forEach((x) => {
			this.notifications.push(x);
			this.NotificationReceived.Trigger(this, x);
			this.newNotificationsCount++;
		});
	}
}
