import { Company } from "../Company";
import { Site } from "../Site";
import { DeviceStatus } from "../DeviceStatus";

export class EditCrane {
	/**@type {string} */
	id = "";

	/**@type {string} */
	number = "";

	/**@type {string} */
	model = "";

	/**@type {"Tower" | "Feeder" | "Other"} */
	type = null;

	/**@type {"Active" | "Inactive" | "Fault"} */
	status = null;

	/**@type {string} */
	sn = null;

	/**@type {string} */
	manufacturer = null;

	/**@type {string} */
	blackboxId = null;

	/**@type {string} */
	blackboxVersion = null;

	/**@type {number} */
	height = null;

	/**@type {number} */
	length = null;

	/**@type {string} */
	site = null;

	/**@type {string} */
	company = null;

	/**@type {string} */
	deviceId = null;

	/**@type {DeviceStatus} */
	deviceStatus = null;

	/** Creates a new temporary instance of Crane to
	 */
	constructor(data = null, company = null, site = null) {
		this.id = data?.id;
		this.number = data?.number;
		this.model = data?.model;
		this.type = data?.type;
		this.status = data?.status;
		this.sn = data?.sn;
		this.manufacturer = data?.manufacturer;
		this.height = data?.height;
		this.length = data?.length;
		this.site = site;
		this.company = company;
		data?.site instanceof Site ? (this.site = data?.site.name) : (this.site = data?.site);
		data?.company instanceof Company ? (this.company = data?.company.name) : (this.company = data?.company);
	}

	/**
	 * @param {string} key
	 * @param {string} value
	 */
	Edit(key, value) {
		switch (key) {
			case "height":
				this.height = parseInt(value);
				break;
			case "length":
				this.length = parseInt(value);
				break;
			default:
				this[key] = value;
				break;
		}
	}

	UpdateDeviceStatus(data) {
		this.deviceStatus = new DeviceStatus(data);
	}
}
