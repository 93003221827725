import React, { useState, useContext } from 'react';
import Classes from './NewUserModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { EditUser } from '../../../DataTypes/Edit/EditUser';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const NewUserModal = props => {
	/**@type {[EditUser,any]} */
	const [user, setUser] = useState(new EditUser());
	const { ShowModal } = useContext(LoadingModalContext);

	const handleChange = e => {
		user.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.closeHandler();
		ShowModal(user.CreateNewUser(), CurrentDict.UserCreate);
	};

	const companyIdToName = id => {
		return props.hierarchy.companies.find(x => x.id == id)?.name;
	};

	const companiesDropdownList = () => {
		let items = [];
		props.hierarchy.companies.map(c =>
			items.push(
				<option key={c.id} value={c.id}>
					{c.name}
				</option>
			)
		);
		return items;
	};

	return (
		<>
			<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.isModalOpen} onHide={props.closeHandler} centered dir='rtl'>
				<Modal.Header  className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

				<Modal.Body className={Classes.modalBody}>
					<div className={Classes.title}>{'הוספת משתמש'}</div>
					<form className={Classes.modalForm} onSubmit={handleSubmit}>
						<div>
							<div className={Classes.key}>{'מייל'}</div>
							<input name='mail' className={Classes.input} type='text' defaultValue={user.mail ?? ''} placeholder={'אימייל'} onChange={handleChange} required></input>
						</div>
						<div>
							<div className={Classes.key}>{'חברה'}</div>
							<select name='company' className={Classes.input} placeholder={'חברה'} defaultValue='' onChange={handleChange} required>
								<option value='' disabled>
									{'בחר'}
								</option>
								{companiesDropdownList()}
							</select>
						</div>
						<div>
							<div className={Classes.key}>{'תפקיד'}</div>
							<select name='role' onChange={handleChange} defaultValue={user.role ?? ''}>
								<option value='' disabled>
									{'בחר'}
								</option>
								<option value='SuperAdmin'>SuperAdmin</option>
								<option value='ServiceManager'>ServiceManager</option>
								<option value='ServiceManager2nd'>ServiceManager2nd</option>
								<option value='SafetyManager'>SafetyManager</option>
								<option value='OperationManager'>OperationManager</option>
								<option value='CranesManager'>CranesManager</option>
								<option value='SiteManager'>SiteManager</option>
							</select>
						</div>

						<div className={Classes.modalFooter}>
							<p onClick={props.closeHandler}>ביטול</p>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
							{'שליחת הזמנה'}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default NewUserModal;
