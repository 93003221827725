import { Hierarchy } from '../DataTypes/Hierarchy';
import CompanyView from './CompanyView';
import CraneView from './CraneView';
import SiteView from './SiteView';

class HierarchyView {
	/**@type {Hierarchy}*/
	hierarchy;

	/**@type {CompanyView[]} */
	companyViews = [];

	/**@type {SiteView[]} */
	siteViews = [];

	/**@type {CraneView[]} */
	craneViews = [];

	/**@param {Hierarchy} hierarchy*/
	constructor(hierarchy) {
		this.hierarchy = hierarchy;

		this.companyViews = this.hierarchy.companies.map(c => {
			let company = new CompanyView(c);

			//Fill sites
			this.siteViews = this.siteViews.concat(company.siteViews);

			//Fill cranes
			company.siteViews.forEach(s => s.craneViews.forEach(c => this.craneViews.push(c)));

			return company;
		});
	}
}

export default HierarchyView;
