import React, { useState, useEffect, useRef } from 'react';
import Classes from './LicenseSettings.module.scss';
import Spinner from '../../../UI/Spinner/Spinner';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import LicenseAssignmentModal from '../../../Modals/LicenseAssignmentModal/LicenseAssignmentModal';
import LicenseUnassignmentModal from '../../../Modals/LicenseUnassignmentModal/LicenseUnassignmentModal';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/sidebar-close.svg';
import cn from 'classnames';
import useOutsideClick from '../../../../utils/useOutsideClick';

const LicenseSettings = props => {
	const controlsRef = useRef();
	const [subscriptionCredit, setSubscriptionCredit] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [currentLicense, setCurrentLicense] = useState(null);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showUnassignModal, setShowUnassignModal] = useState(false);

	const [openDistIds, setOpenDistIds] = useState([]);
	const [openControls, setOpenControls] = useState(null);

	useEffect(() => {
		loadData();
		setIsLoading(false);
	}, [props.hierarchy.subscriptionCredit]);

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	const loadData = () => {
		if (props.hierarchy.subscriptionCredit) {
			setSubscriptionCredit(props.hierarchy.subscriptionCredit);
		}
	};

	const assignModalHandler = license => {
		if(license != null)
			setCurrentLicense(license)

		const show = showAssignModal;
		setShowAssignModal(!show);
	};

	const unassignModalHandler = license => {
		if(license != null)
			setCurrentLicense(license)

		const show = showUnassignModal;
		setShowUnassignModal(!show);
	};

	const createBlackboxesDropdownList = () => {
		let items = [];
		props.hierarchy.subscriptionCredit?.blackboxes.map(blackbox =>
			items.push(
				<option key={blackbox.id} value={blackbox.id}>
					{blackbox.friendlyName}
				</option>
			)
		);
		return items;
	};

	const licenseExpirationCalc = (duration, bindTime) => {
		let now = Date.now();
		let bindingTime = new Date(bindTime).getTime();
		return (duration - (now - bindingTime) / (1000 * 60 * 60 * 24)).toFixed(0);
	};

	const assignPopover = (
		<Popover id='assign-popover' className={Classes.popover}>
			<div role='button' className={Classes.assign} onClick={() => assignModalHandler(null)}>
				<span className={Classes.popoverText}>{'שייך לקופסה'}</span>
			</div>
		</Popover>
	);
	const unassignPopover = (
		<Popover id='unassign-popover' className={Classes.popover}>
			<div role='button' className={Classes.unassign} onClick={() => unassignModalHandler(null)}>
				<span className={Classes.popoverText}>{'ביטול שיוך'}</span>
			</div>
		</Popover>
	);

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={Classes.wrapper}>
			{showAssignModal ? (
				<LicenseAssignmentModal blackboxesDropdownList={createBlackboxesDropdownList} license={currentLicense} show={showAssignModal} assignModalHandler={() => assignModalHandler(null)} />
			) : null}
			{showUnassignModal ? <LicenseUnassignmentModal license={currentLicense} show={showUnassignModal} unassignModalHandler={() => unassignModalHandler(null)} /> : null}
			<div className={Classes.card}>
				<div className={Classes.cardHeader}>
					<div className={Classes.cardTitle}>פירוט רשיונות</div>
				</div>
				<table className={cn(Classes.decstopTable , 'table table-borderless') }>
					<thead>
						<tr>
							<th scope='col'>סוג רשיון</th>
							<th scope='col'>מס' רשיון</th>
							<th scope='col'>תוקף (בימים)</th>
							<th scope='col'>קופסה שחורה</th>
							<th scope='col'>מנוף</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{subscriptionCredit?.licenses.map((license, index) => {
							return (
								<tr key={index}>
									<td>{license.type === 'Full' ? 'SBC' : license.type === 'Lite' ? 'SBL' : '-'}</td>
									<td>{license.id}</td>
									<td>{license.blackboxBinding ? licenseExpirationCalc(license.durationInDays, license.blackboxBinding?.bindTime) : '-'}</td>
									<td>{license.blackboxBinding?.blackbox.friendlyName ?? '-'}</td>
									<td>
										{props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)
											? `מנוף ${props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.number},  אתר ${
													props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.site?.name
											  },  חברת ${props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.site?.company?.name}`
											: 'לא משויך מנוף'}
									</td>
									<td className={Classes.button}>
										<div role='button'>
											{license.blackboxBinding ? (
												<OverlayTrigger trigger='click' placement='right-start' onToggle={() => setCurrentLicense(license)} overlay={unassignPopover} rootClose={true}>
													<img src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon}></img>
												</OverlayTrigger>
											) : (
												<OverlayTrigger trigger='click' placement='right-start' onToggle={() => setCurrentLicense(license)} overlay={assignPopover} rootClose={true}>
													<img src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon}></img>
												</OverlayTrigger>
											)}
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>

				<table className={cn(Classes.mobileTable , 'table table-borderless fw-bold')}>
					<thead>
						<tr>
							<th className='w-25' scope='col'>סוג רשיון</th>
							<th scope='col'>מס' רשיון</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{subscriptionCredit?.licenses.map((license, index) => {
							return (
								<>
									<tr key={index}>
										<td>{license.type === 'Full' ? 'SBC' : license.type === 'Lite' ? 'SBL' : '-'}</td>
										<td>{license.id}</td>
										<td className={Classes.expandButton} onClick={() => toggleOnetDistId(index)}><ChevronIcon/></td>
									</tr>

									<tr className={cn((openDistIds.includes(index) ? Classes.open : null), Classes.tableExpand)}>
										<td colSpan={2}>
											<div>
												<span className={Classes.distHeader}>תוקף (בימים)</span>
												<span>{license.blackboxBinding ? licenseExpirationCalc(license.durationInDays, license.blackboxBinding?.bindTime) : '-'}</span>
											</div>
											<div>
												<span className={Classes.distHeader}>קופסה שחורה</span>
												<span>{license.blackboxBinding?.blackbox.friendlyName ?? '-'}</span>
											</div>
											<div>
												<span className={Classes.distHeader}>מנוף</span>
												<span>
													{props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)
													? `מנוף ${props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.number},  אתר ${
															props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.site?.name
													},  חברת ${props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.site?.company?.name}`
													: 'לא משויך מנוף'}
											</span>
											</div>
										</td>
										<td className={Classes.mobileButton}>
											<div role='button'>
												<img src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon} onClick={() => setOpenControls(index)}></img>
											</div>
											<div className={cn(Classes.controls, openControls === index ? Classes.openControls : null)} ref={controlsRef}>
												<CloseIcon className={Classes.close} onClick={() => setOpenControls(null)} />
												{license.blackboxBinding ? (
													<div role='button' className={Classes.unassign} onClick={() => unassignModalHandler(license)}>
														<span className={Classes.popoverText}>{'ביטול שיוך'}</span>
													</div>
												) : (
													<div role='button' className={Classes.assign} onClick={() => assignModalHandler(license)}>
														<span className={Classes.popoverText}>{'שייך לקופסה'}</span>
													</div>
												)}
											</div>
										</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</table>
			</div>

			<div className={Classes.tableHeader}>
				<div>
					<FilterIcon/>
					<p>סינון</p>
				</div>
				<div>
					<SearchIcon/>
					<p>חיפוש</p>
				</div>
			</div>

			<div className={Classes.licenseAmountCard}>
				<div className={Classes.licenseAmountCardTitle}>כמות רישיונות</div>
				<div className={Classes.licenseAmountCardBody}>
					<div className={Classes.licenseAmount}>
						<span className={Classes.licenseAmountKey}>SBC :</span>
						<span className={Classes.licenseAmountValue}>{subscriptionCredit?.licenses.filter(license => license.type === 'Full').length}</span>
					</div>
					<div className={Classes.licenseAmount}>
						<span className={Classes.licenseAmountKey}>SBL :</span>
						<span className={Classes.licenseAmountValue}>{subscriptionCredit?.licenses.filter(license => license.type === 'Lite').length}</span>
					</div>
				</div>
				<div className={Classes.licenseAmountCardFooter}>
					<span>{`סה"כ ${subscriptionCredit?.licenses.length} רשיונות `}</span>
				</div>
			</div>
		</div>
	);
};

export default LicenseSettings;
