export class OperationSummary {
	/**@type {String} */
	deviceId = null;

	/**@type {Date} */
	date = null;

	/**@type {string} */
	id = null;

	/**@type {Date} */
	createdTime = null;

	/**@type {Interval[]} */
	intervals = [];

	/**@type {number} */
	secondsOfOperation = null;

	constructor(data) {
		this.deviceId = data.deviceId;
		this.date = new Date(data.date);
		this.id = data.id;
		this.createdTime = new Date(data.id);
		this.intervals = data.intervals.map((i) => new Interval(i)).sort((a, b) => (a.start < b.start ? 1 : -1));
		this.secondsOfOperation = data.secondsOfOperation;
	}
}

export class Interval {
	/**@type {Date} */
	start = null;

	/**@type {Date} */
	end = null;

	constructor(time) {
		this.start = new Date(time.start);
		this.end = new Date(time.end);
	}
}
