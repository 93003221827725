import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Classes from './ApiResponseModal.module.scss';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';

const ApiResponseModal = props => {
	const [type, setType] = useState(null);
	const { show, actionTexts, response, toggleShow } = useContext(LoadingModalContext);

	useEffect(() => {
		setType(response?.isError);
	});

	const loadingAnimation = <div className={Classes.loader} />;
	const successAnimation = (
		<svg className={Classes.success_checkmark} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
			<circle className={Classes.success_checkmark_circle} cx='26' cy='26' r='25' fill='none' />
			<path className={Classes.checkmark_check} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
		</svg>
	);

	const errorAnimation = (
		<svg className={Classes.error_checkmark} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
			<circle className={Classes.error_checkmark_circle} cx='26' cy='26' r='25' fill='none' />
			<path className={Classes.checkmark_check} fill='none' d='M16 16 36 36 M36 16 16 36' />
		</svg>
	);

	return (
		<Modal className={Classes.modalContainer} size='sm' show={show} centered>
			<Modal.Header className={Classes.close} onClick={toggleShow} style={{ border: 'none' }} closeButton />
			<div className={Classes.modal}>
				<div className={Classes.modalTitle}>{actionTexts?.Generic}</div>
				<div className={Classes.animation}>{type === false ? successAnimation : type === true ? errorAnimation : loadingAnimation}</div>
				<div className={Classes.modalFooterTitle}>{type === false ? '!' + actionTexts?.Success : type === true ? '!' + actionTexts?.Fail : ''}</div>
				<div className={Classes.modalContent}>{typeof response?.data === 'string' ? response?.data : ''}</div>

				<Button variant='outline-primary' onClick={toggleShow} className={Classes.button} disabled={typeof type !== 'boolean'}>
					אישור
				</Button>
			</div>
		</Modal>
	);
};

export default ApiResponseModal;
