import { Company } from './Company';
import { Manager } from './Manager';
import { Site } from './Site';
import { DeviceStatus } from './DeviceStatus';
import axios from 'axios';
import { Notification } from './Notification';
import { EditCrane } from './Edit/EditCrane';

export class Crane {
	/**@type {string} */
	id = '';

	/**@type {string} */
	number = '';

	/**@type {string} */
	model = '';

	/**@type {"Tower" | "Feeder" | "Other"} */
	type = null;

	/**@type {"Active" | "Inactive" | "Fault"} */
	status = null;

	/**@type {string} */
	sn = null;

	/**@type {string} */
	manufacturer = null;

	/**@type {string} */
	blackboxId = null;

	/**@type {string} */
	blackboxVersion = null;

	/**@type {number} */
	height = null;

	/**@type {number} */
	length = null;

	/**@type {Site} */
	site = null;

	/**@type {Company} */
	company = null;

	/**@type {string} */
	deviceId = null;

	/**@type {DeviceStatus} */
	deviceStatus = null;

	/** Creates a new instance of Crane
	 * @param {Company} company
	 * @param {Site} site
	 */
	constructor(data, company, site) {
		this.id = data.id;
		this.number = data.number;
		this.model = data.model;
		this.type = data.type;
		this.status = data.status;
		this.sn = data.sn;
		this.manufacturer = data.manufacturer;
		this.blackboxId = data.blackboxId;
		this.blackboxVersion = data.blackboxVersion;
		this.height = data.height;
		this.length = data.length;
		this.site = site;
		this.company = company;
	}

	GetStatus() {
		switch (this.status) {
			case 'Active':
				return 'פעיל';
			case 'Inactive':
				return 'לא פעיל';
			case 'Fault':
				return 'תקול';
			default:
				return '';
		}
	}

	UpdateDeviceStatus(data) {
		this.deviceStatus = new DeviceStatus(data);
	}

	/**
	 * @param {EditCrane} details
	 */
	UpdateDetails(details) {
		const updatedCrane = {
			number: details.number,
			model: details.model,
			type: details.type,
			status: details.status,
			manufacturer: details.manufacturer,
			blackboxId: null,
			blackboxVersion: null,
			height: details.height,
			length: details.length,
		};
		return axios.put(`/Company/${this.company.id}/Site/${this.site.id}/Crane/${this.id}`, updatedCrane);
	}

	Move(details) {
		return axios.post(`/Company/${this.company.id}/Site/${this.site.id}/Crane/${this.id}/Move`, { companyId: details?.company, siteId: details?.site });
	}

	SetPower(action) {
		if (this.deviceId === null) {
			alert('Device Id not found');
			return Promise.reject('Device Id not found');
		}

		return axios.put(`/Device/${this.deviceId}/SetPower?power=${action}`);
	}

	SetPowerVerify(token) {
		return axios.put(`/Device/${this.deviceId}/SetPowerVerify?token=${token}`);
	}

	// GetActivitySummary(startTime, endTime) {
	// 	return axios.post(`/Summary/${this.deviceId}`, null, { params: { startTime, endTime } });
	// }

	GetActivitySummary(year) {
		return axios.post(`/Summary/${this.deviceId}`, null, {
			params: {
				startTime: `${year}-01-01T00:00:00Z`,
				endTime: `${year + 1}-01-01T00:00:00Z`,
			},
		});
	}

	GetOperatorImage() {
		return axios.put(`/Device/${this.deviceId}/GetOperatorImage`);
	}

	/**
	 *
	 * @returns
	 */
	async GetSnapshotImages() {
		if (this.deviceId == null) return null;
		const response = await axios.put(`/Device/${this.deviceId}/GetSnapshotImages`);
		return response.data;
	}

	static typeDropdown = [
		{ label: 'בחר', value: '', disabled: true },
		{ label: 'עגורן', value: 'Tower' },
		{ label: 'פידר', value: 'Feeder' },
		{ label: 'אחר', value: 'Other' },
	];

	static statusDropdown = [
		{ label: 'בחר', value: '', disabled: true },
		{ label: 'פעיל', value: 'Active' },
		{ label: 'לא פעיל', value: 'Inactive' },
		{ label: 'תקול', value: 'Fault' },
	];
}
