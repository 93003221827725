export class ActionTexts {
	Generic = 'ACTION';
	Success = 'Success';
	Fail = 'Fail';
}
class LocaleDict {
	/**@type {ActionTexts}*/
	BBCreate = new ActionTexts();
	BBAssign = new ActionTexts();
	BBUnassign = new ActionTexts();
	BBRemove = new ActionTexts();
	LicenseAssign = new ActionTexts();
	LicenseUnassign = new ActionTexts();
	CraneCreate = new ActionTexts();
	CraneRemove = new ActionTexts();
	CraneUpdate = new ActionTexts();
	CraneAssignUpdate = new ActionTexts();
	SiteCreate = new ActionTexts();
	SiteRemove = new ActionTexts();
	SiteDetailsUpdate = new ActionTexts();
	CompanyCreate = new ActionTexts();
	CompanyRemove = new ActionTexts();
	CompanyDetailsUpdate = new ActionTexts();
	DistributionListItemCreate = new ActionTexts();
	DistributionListItemRemove = new ActionTexts();
	DistributionListItemUpdate = new ActionTexts();
	UserCreate = new ActionTexts();
	UserUpdate = new ActionTexts();
	UserRemove = new ActionTexts();
}

/**@type {LocaleDict} Hebrew*/
let HebDict = new LocaleDict();

//Blackbox Create
HebDict.BBCreate.Generic = 'הוספת קופסה שחורה';
HebDict.BBCreate.Success = 'הקופסה השחורה נוספה בהצלחה';
HebDict.BBCreate.Fail = 'תהליך הוספת הקופסה השחורה נכשל';

//Blackbox Assignment
HebDict.BBAssign.Generic = 'שיוך קופסה שחורה';
HebDict.BBAssign.Success = 'קופסה שחורה שויכה בהצלחה';
HebDict.BBAssign.Fail = 'תהליך שיוך הקופסה השחורה נכשל';

//Blackbox Unassignment
HebDict.BBUnassign.Generic = 'ביטול שיוך קופסה שחורה';
HebDict.BBUnassign.Success = 'תהליך ביטול שיוך הקופסה השחורה הצליח';
HebDict.BBUnassign.Fail = 'תהליך ביטול שיוך הקופסה השחורה נכשל';

//Blackbox remove
HebDict.BBRemove.Generic = 'הסרת קופסה שחורה';
HebDict.BBRemove.Success = 'קופסה שחורה הוסרה בהצלחה';
HebDict.BBRemove.Fail = 'תהליך הסרת הקופסה השחורה נכשל';

//License Assignment
HebDict.LicenseAssign.Generic = 'שיוך רשיון';
HebDict.LicenseAssign.Success = 'רשיון שויך בהצלחה';
HebDict.LicenseAssign.Fail = 'תהליך שיוך הרשיון נכשל';

//License Unassignment
HebDict.LicenseUnassign.Generic = 'ביטול שיוך רשיון';
HebDict.LicenseUnassign.Success = 'שיוך הרשיון בוטל בהצלחה';
HebDict.LicenseUnassign.Fail = 'תהליך ביטול שיוך הרשיון נכשל';

//Crane Create
HebDict.CraneCreate.Generic = 'הוספת מנוף';
HebDict.CraneCreate.Success = 'המנוף נוסף בהצלחה';
HebDict.CraneCreate.Fail = 'תהליך הוספת המנוף נכשלה';

//Crane Remove
HebDict.CraneRemove.Generic = 'הסרת מנוף';
HebDict.CraneRemove.Success = 'המנוף הוסר בהצלחה';
HebDict.CraneRemove.Fail = 'תהליך הסרת המנוף נכשל';

//Crane details Update
HebDict.CraneUpdate.Generic = 'עדכון פרטי המנוף';
HebDict.CraneUpdate.Success = 'פרטי המנוף עודכנו בהצלחה';
HebDict.CraneUpdate.Fail = 'תהליך עדכון פרטי המנוף נכשל';

//Crane assignment Update
HebDict.CraneAssignUpdate.Generic = 'עדכון שיוך המנוף';
HebDict.CraneAssignUpdate.Success = 'שיוך המנוף עודכן בהצלחה';
HebDict.CraneAssignUpdate.Fail = 'תהליך עדכון שיוך המנוף נכשל';

//Site Create
HebDict.SiteCreate.Generic = 'הוספת אתר';
HebDict.SiteCreate.Success = 'האתר נוסף בהצלחה';
HebDict.SiteCreate.Fail = 'תהליך הוספת האתר נכשל';

//Site Remove
HebDict.SiteRemove.Generic = 'הסרת אתר';
HebDict.SiteRemove.Success = 'האתר הוסר בהצלחה';
HebDict.SiteRemove.Fail = 'תהליך הסרת האתר נכשל';

//Site details Update
HebDict.SiteDetailsUpdate.Generic = 'עדכון פרטי האתר';
HebDict.SiteDetailsUpdate.Success = 'פרטי האתר עודכנו בהצלחה';
HebDict.SiteDetailsUpdate.Fail = 'תהליך עדכון פרטי האתר נכשל';

//Company Create
HebDict.CompanyCreate.Generic = 'הוספת חברה';
HebDict.CompanyCreate.Success = 'חברה נוספה בהצלחה';
HebDict.CompanyCreate.Fail = 'תהליך הוספת החברה נכשל';

//Company Remove
HebDict.CompanyRemove.Generic = 'הסרת חברה';
HebDict.CompanyRemove.Success = 'החברה הוסרה בהצלחה';
HebDict.CompanyRemove.Fail = 'תהליך הסרת החברה נכשל';

//Company details Update
HebDict.CompanyDetailsUpdate.Generic = 'עדכון פרטי החברה';
HebDict.CompanyDetailsUpdate.Success = 'פרטי החברה עודכנו בהצלחה';
HebDict.CompanyDetailsUpdate.Fail = 'תהליך עדכון פרטי החברה נכשל';

//DistributionListItem Create
HebDict.DistributionListItemCreate.Generic = 'הוספת איש קשר';
HebDict.DistributionListItemCreate.Success = 'איש הקשר נוסף בהצלחה';
HebDict.DistributionListItemCreate.Fail = 'תהליך הוספת איש הקשר נכשל';

//DistributionListItem Remove
HebDict.DistributionListItemRemove.Generic = 'הסרת איש קשר';
HebDict.DistributionListItemRemove.Success = 'איש הקשר הוסר בהצלחה';
HebDict.DistributionListItemRemove.Fail = 'תהליך הסרת איש הקשר נכשל';

//DistributionListItem Update
HebDict.DistributionListItemUpdate.Generic = 'עדכון פרטי איש הקשר';
HebDict.DistributionListItemUpdate.Success = 'פרטי איש הקשר עודכנו בהצלחה';
HebDict.DistributionListItemUpdate.Fail = 'תהליך עדכון הפרטים נכשל';

//User Create
HebDict.UserCreate.Generic = 'הוספת משתמש';
HebDict.UserCreate.Success = 'המשתמש נוסף בהצלחה';
HebDict.UserCreate.Fail = 'תהליך הוספת המשתמש נכשל';

//User details Update
HebDict.UserUpdate.Generic = 'עדכון פרטי המשתמש';
HebDict.UserUpdate.Success = 'פרטי המשתמש עודכנו בהצלחה';
HebDict.UserUpdate.Fail = 'תהליך עדכון הפרטים נכשל';

//User Remove
HebDict.UserRemove.Generic = 'הסרת משתמש';
HebDict.UserRemove.Success = 'המשתמש הוסר בהצלחה';
HebDict.UserRemove.Fail = 'תהליך הסרת המשתמש נכשל';

/**@type {LocaleDict} English*/
let EngDict = new LocaleDict();

/**@type {LocaleDict} The current dictionary*/
export let CurrentDict = HebDict;

export default CurrentDict;
