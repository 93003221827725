import axios from "axios";
import LicenseAssignmentModal from "../components/Modals/LicenseAssignmentModal/LicenseAssignmentModal";
import { Blackbox } from "./Blackbox";
import { License } from "./License";

export class SubscriptionCredit {
	/**@type {License[]} */
	licenses = [];

	/**@type {Blackbox[]} */
	blackboxes = [];

	/**@type {string[]} */
	deviceIds = [];

	constructor(data) {
		//TODO
		this.licenses = data.licenses.map((l) => new License(l));
		this.blackboxes = data.blackboxes.map((b) => new Blackbox(b));
		this.deviceIds = this.licenses.map((x) => x.deviceId);
	}

	// /**@returns {Promise<Hierarchy> | null} */
	// static FetchHierarchy() {
	// 	return axios.get("/Hierarchy").then(
	// 		(response) => {
	// 			return new Hierarchy(response.data);
	// 		},
	// 		(reason) => {
	// 			return null;
	// 		}
	// 	);
	// }

	AddBlackbox(details) {
		return axios.post("/License/AddBlackbox", details);
	}
}
