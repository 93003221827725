import { Manager } from "../Manager";
import axios from "axios";
import { Company } from "../Company";

export class EditSite {
	/**@type {string} */
	id = null;

	/**@type {string} */
	name = null;

	/**@type {string} */
	address = null;

	/**@type {string} */
	company = null;

	/**@type {Manager} */
	manager = null;

	/** */
	constructor(data = null) {
		this.id = data?.id;
		this.name = data?.name;
		this.address = data?.address;
		this.manager = data?.manager;
		data?.company instanceof Company ? (this.company = data?.company.name) : (this.company = data?.company);
	}

	Edit(key, value) {
		switch (key) {
			case "managerName":
				this.manager = new Manager({ name: value, phone: this.manager?.phone });
				break;
			case "managerPhone":
				this.manager = new Manager({ name: this.manager?.name, phone: value });
				break;
			default:
				this[key] = value;
				break;
		}
	}

	UpdateDetails(details) {
		return axios.put(`/Company/${this.company}/Site/${this.id}`, details);
	}
}
