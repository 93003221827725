import React, { useState, useEffect } from 'react';
import Classes from './UsersSettings.module.scss';
import Spinner from '../../../UI/Spinner/Spinner';

import NewUserModal from '../../../Modals/NewUserModal/NewUserModal';
import EditUserModal from '../../../Modals/EditUserModal/EditUserModal';
import RemoveUserModal from '../../../Modals/RemoveUserModal/RemoveUserModal';
import axios from 'axios';
import { User } from '../../../../DataTypes/User';
import ErrorModal from '../../../ErrorModal/ErrorModal';
import cn from 'classnames';

import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

const UsersSettings = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [showNewUserModal, setShowNewUserModal] = useState(false);
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const [users, setUsers] = useState(null);
	const [apiError, setApiError] = useState(null);

	const [openDistIds, setOpenDistIds] = useState([]);

	/**@type {number} */
	let timeoutHandle = null;

	useEffect(() => {
		PollUsers();
		return clearTimeout(timeoutHandle);
	}, []);

	const PollUsers = async () => {
		const response = await axios.get('/User/All').catch(reason => {
			return { ...reason, isError: true };
		});

		if (response.isError) {
			setApiError(<ErrorModal statusCode={response.response.status} content={response.response.data} />);
			return clearTimeout(timeoutHandle);
		} else {
			setUsers(response.data.map(u => new User(u)));
			setIsLoading(false);
		}

		timeoutHandle = setTimeout(PollUsers, 3000);
	};

	const newUserModalHandler = () => {
		setShowNewUserModal(!showNewUserModal);
	};

	const editUserModalHandler = () => {
		setShowEditUserModal(!showEditUserModal);
	};

	const removeUserModalHandler = () => {
		setShowRemoveUserModal(!showRemoveUserModal);
	};

	const apiResponseModalHandler = data => {
		setApiResponseModal(data);
	};

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	if (apiError) {
		return (
			<>
				{apiError} <Spinner />
			</>
		);
	} else if (isLoading) {
		return <Spinner />;
	} else {
		return (
			<>
				{showNewUserModal ? <NewUserModal hierarchy={props.hierarchy} isModalOpen={showNewUserModal} closeHandler={newUserModalHandler}></NewUserModal> : null}
				{showEditUserModal ? <EditUserModal hierarchy={props.hierarchy} show={showEditUserModal} closeHandler={editUserModalHandler} user={currentUser}></EditUserModal> : null}
				{showRemoveUserModal ? <RemoveUserModal show={showRemoveUserModal} closeHandler={removeUserModalHandler} user={currentUser}></RemoveUserModal> : null}

				<div className={Classes.tableHeader}>
					<div>
						<FilterIcon/>
						<p>סינון</p>
					</div>
					<div onClick={newUserModalHandler}>
						<PlusIcon/>
						<p>הוספה</p>
					</div>
				</div>

				<div className={Classes.card} dir='rtl'>
					<div className={Classes.cardHeader}>
						<div className={Classes.cardTitle}>הגדרות משתמשים</div>
						<div className={Classes.addUserButton} role='button' onClick={newUserModalHandler}>
							<div className={'material-icons'}>add_circle_outline</div>
						</div>
					</div>
					<table className={cn(Classes.decstopTable , 'table table-borderless')}>
						<thead>
							<tr>
								<th scope='col'>שם</th>
								<th scope='col'>חברה</th>
								<th scope='col'>תפקיד</th>
								<th scope='col'>נייד</th>
								<th scope='col'>מייל</th>
								<th scope='col'>סטטוס</th>
								{/* <th scope='col'></th> */}
								<th scope='col'></th>
							</tr>
						</thead>
						<tbody>
							{users?.map((user, index) => {
								return (
									<tr key={index}>
										<td>{user.fullName}</td>
										<td>{user.company}</td>
										<td>{user.role}</td>
										<td>{user.phone.number}</td>
										<td>{user.mail}</td>
										<td>{user.isPending ? 'נשלחה הזמנה' : 'רשום'}</td>
										<td className={Classes.button}>
											{user.isPending ? (
												<svg
													className={Classes.moreIcon}
													role='button'
													onClick={() => {
														setCurrentUser(user);
														removeUserModalHandler();
													}}
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'>
													<path d='M3 6H5H21' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
													<path
														d='M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z'
														stroke='#101010'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											) : null}
										</td>
										{/* <td className={Classes.button}>
											{user.isPending ? (
												<svg
													className={Classes.moreIcon}
													role='button'
													onClick={() => {
														setCurrentUser(user), editUserModalHandler();
													}}
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'>
													<path
														d='M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13'
														stroke='#101010'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M18.5 2.5C18.8978 2.10217 19.4374 1.87868 20 1.87868C20.5626 1.87868 21.1022 2.10217 21.5 2.5C21.8978 2.89782 22.1213 3.43739 22.1213 4C22.1213 4.56261 21.8978 5.10217 21.5 5.5L12 15L8 16L9 12L18.5 2.5Z'
														stroke='#101010'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											) : null}
										</td> */}
									</tr>
								);
							})}
							<tr></tr>
						</tbody>
					</table>

					<table className={cn(Classes.mobileTable , 'table table-borderless fw-bold')}>
						<tbody>
							{users?.map((user, index) => {
								return (
									<>
										<tr className={openDistIds.includes(index) ? Classes.open : null}>
											<td>
												<span>{user.fullName}</span>
												<span onClick={() => toggleOnetDistId(index)}><ChevronIcon/></span>
											</td>
											<td className={Classes.removeUser}>
												<div>
													<span>חברה</span>
													<span>{user.company}</span>
												</div>
												<div>
													{user.isPending ? (
														<svg
															className={Classes.moreIcon}
															role='button'
															onClick={() => {
																setCurrentUser(user);
																removeUserModalHandler();
															}}
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'>
															<path d='M3 6H5H21' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
															<path
																d='M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z'
																stroke='#101010'
																strokeWidth='2'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
														</svg>
													) : null}
												</div>
											</td>
											<td>
												<span>תפקיד</span>
												<span>{user.role}</span>
											</td>
											<td>
												<span>נייד</span>
												<span>{user.phone.number}</span>
											</td>
											<td>
												<span>מייל</span>
												<span>{user.mail}</span>
											</td>
											<td>
												<span>סטטוס</span>
												<span>{user.isPending ? 'נשלחה הזמנה' : 'רשום'}</span>
											</td>
										</tr>
									</>
								);
							})}
						</tbody>
					</table>
				</div>
			</>
		);
	}
};

export default UsersSettings;
