import React, { useState, Fragment, useEffect } from 'react';
import Switch from 'react-ios-switch';

import Classes from './MiniCraneControl.module.scss';
import '../../../css/border.scss';
import MfaModal from '../../Modals/MfaModal/MfaModal';
import { MyProfile } from '../../../DataTypes/MyProfile';
import CraneView from '../../../ViewModels/CraneView';

/**
 *
 * @param {Object} props
 * @param {CraneView} props.view
 */
const MiniCraneControl = props => {
	const [showPowerMfa, setShowPowerMfa] = useState(false);
	const [showPowerMfaValue, setShowPowerMfaValue] = useState(false);
	/**@type {[MyProfile, any]} */
	const [currentUser, setCurrent] = useState(MyProfile.Current);

	const SetPower = checked => {
		setShowPowerMfa(true);
		setShowPowerMfaValue(checked);
	};

	const sendTokenFuncPower = async () => {
		const result = await props.view.crane.SetPower(showPowerMfaValue);
		return result;
	};

	const verifyTokenFuncPower = async token => {
		const result = await props.view.crane.SetPowerVerify(token);
		return result;
	};

	const closedHandler = () => {
		setShowPowerMfa(false);
	};

	return (
		<Fragment>
			<MfaModal show={showPowerMfa} onClosed={closedHandler} sendTokenFunc={() => sendTokenFuncPower()} verifyTokenFunc={t => verifyTokenFuncPower(t)} />
			<div className={Classes.notificationCard + ` ${props.view.getBorderColor()}`}>
				<div className={Classes.rightSectionControl}>
					<div className={Classes.crane}>מנוף {props.view.crane.number}</div>
					<div className={Classes.sensor}>
						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
							<path
								className={
									props.view.crane.deviceStatus?.isOperatorPresent ? Classes.svgActiveStroke + ' ' + Classes.svgActiveFill : Classes.svgInactiveStroke + ' ' + Classes.svgInactiveFill
								}
								d='M11.165 15.9972V16.4972H11.665H14.6688C15.1505 16.4972 15.6122 16.6882 15.9446 17.02L20.3002 21.3675C20.5691 21.6359 20.5626 22.0534 20.3047 22.3044L20.3047 22.3043L20.3002 22.3088C20.0448 22.5637 19.6099 22.5637 19.3545 22.3088L16.1427 19.1029L15.9963 18.9568H15.7895H9.93196C8.28939 18.9568 6.96598 17.6345 6.96598 15.9972V9.36639C6.96598 8.21345 7.90813 7.27171 9.06547 7.27171H9.10013C9.40583 7.27171 9.71319 7.35294 9.99219 7.48738L9.99216 7.48745L10 7.49107C10.2453 7.60409 10.466 7.76182 10.636 7.94934L12.2531 9.73647L12.2537 9.73709C13.342 10.9357 15.1339 11.8723 16.8728 12.1657C17.2656 12.2323 17.5193 12.537 17.5193 12.8836C17.5193 13.3401 17.0934 13.6923 16.6194 13.5935L16.6189 13.5934C14.9256 13.2422 13.184 12.3465 11.9808 11.3659L11.165 10.701V11.7535V15.9972ZM4.81065 7.9249V17.1504C4.81065 19.3417 6.58352 21.11 8.77664 21.11H14.5533C14.9134 21.11 15.2086 21.406 15.2086 21.7632C15.2086 22.1204 14.9134 22.4164 14.5533 22.4164H8.77664C5.8632 22.4164 3.5 20.0562 3.5 17.1504V7.9249C3.5 7.56766 3.79517 7.27171 4.15533 7.27171C4.51549 7.27171 4.81065 7.56766 4.81065 7.9249Z'
								fill='#101010'
							/>
							<circle
								className={
									props.view.crane.deviceStatus?.isOperatorPresent ? Classes.svgActiveStroke + ' ' + Classes.svgActiveFill : Classes.svgInactiveStroke + ' ' + Classes.svgInactiveFill
								}
								cx='8.5'
								cy='3.5'
								r='2.5'
							/>
						</svg>

						<span>{props.view.crane.deviceStatus?.isOperatorPresent ? 'מפעיל בתא' : 'אין מפעיל בתא'}</span>
					</div>
					<div className={Classes.sensor}>
						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
							<path
								d='M10.4239 4.08734C10.739 3.99104 11.073 3.97408 11.3963 4.03799C11.7195 4.10189 12.022 4.24468 12.2768 4.45364C12.5315 4.66261 12.7307 4.93129 12.8566 5.23579C12.9825 5.54029 13.0313 5.87119 12.9985 6.19906C12.9657 6.52693 12.8524 6.84162 12.6687 7.11516C12.4849 7.38869 12.2365 7.61259 11.9454 7.76696C11.6542 7.92133 11.3295 8.00138 11 8H2M13.4239 19.9127C13.739 20.009 14.073 20.0259 14.3963 19.962C14.7195 19.8981 15.022 19.7553 15.2768 19.5464C15.5315 19.3374 15.7307 19.0687 15.8566 18.7642C15.9825 18.4597 16.0313 18.1288 15.9985 17.8009C15.9657 17.4731 15.8524 17.1584 15.6687 16.8848C15.4849 16.6113 15.2365 16.3874 14.9454 16.233C14.6542 16.0787 14.3295 15.9986 14 16H2M17.73 7.73C18.0208 7.43988 18.3787 7.22591 18.7719 7.10701C19.1652 6.98811 19.5816 6.96794 19.9845 7.04828C20.3874 7.12863 20.7642 7.30702 21.0817 7.56767C21.3993 7.82832 21.6477 8.1632 21.805 8.54269C21.9623 8.92218 22.0236 9.33459 21.9836 9.74344C21.9436 10.1523 21.8035 10.545 21.5756 10.8868C21.3477 11.2286 21.0391 11.509 20.6771 11.7031C20.3151 11.8973 19.9108 11.9993 19.5 12H2'
								stroke='#101010'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>

						<span>{props.view.crane.deviceStatus?.wind.speed.toFixed(0) + ' קמ"ש '}</span>
					</div>
				</div>

				<div className={Classes.leftSectionControl}>
					<div className={Classes.notificationSwitchButton}>
						<Switch
							checked={props.view.crane.deviceStatus?.isPowered}
							onChange={c => SetPower(c)}
							style={{ transform: 'scale(0.7)' }}
							offColor='#DBDCE8'
							disabled={!currentUser?.permissions.cranePowerCRUD.update}
						/>
						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
							<path
								d='M18.36 6.64C19.6184 7.89879 20.4753 9.50244 20.8223 11.2482C21.1693 12.9939 20.9909 14.8034 20.3096 16.4478C19.6284 18.0921 18.4748 19.4976 16.9948 20.4864C15.5148 21.4752 13.7749 22.0029 11.995 22.0029C10.2151 22.0029 8.47515 21.4752 6.99517 20.4864C5.51519 19.4976 4.36164 18.0921 3.68036 16.4478C2.99909 14.8034 2.82069 12.9939 3.16772 11.2482C3.51475 9.50244 4.37162 7.89879 5.63 6.64'
								stroke='#101010'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path d='M12 3L12 12' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
						</svg>
					</div>
					<div className={Classes.notificationSwitchButton}>
						<Switch
							checked={props.view.crane.deviceStatus?.isReleased}
							style={{ transform: 'scale(0.7)' }}
							offColor='#DBDCE8'
							onColor='#242530'
							disabled={props.view.crane.deviceStatus?.isReleased || !currentUser?.permissions.nightReleaseCRUD.update}
						/>

						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
							<path
								d='M21 12.79C20.8427 14.4922 20.2039 16.1144 19.1582 17.4668C18.1126 18.8192 16.7035 19.8458 15.0957 20.4265C13.4879 21.0073 11.748 21.1181 10.0795 20.7461C8.41101 20.3741 6.88299 19.5345 5.67422 18.3258C4.46545 17.117 3.62593 15.589 3.2539 13.9205C2.88187 12.252 2.99271 10.5121 3.57345 8.9043C4.1542 7.29651 5.18082 5.88737 6.53321 4.84175C7.88559 3.79614 9.50779 3.15731 11.21 3C10.2134 4.34827 9.73384 6.00945 9.85852 7.68141C9.98321 9.35338 10.7039 10.9251 11.8894 12.1106C13.0749 13.2961 14.6466 14.0168 16.3186 14.1415C17.9905 14.2662 19.6517 13.7866 21 12.79Z'
								stroke='#101010'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</div>
					<div className={Classes.notificationSwitchButton} hidden={!currentUser?.permissions.craneLockCRUD.read}>
						<Switch checked={props.view.crane.deviceStatus?.isLocked} style={{ transform: 'scale(0.7)' }} offColor='#DBDCE8' disabled={!currentUser?.permissions.craneLockCRUD.update} />

						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
							<rect x='4' y='9' width='16' height='13' rx='1' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
							<circle cx='12' cy='15' r='2' fill='#101010' />
							<path d='M7 6.5V6.5C7 4.01472 9.01472 2 11.5 2H12C14.7614 2 17 4.23858 17 7V9' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
						</svg>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default MiniCraneControl;
