export class Event {
	constructor() {
		/**@type {Function[]} */
		let _eventHandlers = [];

		/**
		 * @param {Function} h
		 */
		this.AddHandler = (h) => {
			_eventHandlers.push(h);
		};

		/**
		 * @param {Function} h
		 */
		this.RemoveHandler = (h) => {
			_eventHandlers = _eventHandlers.filter((x) => x != h);
		};

		/**
		 * @param {any} sender
		 * @param {any} data
		 */
		this.Trigger = (sender, data) => {
			_eventHandlers.forEach((x) => x(sender, data));
		};
	}
}
