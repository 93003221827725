import { Phone } from "../MyProfile";
import axios from "axios";

export class EditRegistrationUser {
  /**@type {string} */
  id = null;
  /**@type {string} */
  fullName = null;
  /**@type {string} */
  mail = null;
  /**@type {Phone} */
  phone = null;
  /**@type {string} */
  role = null;
  /**@type {string} */
  company = null;
  /**@type {string} */
  password = null;
  /**@type {boolean} */
  isPending = false;

  constructor(data) {
    this.id = data?.userId;
    this.fullName = data?.fullName;
    this.mail = data?.mail;
    this.phone = new Phone(data?.phone);
    this.role = data?.role;
    this.company = data?.company;
    this.password = data?.password;
    this.validationCode = data?.validationCode;
    this.isPending = data?.isPending;
  }

  GetPassword() {
    if (this.password === null) {
      return false;
    } else {
      return this.password;
    }
  }

  Edit(key, value) {
    switch (key) {
      case "phone":
        this.phone = new Phone({ number: value, countryCode: "972" });
        break;
      default:
        this[key] = value;
        break;
    }
  }

  GetInviteDetails(token) {
    return axios.get(`/User/InviteDetails`, {
      params: {
        token: token,
      },
    });
  }

  Register() {
    return axios.post("/User/Register", {
      userId: this.id,
      fullName: this.fullName,
      phoneNumber: this.phone.number,
      countryCode: this.phone.countryCode,
      password: this.password,
    });
  }

  RegisterFinish() {
    return axios.post("/User/RegisterFinish", null, {
      params: {
        userId: this.id,
        token: this.validationCode,
      },
    });
  }
}
