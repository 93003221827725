import React, { useState, useEffect, useRef } from 'react';
import useOutsideClick from '../../../utils/useOutsideClick';
import Switch from 'react-ios-switch';
import cn from 'classnames';

import { Crane } from '../../../DataTypes/Crane';
import { MyProfile } from '../../../DataTypes/MyProfile';

import Classes from './ControlNotification.module.scss';
import { ReactComponent as AlertIcon } from '../../../assets/icons/notification-alert-circle.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/notification-more.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/sidebar-close.svg';

const ControlNotification = props => {
	const controlsRef = useRef();

	/**@type {[MyProfile, any]} */
	const [currentUser, setCurrentUser] = useState(MyProfile.Current);
	/**@type {[Crane, any]} */
	const [crane, setCrane] = useState(props.crane);

	const [controlsOpen, setControlsOpen] = useState(false);

	const onScroll = () => {
		if (controlsOpen) setControlsOpen(false);
	}

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		}
	})

	useOutsideClick(controlsRef, () => {
		if (controlsOpen) setControlsOpen(false);
	});

	return (
		<div className={Classes.notificationCard}>
			<div className={Classes.rightSectionControl}>
				<MoreIcon className={Classes.moreIcon} onClick={() => setControlsOpen(true)} />
				<AlertIcon className={Classes.alertIcon} />
				<div className={Classes.crane}>{' מנוף ' + props.notification?.crane?.number}</div>
				<div className={Classes.site}>{props.notification?.crane?.site?.name}</div>
			</div>

			<div className={Classes.centerSectionControl}>
				<div className={Classes.content}>{props.notification?.data}</div>
				<div className={Classes.time}>{props.notification?.createdTime}</div>
			</div>

			<div className={cn(Classes.leftSectionControl, controlsOpen && Classes.open)} ref={controlsRef}>
				<CloseIcon className={Classes.close} onClick={() => setControlsOpen(false)} />
				<div className={Classes.switch}>
					<Switch
						checked={props.notification?.crane?.deviceStatus?.isPowered}
						onChange={null}
						style={{ transform: 'scale(0.7)' }}
						offColor='#DBDCE8'
						// disabled={!currentUser?.permissions.cranePowerCRUD.update}
						disabled={true}
					/>
					<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
						<path
							d='M18.36 6.64C19.6184 7.89879 20.4753 9.50244 20.8223 11.2482C21.1693 12.9939 20.9909 14.8034 20.3096 16.4478C19.6284 18.0921 18.4748 19.4976 16.9948 20.4864C15.5148 21.4752 13.7749 22.0029 11.995 22.0029C10.2151 22.0029 8.47515 21.4752 6.99517 20.4864C5.51519 19.4976 4.36164 18.0921 3.68036 16.4478C2.99909 14.8034 2.82069 12.9939 3.16772 11.2482C3.51475 9.50244 4.37162 7.89879 5.63 6.64'
							stroke='#101010'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path d='M12 3L12 12' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</div>
				<div className={Classes.switch}>
					<Switch
						checked={crane?.deviceStatus?.isReleased}
						onChange={null}
						style={{ transform: 'scale(0.7)' }}
						offColor='#DBDCE8'
						onColor='black'
						// disabled={props.crane?.deviceStatus?.isReleased || !currentUser?.permissions.nightReleaseCRUD.update}
						disabled={true}
					/>
					<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
						<path
							d='M21 12.79C20.8427 14.4922 20.2039 16.1144 19.1582 17.4668C18.1126 18.8192 16.7035 19.8458 15.0957 20.4265C13.4879 21.0073 11.748 21.1181 10.0795 20.7461C8.41101 20.3741 6.88299 19.5345 5.67422 18.3258C4.46545 17.117 3.62593 15.589 3.2539 13.9205C2.88187 12.252 2.99271 10.5121 3.57345 8.9043C4.1542 7.29651 5.18082 5.88737 6.53321 4.84175C7.88559 3.79614 9.50779 3.15731 11.21 3C10.2134 4.34827 9.73384 6.00945 9.85852 7.68141C9.98321 9.35338 10.7039 10.9251 11.8894 12.1106C13.0749 13.2961 14.6466 14.0168 16.3186 14.1415C17.9905 14.2662 19.6517 13.7866 21 12.79Z'
							stroke='#101010'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div>
				<div className={Classes.switch} hidden={!currentUser?.permissions.craneLockCRUD.read}>
					<Switch
						checked={false}
						onChange={null}
						style={{ transform: 'scale(0.7)' }}
						offColor='#DBDCE8'
						// disabled={!currentUser?.permissions.craneLockCRUD.update}
						disabled={true}
					/>

					<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={Classes.icons}>
						<rect x='4' y='9' width='16' height='13' rx='1' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
						<circle cx='12' cy='15' r='2' fill='#101010' />
						<path d='M7 6.5V6.5C7 4.01472 9.01472 2 11.5 2H12C14.7614 2 17 4.23858 17 7V9' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</div>
			</div>
		</div>
	);
};

export default ControlNotification;
