import * as Msal from 'msal';

// The current application coordinates were pre-registered in a B2C tenant.
const apiConfig = {
	b2cScopes: ['https://visionlaborg.onmicrosoft.com/8416038c-fc74-4756-aeda-0731b6b6b9f4/scope'],
};

const b2cPolicies = {
	authorities: {
		signUpSignIn: {
			authority: 'https://visionlaborg.b2clogin.com/visionlaborg.onmicrosoft.com/B2C_1_SIGN_IN',
		},
		forgotPassword: {
			authority: 'https://visionlaborg.b2clogin.com/visionlaborg.onmicrosoft.com/B2C_1_RESET',
		},
		editProfile: {
			authority: 'https://visionlaborg.b2clogin.com/visionlaborg.onmicrosoft.com/B2C_1_EDIT',
		},
	},
};

/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * */
const msalConfig = {
	auth: {
		clientId: '8416038c-fc74-4756-aeda-0731b6b6b9f4',
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		validateAuthority: false,
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
	},
};

// Add here scopes for access token to be used at the API endpoints.
const tokenRequest = {
	scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export const MsalObj = new Msal.UserAgentApplication(msalConfig);

export function LoginPopup() {
	/**
	 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
	 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
	 */
	const loginRequest = {
		redirectUri: window.location.origin,
		scopes: ['openid', 'profile'],
	};

	return MsalObj.loginPopup(loginRequest);
}

export function LoginRedirect() {
	const loginRequest = {
		redirectUri: window.location.origin,
		scopes: ['openid', 'profile'],
	};

	return MsalObj.loginRedirect(loginRequest);
}

export function ForgotPasswordPopup() {
	return MsalObj.loginPopup(b2cPolicies.authorities.forgotPassword);
}

export function Logout() {
	return MsalObj.logout();
}
