import { Manager } from "../Manager";
import axios from "axios";

export class EditCompany {
	/**@type {string} */
	id = null;

	/**@type {string} */
	sn = null;

	/**@type {string} */
	name = null;

	/**@type {string} */
	officeAddress = null;

	/**@type {Manager} */
	manager = null;

	constructor(data = null) {
		this.id = data?.id;
		this.name = data?.name;
		this.sn = data?.sn;
		this.officeAddress = data?.officeAddress;
		this.manager = data?.manager;
	}

	Edit(key, value) {
		switch (key) {
			case "managerName":
				this.manager = new Manager({ name: value, phone: this.manager?.phone });
				break;
			case "managerPhone":
				this.manager = new Manager({ name: this.manager?.name, phone: value });
				break;
			default:
				this[key] = value;
				break;
		}
	}
	UpdateDetails = (details) => {
		return axios.put(`/Company/${this.id}`, details);
	};
}
