import React, { Fragment, useState, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router'
import cn from 'classnames';

import axios from 'axios';
import { Collapse } from 'react-collapse';
import logo from '../../assets/img/skylinelogo.png';
import HierarchyContext from '../../contexts/HierarchyContext';

import Classes from './Sidebar.module.scss';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/sidebar-plus.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/sidebar-close.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/sidebar-profile.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

const Sidebar = props => {
	const hierarchy = props.hierarchy;
	const location = useLocation();
	const history = useHistory();

	const [activeCompanyIndex, setActiveCompanyIndex] = useState(null);
	const [activeSiteIndex, setActiveSiteIndex] = useState(null);
	const [siteChildrenActive, setSiteChildrenActive] = useState(null);
	const [companyChildrenActive, setCompanyChildrenActive] = useState(null);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const [entity, setEntity] = useState(null)

	function goToMain() {
		setActiveCompanyIndex(null)
		setActiveSiteIndex(null)
		setSiteChildrenActive(null)
		setCompanyChildrenActive(null)
	}

	function toggleComp(compId) {
		setActiveCompanyIndex(activeCompanyIndex === compId ? null : compId);
		setCompanyChildrenActive(null)
		setSiteChildrenActive(null);
	}
	
	function toggleSite(siteId, companyId) {
		setCompanyChildrenActive(companyId)
		setActiveCompanyIndex(companyId);
		setActiveSiteIndex(activeSiteIndex === siteId ? null : siteId);
		setSiteChildrenActive(null);
	}

	function getCompanyClass(company) {
		return (
			cn(
				Classes.navLink , 
				(activeCompanyIndex === company.id ? Classes.activeNav : null), 
				((activeCompanyIndex === company.id 
					&& (activeSiteIndex != null 
						&& (() => company.sities.find(site => site.id === activeSiteIndex))
					)
				) || (activeCompanyIndex === company.id 
					&& companyChildrenActive != null 
					&& (() => company.id = companyChildrenActive)
				) ? Classes.companyChildrenActive : null)
			)
		)
	}

	function getSiteClass(id) {
		return (
			cn(
				Classes.navLink ,
				(activeSiteIndex === id ? Classes.activeNav: null),
				(activeSiteIndex === id 
				&& (siteChildrenActive != null 
					&& id === siteChildrenActive
				) 
				? Classes.siteChildrenActive 
				: null)
			)
		)
	}

	useEffect(() => {
		setSidebarOpen(false)

		const isCrane = location.pathname.includes('/crane/');
		const isSite = location.pathname.includes('/site/');
		const isCompany = location.pathname.includes('/company/');

		const pathnameParts = location.pathname.split('/')
		const entityId = pathnameParts[2]

		if (isCompany) {
			setEntity({ type: 'company', object: props.hierarchy.companies.find(c => c.id === entityId) })
		} else if (isSite) {
			setEntity({ type: 'site', object: props.hierarchy.sites.find(s => s.id === entityId) })
		} else if (isCrane) {
			setEntity({ type: 'crane', object: props.hierarchy.cranes.find(c => c.id === entityId) })
		} else {
			setEntity(null)
		}
	}, [location.pathname])

	return (
		<>
			<div className={cn(Classes.backdrop, sidebarOpen && Classes.open)} onClick={() => setSidebarOpen(false)} />
			<div className={Classes.mobileHeader} dir='rtl'>
				{(entity && entity.type === 'company') && (
					<span className={Classes.title}>
						<span>{entity.object.name}</span>
					</span>
				)}
				{(entity && entity.type === 'site') && (
					<span className={Classes.title}>
						<span>{entity.object.company.name}</span>
						<span>{entity.object.name}</span>
					</span>
				)}
				{(entity && entity.type === 'crane') && (
					<span className={Classes.title}>
						<span>{entity.object.company.name}</span>
						<span>{entity.object.site.name}</span>
						<span>מנוף {entity.object.number}</span>
					</span>
				)}
				{!entity && (
					<span className={Classes.title}>
						<span>תצוגה ראשית</span>
					</span>
				)}
				<button className={Classes.menuBtn} onClick={() => setSidebarOpen(true)}>
					<MenuIcon className={Classes.menuIcon} />
				</button>
			</div>
			<div className={cn(Classes.sidebar, sidebarOpen && Classes.open)} dir='rtl'>
				<span className={Classes.header}>
					<button className={Classes.closeBtn} onClick={() => setSidebarOpen(false)}>
						<CloseIcon className={Classes.closeIcon} />
					</button>

					<div className={Classes.profile} onClick={() => history.push('/userProfile')}>
						<span className={Classes.name}>{props.currentUser.fullName}</span>
						<ProfileIcon className={Classes.icon} />
					</div>
				</span>

				<span className={Classes.mobileScroll}>
					<div className={Classes.sidebarHeader}>
						<img src={logo} alt='Skyline' />
					</div>
					<div className={Classes.org} onClick={goToMain}>
						<Link to='/' activeClassName={Classes.activeHome} exact>
							<img src={require('../../assets/icons/Home.svg')} />
							תצוגה ראשית
						</Link>
					</div>
					<div className={Classes.companies}>חברות</div>
					<div className={Classes.nestedList}>
						{hierarchy.companies.map((company, index) => {
							return (
								<Fragment key={index}>
									<div className={Classes.company} onClick={() => toggleComp(company.id)}>
										<Link
											to={{
												pathname: `/company/${company.id}`,
											}}
											className={getCompanyClass(company)}
											activeClassName={Classes.companyActive}>
											{company.sites.length != 0 ? <Arrow onClick={e => e.preventDefault()} /> : null}
											{company.name}
										</Link>
									</div>
									<Collapse isOpened={activeCompanyIndex === company.id}>
										{company.sites.map((site, index) => {
											return (
												<Fragment key={index}>
													<div className={Classes.site} onClick={() => toggleSite(site.id, company.id)}>
														<Link
															to={{
																pathname: `/site/${site.id}`,
															}}
															className={getSiteClass(site.id)}
															activeClassName={Classes.siteActive}>
															{site.cranes.length != 0 ? <Arrow onClick={e => e.preventDefault()} /> : null}
															{site.name}
														</Link>
													</div>
													<Collapse isOpened={activeSiteIndex === site.id}>
														{site.cranes.map((crane, index) => {
															return (
																<div className={Classes.crane} key={index} onClick={() => setSiteChildrenActive(site.id)}>
																	<Link
																		to={{
																			pathname: `/crane/${crane.id}`,
																		}}
																		className={Classes.navLink}
																		activeClassName={Classes.craneActive}>
																		מנוף {crane.number}
																	</Link>
																</div>
															);
														})}
													</Collapse>
												</Fragment>
											);
										})}
									</Collapse>
								</Fragment>
							);
						})}
					</div>
					<div
						className={Classes.footerSidebar}
						hidden={!props.currentUser.permissions.craneCRUD.create && !props.currentUser.permissions.siteCRUD.create && !props.currentUser.permissions.companyCRUD.create}>
						<PlusIcon className={Classes.plusIcon} />
						<div className={Classes.addNewItem} role='button' onClick={props.openHandler}>
							הוספת פריט
						</div>
					</div>
				</span>
			</div>
		</>
	);
};

export default Sidebar;
