import { Company } from '../DataTypes/Company';
import CraneView from './CraneView';
import SiteView from './SiteView';

class CompanyView {
	/**@type {Company}*/
	company;

	/**@type {SiteView[]} */
	siteViews = [];

	/**@type {CraneView[]} */
	craneViews = [];

	/**@param {Company} company*/
	constructor(company) {
		this.company = company;

		this.siteViews = this.company.sites.map(s => {
			const site = new SiteView(s);
			this.craneViews = this.craneViews.concat(site.craneViews);
			return site;
		});
	}
}

export default CompanyView;
