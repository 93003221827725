import React, { useState, useEffect, useRef } from 'react';
import Classes from './BlackboxSettings.module.scss';
import Spinner from '../../../UI/Spinner/Spinner';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import BBAssignmentModal from '../../../Modals/BBAssignmentModal/BBAssignmentModal';
import BBUnassignmentModal from '../../../Modals/BBUnassignmentModal/BBUnassignmentModal';
import NewBlackboxModal from '../../../Modals/NewBlackboxModal/NewBlackboxModal';
import BBRemoveModal from '../../../Modals/BBRemoveModal/BBRemoveModal';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/sidebar-close.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import cn from 'classnames';
import useOutsideClick from '../../../../utils/useOutsideClick';

const BlackboxSettings = props => {
	const controlsRef = useRef();
	const [subscriptionCredit, setSubscriptionCredit] = useState(null);
	const [blackboxes, setBlackboxes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [companyId, setCompanyId] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [craneId, setCraneId] = useState(null);
	const [currentBlackbox, setCurrentBlackbox] = useState(null);
	const [currentLicense, setCurrentLicense] = useState(null);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showUnassignModal, setShowUnassignModal] = useState(false);
	const [showAddBlackboxModal, setShowAddBlackboxModal] = useState(false);
	const [showRemoveBbModal, setShowRemoveBbModal] = useState(false);
	const [cranes, setCranes] = useState(null);
	const [form, setForm] = useState(null);
	const [newCrane, setNewCrane] = useState({});

	const [openDistIds, setOpenDistIds] = useState([]);
	const [openControls, setOpenControls] = useState(null);

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	useEffect(() => {
		loadData();
		setIsLoading(false);
	}, [props.hierarchy.subscriptionCredit]);

	const loadData = () => {
		if (props.hierarchy.subscriptionCredit != null) {
			let sc = props.hierarchy.subscriptionCredit;
			let bb = sc.licenses
				.filter(x => x.blackboxBinding != null)
				.map(x => {
					x.blackboxBinding.blackbox.license = x;
					return x.blackboxBinding.blackbox;
				});

			bb = bb.concat(sc.blackboxes);

			setSubscriptionCredit(sc);
			setBlackboxes(bb);
		}
	};

	const setCurrentBlackboxAndLicense = blackbox => {
		setCurrentBlackbox(blackbox);
		setCurrentLicense(blackbox.license);

		const crane = props.hierarchy.cranes.find(c => c.id === blackbox.license?.blackboxBinding?.craneBinding?.craneId);

		setCompanyId(crane?.company.id);
		setSiteId(crane?.site.id);
		setCraneId(crane?.id);
	};

	const assignModalHandler = blackbox => {
		if(blackbox != null)
			setCurrentBlackboxAndLicense(blackbox)

		const show = showAssignModal;
		setShowAssignModal(!show);
	};

	const unassignModalHandler = blackbox => {
		if(blackbox != null)
			setCurrentBlackboxAndLicense(blackbox)

		const show = showUnassignModal;
		setShowUnassignModal(!show);
	};

	const removeModalHandler = blackbox => {
		if(blackbox != null)
			setCurrentBlackboxAndLicense(blackbox)

		const show = showRemoveBbModal;
		setShowRemoveBbModal(!show);
	};

	const addBlackboxModalHandler = () => {
		const show = showAddBlackboxModal;
		setShowAddBlackboxModal(!show);
	};
	const createCranesDropdownList = () => {
		let items = [];
		let assignmentCranes = subscriptionCredit?.licenses.filter(license => license?.blackboxBinding?.craneBinding?.craneId ?? null);

		props.hierarchy.cranes.map(crane =>
			assignmentCranes?.find(c => c.id === crane.id)
				? items.push(
						<option key={crane.id} value={crane.id} disabled>
							{crane.sn}
						</option>
				  )
				: items.push(
						<option key={crane.id} value={crane.id}>
							{crane.sn}
						</option>
				  )
		);
		return items;
	};

	const craneSnFinder = craneId => {
		const crane = props.hierarchy.cranes.find(c => c.id === craneId);
		if (crane) {
			return crane.sn;
		} else {
			return null;
		}
	};

	const blackboxSnFinder = () => {
		let sn = [];
		subscriptionCredit.blackboxes.map(b => sn.push(b.sn));
		return sn;
	};

	const assignPopover = (
		<Popover id='assign-popover' className={Classes.popover}>
			<div role='button' className={Classes.action} onClick={() => assignModalHandler(null)}>
				{'שייך למנוף'}
			</div>
			{/* <div
					role='button'
					className={Classes.comment}
					// onClick={}
				>
					{"הערות"}
				</div> */}
		</Popover>
	);
	const unassignPopover = (
		<Popover id='assign-popover' className={Classes.popover}>
			<div role='button' className={Classes.action} onClick={() => unassignModalHandler(null)}>
				{'ביטול שיוך'}
			</div>
			{/* <div
					role='button'
					className={Classes.comment}
					// onClick={}
				>
					{"הערות"}
				</div> */}
		</Popover>
	);
	const removePopover = (
		<Popover id='remove-popover' className={Classes.popover}>
			<div role='button' className={Classes.removeBb} onClick={() => removeModalHandler(null)}>
				{'הסרה'}
			</div>
		</Popover>
	);

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={Classes.wrapper}>
			
			{showAssignModal ? (
				<BBAssignmentModal
					show={showAssignModal}
					assignModalHandler={assignModalHandler}
					cranesDropdownList={createCranesDropdownList}
					license={currentLicense}
					blackbox={currentBlackbox}
					hierarchy={props.hierarchy}
				/>
			) : null}
			{showUnassignModal ? (
				<BBUnassignmentModal
					show={showUnassignModal}
					unassignModalHandler={unassignModalHandler}
					license={currentLicense}
					blackbox={currentBlackbox}
					companyId={companyId}
					siteId={siteId}
					craneId={craneId}
					craneSn={craneSnFinder(craneId)}
				/>
			) : null}
			{showAddBlackboxModal ? <NewBlackboxModal show={showAddBlackboxModal} addBlackboxModalHandler={addBlackboxModalHandler} subscriptionCredit={subscriptionCredit} /> : null}
			{showRemoveBbModal ? <BBRemoveModal show={showRemoveBbModal} removeModalHandler={removeModalHandler} blackbox={currentBlackbox} /> : null}

			<div className={Classes.card}>
				<div className={Classes.cardHeader}>
					<div className={Classes.cardTitle}>{'קופסאות שחורות'}</div>
					<div className={Classes.addBlackboxButton} role='button' onClick={addBlackboxModalHandler}>
						<div className={'material-icons'}>add_circle_outline</div>
					</div>
				</div>
				<table className={cn(Classes.decstopTable , 'table table-borderless') }>
					<thead>
						<tr>
							<th scope='col'>סוג קופסה</th>
							<th scope='col'>מס' סידורי</th>
							<th scope='col'>גרסה</th>
							<th scope='col'>משויך</th>
							<th scope='col'>מנוף</th>
							<th scope='col'>רשיון</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{blackboxes.map((blackbox, index) => {
							return (
								<tr key={index}>
									<td>{blackbox.type === 'Full' ? 'SBC' : blackbox.type === 'Lite' ? 'SBL' : blackbox.type === 'Feeder' ? 'SBF' : '-'}</td>
									<td>{blackbox.friendlyName}</td>
									<td>{blackbox.version ?? '-'}</td>

									<td>{blackbox.license?.blackboxBinding?.craneBinding ? <img src={require('../../../../assets/icons/vector.svg')} className={Classes.vectorIcon}></img> : '-'}</td>
									<td>{craneSnFinder(blackbox.license?.blackboxBinding?.craneBinding?.craneId) ?? '-'}</td>
									<td>{blackbox.license?.id ?? '-'}</td>
									<td className={Classes.button}>
										<div role='button'>
											{blackbox.license?.id && !blackbox.license?.blackboxBinding?.craneBinding ? (
												<OverlayTrigger
													trigger='click'
													placement='right-start'
													onToggle={() => setCurrentBlackboxAndLicense(blackbox)}
													overlay={assignPopover}
													rootClose={true}>
													<img role='button' src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon}></img>
												</OverlayTrigger>
											) : blackbox.license?.id && blackbox.license?.blackboxBinding?.craneBinding ? (
												<OverlayTrigger
													trigger='click'
													placement='right-start'
													onToggle={() => setCurrentBlackboxAndLicense(blackbox)}
													overlay={unassignPopover}
													rootClose={true}>
													<img role='button' src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon}></img>
												</OverlayTrigger>
											) : (
												<OverlayTrigger
													trigger='click'
													placement='right-start'
													onToggle={() => setCurrentBlackboxAndLicense(blackbox)}
													overlay={removePopover}
													rootClose={true}>
													<img role='button' src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon}></img>
												</OverlayTrigger>
											)}
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>

				<table className={cn(Classes.mobileTable , 'table table-borderless fw-bold')}>
					<thead>
						<tr>
							<th scope='col'>סוג קופסה</th>
							<th scope='col'>מס' סידורי</th>
							<th scope='col'>משויך</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{blackboxes.map((blackbox, index) => {
									return (
										<>
											<tr key={index}>
												<td>{blackbox.type === 'Full' ? 'SBC' : blackbox.type === 'Lite' ? 'SBL' : blackbox.type === 'Feeder' ? 'SBF' : '-'}</td>
												<td>{blackbox.friendlyName}</td>
												<td className={Classes.biding}>{blackbox.license?.blackboxBinding?.craneBinding ? <img src={require('../../../../assets/icons/vector.svg')} className={Classes.vectorIcon}></img> : '-'}</td>
												<td className={Classes.expandButton} onClick={() => toggleOnetDistId(index)}><ChevronIcon/></td>
											</tr>

											<tr className={cn((openDistIds.includes(index) ? Classes.open : null), Classes.tableExpand)}>
												<td colSpan={3}>
													<div>
														<span className={Classes.distHeader}>גרסה</span>
														<span>{blackbox.version ?? '-'}</span>
													</div>
													<div>
														<span className={Classes.distHeader}>מנוף</span>
														<span>{craneSnFinder(blackbox.license?.blackboxBinding?.craneBinding?.craneId) ?? '-'}</span>
													</div>
													<div>
														<span className={Classes.distHeader}>רשיון</span>
														<span>{blackbox.license?.id ?? '-'}</span>
													</div>
												</td>
												<td className={Classes.mobileButton}>
													<div role='button'>
														<img src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon} onClick={() => setOpenControls(index)}></img>
													</div>
													<div className={cn(Classes.controls, openControls === index ? Classes.openControls : null)} ref={controlsRef}>
														<CloseIcon className={Classes.close} onClick={() => setOpenControls(null)} />
														{blackbox.license?.id && !blackbox.license?.blackboxBinding?.craneBinding ? (
															<div role='button' className={Classes.action} onClick={() => assignModalHandler(blackbox)}>
																<span>'שייך למנוף'</span>
															</div>
														) : blackbox.license?.id && blackbox.license?.blackboxBinding?.craneBinding ? (
															<div role='button' className={Classes.action} onClick={() => unassignModalHandler(blackbox)}>
																<span>'ביטול שיוך'</span>
															</div>
														) : (
															<div role='button' className={Classes.removeBb} onClick={() => removeModalHandler(blackbox)}>
																<span>'הסרה'</span>
															</div>
														)}
													</div>
												</td>
											</tr>
										</>
									);
								})
							}
					</tbody>
				</table>
			</div>

			<div className={Classes.tableHeader}>
				<div>
					<FilterIcon/>
					<p>סינון</p>
				</div>
				<div>
					<SearchIcon/>
					<p>חיפוש</p>
				</div>
				<div onClick={addBlackboxModalHandler}>
					<PlusIcon/>
					<p>הוספה</p>
				</div>
			</div>

			<div className={Classes.licenseAmountCard}>
				<div className={Classes.licenseAmountCardTitle}>כמות קופסאות שחורות</div>
				<div className={Classes.licenseAmountCardBody}>
					<div className={Classes.licenseAmount}>
						<span className={Classes.licenseAmountKey}>SBC :</span>
						<span className={Classes.licenseAmountValue}>{blackboxes?.filter(blackbox => blackbox.type === 'Full').length}</span>
					</div>
					<div className={Classes.licenseAmount}>
						<span className={Classes.licenseAmountKey}>SBL :</span>
						<span className={Classes.licenseAmountValue}>{blackboxes?.filter(blackbox => blackbox.type === 'Lite').length}</span>
					</div>
					<div className={Classes.licenseAmount}>
						<span className={Classes.licenseAmountKey}>SBF :</span>
						<span className={Classes.licenseAmountValue}>{blackboxes?.filter(blackbox => blackbox.type === 'Feeder').length}</span>
					</div>
				</div>
				<div className={Classes.licenseAmountCardFooter}>
					<span>{`בשימוש ${subscriptionCredit?.licenses.filter(license => license?.blackboxBinding?.craneBinding != null).length}/${blackboxes.length}`}</span>
				</div>
			</div>
		</div>
	);
};

export default BlackboxSettings;
