import React, { isValidElement } from 'react';
import Classes from './FormikForm.module.css';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikControl from '../../Form/FormikControl/FormikControl';

function FormikForm(props) {
	const initialValues = {
		name: '',
		email: '',
		age: '',
	};
	const onSubmit = values => {
		console.log('Form data', values);
	};

	const validationSchema = Yup.object({
		name: Yup.string().required('נא הזן שם מלא'),
		email: Yup.string().email('נא הזן מייל תקין').required('נא הזן מייל'),
		age: Yup.number().required('הזן גיל חוקי'),
	});

	return (
		<Modal
			className={Classes.modal}
			aria-labelledby='contained-modal-title-vcenter'
			show={props.show}
			onHide={props.assignModalHandler}
			centered
			dir='rtl'>
			<Modal.Header style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>Formik example form</div>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					validateOnMount>
					{formik => {
						return (
							<Form className={Classes.form}>
								<FormikControl
									className={Classes.control}
									control='input'
									type='text'
									label='name'
									name='name'
								/>
								<FormikControl
									className={Classes.control}
									control='input'
									type='email'
									label='email'
									name='email'
								/>
								<FormikControl
									className={Classes.control}
									control='input'
									type='number'
									label='age'
									name='age'
								/>
								<button
									type='submit'
									className={Classes.submitButton}
									disabled={!formik.isValid}>
									{'אישור'}
								</button>
							</Form>
						);
					}}
				</Formik>
			</Modal.Body>
		</Modal>
	);
}

export default FormikForm;
