export class MyProfile {
	/**@type {MyProfile} */
	static Current = null;

	/**@type {string} */
	fullName = null;
	/**@type {string} */
	mail = null;
	/**@type {string} */
	role = null;

	/**@type {Phone} */
	phone = null;

	/**@type {Permissions} */
	permissions = null;

	constructor(data) {
		this.fullName = data.fullName;
		this.mail = data.mail;
		this.role = data.role;
		this.phone = new Phone(data.phone);
		this.permissions = new Permissions();

		//Company
		this.permissions.companyCRUD.create = this.PermissionBuilder([Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd], this.role);
		this.permissions.companyCRUD.read = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.SafetyManager, Role.OperationManager, Role.CranesManager, Role.SiteManager],
			this.role
		);
		this.permissions.companyCRUD.update = this.PermissionBuilder([Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd], this.role);
		this.permissions.companyCRUD.delete = this.PermissionBuilder([Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd], this.role);

		//Site
		this.permissions.siteCRUD.create = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.OperationManager, Role.CranesManager],
			this.role
		);
		this.permissions.siteCRUD.read = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.SafetyManager, Role.OperationManager, Role.CranesManager, Role.SiteManager],
			this.role
		);
		this.permissions.siteCRUD.update = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.OperationManager, Role.CranesManager],
			this.role
		);
		this.permissions.siteCRUD.delete = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.OperationManager, Role.CranesManager],
			this.role
		);

		//Crane
		this.permissions.craneCRUD.create = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.OperationManager, Role.CranesManager],
			this.role
		);
		this.permissions.craneCRUD.read = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.SafetyManager, Role.OperationManager, Role.CranesManager, Role.SiteManager],
			this.role
		);
		this.permissions.craneCRUD.update = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.SafetyManager, Role.OperationManager, Role.CranesManager, Role.SiteManager],
			this.role
		);
		this.permissions.craneCRUD.delete = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.OperationManager, Role.CranesManager],
			this.role
		);

		//Crane Power
		this.permissions.cranePowerCRUD.read = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.SafetyManager, Role.OperationManager, Role.CranesManager, Role.SiteManager],
			this.role
		);
		this.permissions.cranePowerCRUD.update = this.PermissionBuilder([Role.ServiceManager], this.role);

		//Night Release
		this.permissions.nightReleaseCRUD.read = this.PermissionBuilder(
			[Role.SuperAdmin, Role.ServiceManager, Role.ServiceManager2nd, Role.SafetyManager, Role.OperationManager, Role.CranesManager, Role.SiteManager],
			this.role
		);
		this.permissions.nightReleaseCRUD.update = this.PermissionBuilder([Role.ServiceManager], this.role);

		//Crane Lock
		this.permissions.craneLockCRUD.read = this.PermissionBuilder([Role.ServiceManager, Role.ServiceManager2nd], this.role);
		this.permissions.craneLockCRUD.update = this.PermissionBuilder([Role.ServiceManager], this.role);

		//Settings
		this.permissions.settingsCRUD.read = this.PermissionBuilder([Role.SuperAdmin, Role.ServiceManager], this.role);
	}

	PermissionBuilder = (arr, role) => {
		return arr.includes(role);
	};
}

export class Phone {
	/**@type {string} */
	number = null;
	/**@type {string} */
	countryCode = null;

	constructor(data) {
		this.number = data?.number;
		this.countryCode = data?.countryCode ?? "972";
	}
}

export class Permissions {
	/**@type {CRUD} */
	companyCRUD = new CRUD();

	/**@type {CRUD} */
	siteCRUD = new CRUD();

	/**@type {CRUD} */
	craneCRUD = new CRUD();

	/**@type {CRUD} */
	cranePowerCRUD = new CRUD();

	/**@type {CRUD} */
	nightReleaseCRUD = new CRUD();

	/**@type {CRUD} */
	craneLockCRUD = new CRUD();

	/**@type {CRUD} */
	settingsCRUD = new CRUD();
}

export class CRUD {
	create = false;
	read = false;
	update = false;
	delete = false;
}

export const Role = {
	SuperAdmin: "SuperAdmin",
	ServiceManager: "ServiceManager",
	ServiceManager2nd: "ServiceManager2nd",
	SafetyManager: "SafetyManager",
	OperationManager: "OperationManager",
	CranesManager: "CranesManager",
	SiteManager: "SiteManager",
};
