import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import './App.scss';
import Toolbar from './components/Toolbar/Toolbar';
import Sidebar from './components/Sidebar/Sidebar';
import Organization from '../src/components/Organization/Organization';
import Company from '../src/components/Company/Company';
import Site from '../src/components/Site/Site';
import Crane from '../src/components/Crane/Crane';
import NotificationsPage from './components/Toolbar/NotificationsPage/NotificationsPage';
import UserProfile from '../src/components/Toolbar/UserProfile/UserProfile';
import Settings from '../src/components/Toolbar/Settings/Settings';
import Spinner from './components/UI/Spinner/Spinner';
import NewItem from './components/NewItem/NewItem';
import MfaModal from './components/Modals/MfaModal/MfaModal';

import { HierarchyProvider } from './contexts/HierarchyContext';
import { Hierarchy } from './DataTypes/Hierarchy';
import { SubscriptionCredit } from './DataTypes/SubscriptionCredit';
import { User } from './DataTypes/User';
import { MyProfile } from './DataTypes/MyProfile';
import { Notification } from './DataTypes/Notification';
import { NotificationManager } from './DataTypes/NotificationManager';
import NotificationList from './components/Notification/NotificationList/NotificationList';
import ReadNotification from './components/Notification/ReadNotification/ReadNotification';
import ErrorModal from './components/ErrorModal/ErrorModal';
import ApiResponseModal from './components/UI/ApiResponseModal/ApiResponseModal';
import LoadingModalProvider from './contexts/LoadingModalProvider';
import HierarchyView from './ViewModels/HierarchyView';
import { Logout } from './auth';
import ToolbarView from './ViewModels/ToolbarView';

const App = props => {
	const [showAddItem, setShowAddItem] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	/**@type {[Hierarchy, (Hierarchy) => void]} */
	const [hierarchy, setHierarchy] = useState(null);

	/**@type {[MyProfile, (MyProfile) => void]} */
	const [currentUser, setCurrentUser] = useState(null);

	/**@type {[HierarchyView, (HierarchyView) => void]} */
	const [hierarchyView, setHierarchyView] = useState(null);

	useEffect(() => {
		const asyncFetchDailyData = async () => {
			// if (process.env.NODE_ENV === "development") {
				axios.defaults.baseURL = 'https://vl-app-dev.azurewebsites.net'
			// }

			axios.defaults.headers.common['access_token'] = props.account.accountIdentifier;

			await axios
				.get('/User')
				.then(response => {
					MyProfile.Current = new MyProfile(response.data);
					setCurrentUser(MyProfile.Current);
				})
				.catch(error => <ErrorModal content={error} />);

			await loadData();
		};
		asyncFetchDailyData();
	}, []);

	const loadData = async () => {
		let notificationsCache = hierarchy?.notificationManager ?? new NotificationManager();

		let fetchedHierarchy = await Hierarchy.FetchHierarchy();
		if (fetchedHierarchy == null) {
			// apiFailed('Failed to FetchHierarchy');
			Logout();
			return;
		}

		fetchedHierarchy.notificationManager = notificationsCache;
		fetchedHierarchy.notificationManager.allCranes = fetchedHierarchy.cranes;

		// setState({ loading: false });

		let sc = await fetchedHierarchy.FetchSubscriptionCredit();
		if (sc == null) {
			apiFailed('Failed to FetchSubscriptionCredit');
			return;
		}

		let deviceStatuses = await fetchedHierarchy.FetchDeviceStatuses();
		if (deviceStatuses == null) {
			apiFailed('Failed to FetchDeviceStatuses');
			return;
		}

		setHierarchy(fetchedHierarchy);
		setHierarchyView(new HierarchyView(fetchedHierarchy));
		setIsLoading(false);

		await fetchedHierarchy.notificationManager.RealtimePoll();

		setTimeout(loadData, 2000);
	};

	const apiFailed = reason => {
		console.log(reason);
		setIsLoading(true);

		setTimeout(loadData, 8000);
	};

	const newItemHandler = () => {
		const show = showAddItem;
		setShowAddItem(!show);
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<LoadingModalProvider>
			<div className='App'>
				<Router>
					<ApiResponseModal />
					{/* <HierarchyProvider value={hierarchy}> */}
					<Toolbar view={new ToolbarView(hierarchy)} />
					<Sidebar hierarchy={hierarchy} openHandler={newItemHandler} currentUser={MyProfile.Current} />
					<div className='main'>
						<MfaModal />
						<NewItem show={showAddItem} hierarchy={hierarchy} closeHandler={newItemHandler} />
						<Switch>
							<Route path='/' exact render={props => <Organization hierarchyView={hierarchyView} {...props} />} />
							<Route
								path='/company/:id'
								render={props => {
									let companyView = hierarchyView.companyViews.find(s => s.company.id === props.match.params.id);
									if (!companyView) {
										return 'Company not found';
									}

									return <Company hierarchyView={hierarchyView} view={companyView} {...props} />;
								}}
							/>
							<Route
								path='/site/:id'
								render={props => {
									let siteView = hierarchyView.siteViews.find(s => s.site.id === props.match.params.id);
									if (!siteView) {
										return 'Site not found';
									}

									return <Site hierarchyView={hierarchyView} view={siteView} {...props}></Site>;
								}}
							/>
							;
							<Route
								path='/crane/:id'
								render={props => {
									let craneView = hierarchyView.craneViews.find(c => c.crane.id === props.match.params.id);
									if (!craneView) {
										return 'Crane not found';
									}

									return <Crane hierarchyView={hierarchyView} view={craneView} {...props}></Crane>;
								}}
							/>
						</Switch>
						<Route path='/notifications' render={() => <NotificationsPage hierarchy={hierarchy} />} />
						<Route path='/userProfile' render={() => <UserProfile hierarchy={hierarchy} />} />
						{currentUser?.permissions?.settingsCRUD?.read && <Route path='/settings' render={() => <Settings hierarchy={hierarchy} />} />}
					</div>
					{/* </HierarchyProvider> */}
				</Router>
			</div>
		</LoadingModalProvider>
	);
};

export default App;
