import { Site } from '../DataTypes/Site';
import CraneView from './CraneView';

class SiteView {
	/**@type {Site}*/
	site;

	/**@type {CraneView[]} */
	craneViews;

	/**@param {Site} site*/
	constructor(site) {
		this.site = site;
		this.craneViews = this.site.cranes.map(c => new CraneView(c));
	}
}

export default SiteView;
