import axios from "axios";

export class Blackbox {
	/**@type {string} */
	friendlyName = null;

	/**@type {"Full" | "Feeder" | "Lite"} */
	type = null;

	/**@type {string} */
	id = null;

	/**@type {string} */
	createdTime = null;

	constructor(data) {
		this.friendlyName = data.friendlyName;
		this.type = data.type;
		this.id = data.id;
		this.createdTime = data.createdTime;
	}

	RemoveBlackbox = () => {
		return axios.delete(`/Blackbox/${this.id}`);
	};
}
