import React, { useState, useEffect } from 'react';
import Classes from './Organization.module.css';
import cn from 'classnames';
import OrganizationGeneral from './OrganizationGeneral/OrganizationGeneral';
import OrganizationCranesStatus from './OrganizationCranesStatus/OrganizationCranesStatus';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import Spinner from '../UI/Spinner/Spinner';
import HierarchyView from '../../ViewModels/HierarchyView';
import SteakyHeader from '../SteakyHeader/SteakyHeader';

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 * @param {*} props.unReadNotifications
 */
const Organization = props => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<Router>
			<div className={Classes.wrapperOrg}>
				<Container fluid>
					<h3 className="container-title"><SteakyHeader>תצוגה ראשית</SteakyHeader></h3>
					<Row>
						<Col md={4}>
							<div className={Classes.headerCrane}>
								<ul className="container-tabs">
									<li>
										<NavLink to='/' exact className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
											כללי
										</NavLink>
									</li>
									<li>
										<NavLink to='/org-cranes-status' className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
											סטטוס מנופים
										</NavLink>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<Route path='/' exact render={() => <OrganizationGeneral hierarchy={props.hierarchyView.hierarchy} unReadNotifications={props.unReadNotifications}></OrganizationGeneral>} />
			<Route path='/org-cranes-Status' render={() => <OrganizationCranesStatus hierarchyView={props.hierarchyView} />} />
		</Router>
	);
};

export default Organization;
