import React, { useContext } from 'react';
import Classes from './RemoveUserModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { User } from '../../../DataTypes/User';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const RemoveUserModal = props => {
	/**@type {User} */
	const user = props.user;

	const { ShowModal } = useContext(LoadingModalContext);

	const handleRemove = () => {
		props.closeHandler();
		ShowModal(user.DeleteUser(), CurrentDict.UserRemove);
	};

	return (
		<Modal className={Classes.modal} size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.closeHandler} centered>
			<Modal.Header className={Classes.close} style={{ border: 'none' }} closeButton />

			<ModalBody>
				<p className={Classes.title}>{'האם אתה בטוח שברצונך להסיר משתמש זה ולמחוק אותו מהמערכת'}</p>
				<div className={Classes.buttons}>
					<Button variant='outline-danger' className={Classes.button} onClick={handleRemove}>
						{'כן, בטוח'}
					</Button>
					<Button variant='outline-primary' className={Classes.button} onClick={props.closeHandler}>
						{'לא, בטל'}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default RemoveUserModal;
