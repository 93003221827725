import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Route, NavLink } from 'react-router-dom';
import cn from 'classnames';

import Classes from './Site.module.css';
import SiteGeneral from '../Site/SiteGeneral/SiteGeneral';
import SiteCranesStatus from '../Site/SiteCranesStatus/SiteCranesStatus';
import SiteDetails from '../Site/SiteDetails/SiteDetails';
import Spinner from '../UI/Spinner/Spinner';
import DistributionListTable from '../Tables/DistributionListTable/DistributionListTable';
import SiteView from '../../ViewModels/SiteView';
import HierarchyView from '../../ViewModels/HierarchyView';
import SteakyHeader from '../SteakyHeader/SteakyHeader';

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 * @param {SiteView} props.view
 * @param {any} props.match
 */
const Site = props => {
	const [site, setSite] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [value, setValue] = useState('לא ידוע');

	useEffect(() => {
		loadData();
	});

	const loadData = () => {
		setSite(props.view.site);
		setIsLoading(false);
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<div className={Classes.wrapperCrane}>
			<Container fluid>
				<h3 className="container-title"><SteakyHeader>{props.view.site.name}</SteakyHeader></h3>
				<Row>
					<Col md={4}>
						<div className={Classes.headerCrane}>
							<ul className="container-tabs">
								<li>
									<NavLink exact to={'/site/' + props.match.params.id} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										כללי
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/site-cranes-Status'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										סטטוס מנופים
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/site-details'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										פרטי האתר
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/site-distribution-list'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										רשימת תפוצה
									</NavLink>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
			<Route exact path={'/site/' + props.match.params.id} render={() => <SiteGeneral site={props.view.site} hierarchy={props.hierarchyView.hierarchy} />} />
			<Route path={props.match.url + '/site-cranes-Status'} render={() => <SiteCranesStatus view={props.view} wrapped={true} />} />
			<Route path={props.match.url + '/site-details'} render={() => <SiteDetails site={props.view.site} />} />
			<Route path={props.match.url + '/site-distribution-list'} render={() => <DistributionListTable item={props.view.site} />} />
		</div>
	);
};

export default Site;
