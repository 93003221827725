import React, { useState, useEffect, useContext } from 'react';

import Classes from './EditUserModal.module.css';
import { Button, Modal } from 'react-bootstrap';
import { EditUser } from '../../../DataTypes/Edit/EditUser';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const EditUserModal = props => {
	/**@type {[EditUser,any]} */
	const [currentUser, setCurrentUser] = useState(new EditUser());
	const { ShowModal } = useContext(LoadingModalContext);

	useEffect(() => {
		setCurrentUser(new EditUser(props.user));
	}, []);

	const changeHandler = e => {
		currentUser.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.closeHandler();
		ShowModal(currentUser.UpdateUserRole(), CurrentDict.UserUpdate);
	};

	const companyIdToName = id => {
		return props.hierarchy.companies.find(x => x.id == id)?.name;
	};

	const companiesDropdownList = () => {
		let items = [];
		props.hierarchy.companies.map(c =>
			items.push(
				<option key={c.id} value={c.id}>
					{c.name}
				</option>
			)
		);
		return items;
	};

	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.closeHandler} centered dir='rtl'>
			<Modal.Header style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>{'עריכת משתמש'}</div>
				<form className={Classes.modalForm} onSubmit={handleSubmit}>
					<div>
						<div className={Classes.key}>{'שם מלא'}</div>
						<input name='fullName' className={Classes.input} type='text' defaultValue={currentUser.fullName ?? ''} placeholder={'שם מלא'} onChange={e => changeHandler(e)} required></input>
					</div>
					<div>
						<div className={Classes.key}>{'נייד'}</div>
						<input name='phone' className={Classes.input} type='text' defaultValue={currentUser.phone.number ?? ''} placeholder={'נייד'} onChange={e => changeHandler(e)} required></input>
					</div>
					<div>
						<div className={Classes.key}>{'מייל'}</div>
						<input name='mail' className={Classes.input} type='text' defaultValue={currentUser.mail ?? ''} placeholder={'אימייל'} onChange={e => changeHandler(e)} required></input>
					</div>
					<div>
						<div className={Classes.key}>{'חברה'}</div>
						<select name='company' className={Classes.input} defaultValue={currentUser.company ?? ''} onChange={e => changeHandler(e)} required>
							<option value={''} disabled>
								{'בחר'}
							</option>
							{companiesDropdownList()}
						</select>
					</div>
					<div>
						<div className={Classes.key}>{'תפקיד'}</div>
						<select name='role' className={Classes.input} defaultValue={currentUser.role ?? ''} onChange={e => changeHandler(e)} required>
							<option value={''} disabled>
								{'בחר'}
							</option>
							<option value='SuperAdmin'>SuperAdmin</option>
							<option value='ServiceManager'>ServiceManager</option>
							<option value='ServiceManager2nd'>ServiceManager2nd</option>
							<option value='SafetyManager'>SafetyManager</option>
							<option value='OperationManager'>OperationManager</option>
							<option value='CranesManager'>CranesManager</option>
							<option value='SiteManager'>SiteManager</option>
						</select>
					</div>

					<Button variant='outline-primary' type='submit' className={Classes.button}>
						{'שמירה'}
					</Button>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default EditUserModal;
