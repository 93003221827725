import React, { useState, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';

import Classes from './CompanyActiveSitesTable.module.scss';
import cn from 'classnames';

const CompanyActiveSitesTable = props => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	if (isLoading || !props.company) {
		return <Spinner />;
	}
	return (
		<div className={Classes.card} dir='rtl'>
			<span className={Classes.cardTitle}>אתרים פעילים</span>
			<table className={cn('table', 'table-borderless', Classes.mobileTable)}>
				<thead className={Classes.thead}>
					<tr>
						<th scope='col'>שם האתר</th>
						<th scope='col'>כתובת</th>
						<th scope='col'>כמות מנופים</th>
						<th scope='col'>איש קשר</th>
						<th scope='col'>טלפון</th>
					</tr>
				</thead>
				<tbody className={Classes.tbody}>
					{props.company.sites.map((site, index) => {
						return (
							<tr key={index} className={Classes.tcard}>
								<td className={Classes.tname}>{site.name}</td>
								<td><span className={Classes.theaders}>כתובת</span>{site.address}</td>
								<td><span className={Classes.theaders}>כמות מנופים</span>{site.cranes.length}</td>
								<td><span className={Classes.theaders}>איש קשר</span>{site.manager?.name}</td>
								<td><span className={Classes.theaders}>טלפון</span>{site.manager?.phone}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default CompanyActiveSitesTable;
