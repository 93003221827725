import React, { useState, useContext } from 'react';
import Classes from './LicenseUnassignmentModal.module.scss';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const LicenseUnassignmentModal = props => {
	const [isProcessingRequest, setIsProcessingRequest] = useState(true);
	const { ShowModal } = useContext(LoadingModalContext);

	const unassignHandler = () => {
		if (props.license.blackboxBinding?.craneBinding !== null) {
			alert('רשיון זה משויך לקופסה המשויכת למנוף, בטל שיוך הקופסה למנוף ונסה שנית');
		} else {
			props.unassignModalHandler();
			ShowModal(props.license.UnbindBlackbox(props.license.id, props.license.blackboxBinding.blackbox.id), CurrentDict.LicenseUnassign);
		}
	};

	return (
		<Modal className={Classes.modal} size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.unassignModalHandler} centered>
			<Modal.Header className={Classes.close} style={{ border: 'none' }} closeButton />

			<ModalBody className={Classes.modalBody}>
				<p className={Classes.title}>
					{'ביטול שיוך'}
					<br />
					<br />
					{`האם אתה בטוח כי ברצונך לבטל שיוך רשיון זה`}
				</p>
				<br />
				<div className={Classes.buttons}>
					<Button variant='outline-danger' className={Classes.button} onClick={unassignHandler}>
						{'כן, בטוח'}
					</Button>
					<Button variant='outline-primary' className={Classes.button} onClick={props.unassignModalHandler}>
						{'לא, בטל'}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default LicenseUnassignmentModal;
