import React from 'react';
import Classes from './CompanyCranesStatus.module.scss';

import SiteCranesStatus from '../../Site/SiteCranesStatus/SiteCranesStatus';
import CompanyView from '../../../ViewModels/CompanyView';

/**
 *
 * @param {Object} props
 * @param {CompanyView} props.view
 */
const CompanyCranesStatus = props => {
	const sites = (
		<div className={Classes.wrapperCard}>
			<div className={Classes.companyName}>{props.view.company.name}</div>
			<div className={Classes.cranesCards}>
				{props.view.siteViews.map(siteView => (
					<SiteCranesStatus key={siteView.site.id} view={siteView} />
				))}
			</div>
		</div>
	);

	return props.view.company.cranes.length != 0 ? sites : null;
};

export default CompanyCranesStatus;
