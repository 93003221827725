import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import ContactUpdateModal from '../../Modals/ContactUpdateModal/ContactUpdateModal';
import AddContactModal from '../../Modals/AddContactModal/AddContactModal';
import DeleteContactModal from '../../Modals/DeleteContactModal/DeleteContactModal';
import { DistributionList } from '../../../DataTypes/DistributionList';
import { Crane } from '../../../DataTypes/Crane';
import { Site } from '../../../DataTypes/Site';
import { Company } from '../../../DataTypes/Company';
import { DistributionListItem } from '../../../DataTypes/DistributionListItem';
import { EditDistributionListItem } from '../../../DataTypes/Edit/EditDistributionListItem';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

import cn from 'classnames';
import Classes from './DistributionListTable.module.scss';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-rounded.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron-rounded.svg';

/**
 *
 * @param {Object} props
 * @param {Crane | Site | Company} props.item
 */
const DistributionListTable = props => {
	const [loading, setLoading] = useState(true);
	const [showAddContactModal, setShowAddContactModal] = useState(false);
	const [showUpdateContactModal, setShowUpdateContactModal] = useState(false);
	const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
	/**@type {[DistributionListItem, any]} */
	const [contact, setContact] = useState(null);
	/**@type {[DistributionList[], any]} */
	const [distributionLists, setDistributionLists] = useState(null);
	/**@type {[DistributionList, any]} */
	const [currentDistList, setCurrentDistList] = useState(null);
	/**@type {[EditDistributionListItem, any]} */
	const [editContact, setEditContact] = useState(null);
	const { ShowModal } = useContext(LoadingModalContext);

	const [openDistIds, setOpenDistIds] = useState([]);

	useEffect(() => {
		let ids = [];

		if (props.item instanceof Crane) {
			ids = [props.item.id, props.item.site.id, props.item.company.id];
		} else if (props.item instanceof Site) {
			ids = [props.item.id, props.item.company.id];
		} else if (props.item instanceof Company) {
			ids = [props.item.id];
		} else {
			throw 'is bad';
		}

		DistributionList.GetDistributionListMultiple(ids).then(x => {
			setDistributionLists(x.reverse());
			setCurrentDistList(distributionLists?.find(x => x.id === props.item.id));
			setLoading(false);
		});
	});

	const addContactModalHandler = () => {
		setEditContact(new EditDistributionListItem(null));
		let show = showAddContactModal;
		setShowAddContactModal(!show);
	};
	const updateContactModalHandler = contact => {
		setEditContact(new EditDistributionListItem(contact));
		setContact(new DistributionListItem(contact));
		let show = showUpdateContactModal;
		setShowUpdateContactModal(!show);
	};

	const deleteContactModalHandler = contact => {
		setEditContact(new EditDistributionListItem(contact));
		setContact(new DistributionListItem(contact));
		let show = showDeleteContactModal;
		setShowDeleteContactModal(!show);
	};

	const changeHandler = e => {
		editContact.Edit(e.target.name, e.target.value);
	};

	const addContactHandler = e => {
		e.preventDefault();
		let newItem = new DistributionListItem(editContact);
		let show = showAddContactModal;
		setShowAddContactModal(!show);
		ShowModal(currentDistList.AddContact(newItem), CurrentDict.DistributionListItemCreate);
	};

	const updateHandler = e => {
		e.preventDefault();
		let newItem = new DistributionListItem(editContact);
		let show = showUpdateContactModal;
		setShowUpdateContactModal(!show);
		ShowModal(currentDistList.UpdateContact(newItem), CurrentDict.DistributionListItemUpdate);
	};

	const deleteHandler = e => {
		e.preventDefault();
		let show = showDeleteContactModal;
		setShowDeleteContactModal(!show);
		ShowModal(currentDistList.DeleteContact(editContact.id), CurrentDict.DistributionListItemRemove);
	};

	const toggleOnetDistId = id => {
		if (window.innerWidth >= 1200) return;

		if (openDistIds.includes(id)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== id));
		} else {
			setOpenDistIds([...openDistIds, id]);
		}
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className={Classes.card} dir='rtl'>
			<div className={Classes.Header}>
				<div className={Classes.cardTitle}>התראות - רשימת תפוצה</div>
				<div className={Classes.addContactButton} role='button' onClick={addContactModalHandler}>
					<div className={'material-icons'}>add_circle_outline</div>
					<span className={Classes.mobileAddBtn}>
						<PlusIcon />
						<span>הוספה</span>
					</span>
				</div>
			</div>
			<table className={cn('table', 'table-borderless', Classes.mobileTable)}>
				<thead>
					<tr>
						<th scope='col'>שם מלא</th>
						<th scope='col'>חברה</th>
						<th scope='col'>תפקיד</th>
						<th scope='col'>טלפון</th>
						{/* <th scope='col'></th>
							<th scope='col'></th> */}
					</tr>
				</thead>
				<tbody>
					{distributionLists.map(dl =>
						dl.items.map(item => {
							return (
								<tr key={item.id} className={cn(Classes.distributor, openDistIds.includes(item.id) && Classes.open)}>
									<td className={cn(Classes.rowHead)} onClick={() => toggleOnetDistId(item.id)}>
										<ChevronIcon />
										<span>{item.fullName}</span>
									</td>
									<td>
										<span className={Classes.distHeader}>חברה</span>
										<span>{item.company}</span>
									</td>
									<td>
										<span className={Classes.distHeader}>תפקיד</span>
										<span>{item.role}</span>
									</td>
									<td>
										<span className={Classes.distHeader}>טלפון</span>
										<span>{item.phone.number}</span>
									</td>
									<td className={cn(Classes.button, Classes.delete)}>
										<div role='button'>
											<svg
												className={Classes.craneIcon}
												role='button'
												onClick={() => deleteContactModalHandler(item)}
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'>
												<path d='M3 6H5H21' stroke='#101010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
												<path
													d='M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z'
													stroke='#101010'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
										</div>
									</td>
									<td className={cn(Classes.button, Classes.edit)}>
										<div role='button'>
											<svg
												className={Classes.craneIcon}
												role='button'
												onClick={() => updateContactModalHandler(item)}
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13'
													stroke='#101010'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M18.5 2.5C18.8978 2.10217 19.4374 1.87868 20 1.87868C20.5626 1.87868 21.1022 2.10217 21.5 2.5C21.8978 2.89782 22.1213 3.43739 22.1213 4C22.1213 4.56261 21.8978 5.10217 21.5 5.5L12 15L8 16L9 12L18.5 2.5Z'
													stroke='#101010'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
										</div>
									</td>
								</tr>
							);
						})
					)}
				</tbody>
			</table>
			{showAddContactModal ? <AddContactModal show={showAddContactModal} changeHandler={changeHandler} closeHandler={addContactModalHandler} submitHandler={addContactHandler} /> : null}
			{showUpdateContactModal ? (
				<ContactUpdateModal
					show={showUpdateContactModal}
					contact={editContact}
					changeHandler={changeHandler}
					closeHandler={() => setShowUpdateContactModal(false)}
					submitHandler={updateHandler}
				/>
			) : null}
			{showDeleteContactModal ? <DeleteContactModal show={showDeleteContactModal} closeHandler={() => setShowDeleteContactModal(false)} submitHandler={deleteHandler} /> : null}
		</div>
	);
};

export default DistributionListTable;
