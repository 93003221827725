import { Company } from './Company';
import { Crane } from './Crane';
import { Site } from './Site';
import { SubscriptionCredit } from './SubscriptionCredit';
import axios from 'axios';
import { Notification } from './Notification';
import { ReturnCranesDeviceIds } from '../services/services';
import { Event } from './Event';
import { EditCrane } from './Edit/EditCrane';
import { EditSite } from './Edit/EditSite';
import { EditCompany } from './Edit/EditCompany';
import { NotificationManager } from './NotificationManager';

export class Hierarchy {
	/**@type {string} */
	id = null;

	/**@type {Company[]} */
	companies = [];

	/**@type {Site[]} */
	sites = [];

	/**@type {Crane[]} */
	cranes = [];

	/**@type {SubscriptionCredit} */
	subscriptionCredit = null;

	/**@type {Event} */
	NotificationsPolled = new Event();

	/**@type {NotificationManager} */
	notificationManager = null;

	constructor(data) {
		this.id = data.id;

		this.companies = data.companies.map(c => {
			let company = new Company(c);

			//Fill sites
			this.sites = this.sites.concat(company.sites);

			//Fill cranes
			company.sites.forEach(s => s.cranes.forEach(c => this.cranes.push(c)));

			return company;
		});
	}

	/**@returns {Promise<Hierarchy | null>} */
	static FetchHierarchy() {
		return axios.get('/Hierarchy').then(
			response => {
				if (typeof response.data !== 'object') {
					//data is not an object, that means its an error!
					return null;
				}

				return new Hierarchy(response.data);
			},
			reason => {
				return null;
			}
		);
	}

	/**@returns {Promise<SubscriptionCredit | null>} */
	FetchSubscriptionCredit() {
		return axios.get('/License').then(
			response => {
				this.subscriptionCredit = new SubscriptionCredit(response.data);

				this.subscriptionCredit.licenses.forEach(lic => {
					let found = this.cranes.find(cr => cr.id == lic.blackboxBinding?.craneBinding?.craneId);
					if (found == null) return;
					found.deviceId = lic.deviceId;
				});

				return this.subscriptionCredit;
			},
			reason => {
				return null;
			}
		);
	}

	/**@returns {Promise<any | null>} */
	FetchDeviceStatuses() {
		return axios.post('/Device/GetMultiple', this.subscriptionCredit.deviceIds).then(
			response => {
				this._deviceStatesHandle(response.data);
				return true;
			},
			reason => {
				return null;
			}
		);
	}

	_deviceStatesHandle(deviceStatuses) {
		deviceStatuses?.forEach(ds => {
			let found = this.cranes.find(cr => cr.deviceId === ds.deviceId);
			if (!found) return;
			found.UpdateDeviceStatus(ds);
		});
	}

	/**
	 * @param {EditCompany} details
	 */
	CreateCompany(details) {
		return axios.post('/Company', details);
	}

	/**Creating new site
	 * @param {EditSite} details
	 */
	CreateSite(details) {
		return axios.post(`/Company/${details.company}/Site`, details);
	}

	/**Creating new crane
	 * @param {EditCrane} details
	 */
	CreateCrane(details) {
		return axios.post(`/Company/${details.company}/Site/${details.site}/Crane`, details);
	}

	GetLastNotifications(amount, skip) {
		return axios.post(`/Notification/GetLast`, ReturnCranesDeviceIds(this.cranes), { params: { amount, skip } });
	}

	// GetActivitySummary(startTime, endTime) {
	// 	return axios.post(`/Summary/GetMultiple`, ReturnCranesDeviceIds(this.cranes), { params: { startTime, endTime } });
	// }

	GetActivitySummary(year) {
		return axios.post(`/Summary/GetMultiple`, ReturnCranesDeviceIds(this.cranes), {
			params: {
				startTime: `${year}-01-01T00:00:00Z`,
				endTime: `${year + 1}-01-01T00:00:00Z`,
			},
		});
	}
}
