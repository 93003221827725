import { Company } from './Company';
import { Crane } from './Crane';
import { Manager } from './Manager';
import axios from 'axios';
import { Notification } from './Notification';
import { ReturnCranesDeviceIds } from '../services/services';

export class Site {
	/**@type {string} */
	id = null;

	/**@type {string} */
	name = null;

	/**@type {string} */
	address = null;

	/**@type {Crane[]} */
	cranes = [];

	/**@type {Company} */
	company = null;

	/**@type {Manager} */
	manager = null;

	/**@param {Company} company */
	constructor(data, company) {
		this.id = data.id;
		this.name = data.name;
		this.address = data.address;
		this.manager = data.manager;
		this.company = company;
		this.cranes = data.cranes.map(c => new Crane(c, this.company, this));
	}

	UpdateDetails(details) {
		return axios.put(
			`/Company/${this.company.id}/Site/${this.id}`,
			details
		);
	}

	/**@returns {Promise<Notification[]>} */
	GetNotifications() {
		return Notification.FetchLast(this.cranes);
	}

	// GetActivitySummary(startTime, endTime) {
	// 	return axios.post(
	// 		`/Summary/GetMultiple`,
	// 		ReturnCranesDeviceIds(this.cranes),
	// 		{ params: { startTime, endTime } }
	// 	);
	// }

	GetActivitySummary(year) {
		return axios.post(
			`/Summary/GetMultiple`,
			ReturnCranesDeviceIds(this.cranes),
			{
				params: {
					startTime: `${year}-01-01T00:00:00Z`,
					endTime: `${year + 1}-01-01T00:00:00Z`,
				},
			}
		);
	}
}
