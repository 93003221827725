import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

const LineChart = props => {
	const data = {
		labels: ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יוני', 'יולי', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'],
		datasets: [
			{
				label: ['שעות פעילות'],
				data: props.data?.map(month => month?.toFixed(2)) ?? null,
				// data: [100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111],
				backgroundColor: 'rgba(54, 162, 235, 0.6)',
			},
		],
	};

	return (
		<div>
			<Bar
				data={data}
				options={{
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								scaleLabel: {
									display: true,
									labelString: 'שעות  עבודה  חודשיות',
									fontFamily: 'Rubik',
									fontSize: 15,
									fontColor: 'black',
								},
							},
						],
					},
					plugins: {
						datalabels: {
							display: false,
						},
					},
				}}
			/>
		</div>
	);
};
export default LineChart;
