import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MyProfile } from '../../../DataTypes/MyProfile';
import Spinner from '../../UI/Spinner/Spinner';
import { EditSite } from '../../../DataTypes/Edit/EditSite';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

import Classes from './SiteDetailsTable.module.scss';
import cn from 'classnames';

const siteDetailsTable = props => {
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	/**@type {[MyProfile, any]} */
	const [currentUser, setCurrentUser] = useState(MyProfile.Current);
	/**@type {[EditSite, any} */
	const [editSite, setEditSite] = useState(new EditSite(props.site));
	const { ShowModal } = useContext(LoadingModalContext);

	useEffect(() => {
		setLoading(false);
	});

	const modalHandler = () => {
		setShowModal(true);
	};

	const closeHandler = () => {
		setShowModal(false);
	};

	const handleChange = e => {
		editSite.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		closeHandler();
		ShowModal(props.site.UpdateDetails(editSite), CurrentDict.SiteDetailsUpdate);
	};

	const modal = (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={showModal} onHide={closeHandler} centered dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>עדכון פרטי האתר</div>
				<div className={Classes.modalForm}>
					<form className={Classes.detailsForm} onSubmit={handleSubmit}>
						<br />
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>חברה</label>
							<input
								name='company'
								className={Classes.input}
								type='text'
								defaultValue={props.site.company.name ? props.site.company.name : ''}
								onChange={handleChange}
								disabled
								required></input>
						</div>
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>שם האתר</label>
							<input name='name' className={Classes.input} type='text' defaultValue={editSite?.name ?? ''} onChange={handleChange} required></input>
						</div>
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>כתובת</label>
							<input name='address' className={Classes.input} type='text' defaultValue={editSite?.address ?? ''} onChange={handleChange} required></input>
						</div>
						<div className={cn(Classes.row, Classes.topMargin)}>
							<label className={Classes.detailsKey}>מנהל אחראי</label>
							<input name='managerName' className={Classes.input} type='text' defaultValue={editSite?.manager?.name ?? ''} onChange={handleChange} required></input>
						</div>
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>טלפון</label>
							<input
								name='managerPhone'
								className={Classes.input}
								type='tel'
								pattern='[0][5][0-9]{8}'
								title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
								defaultValue={editSite?.manager?.phone ?? ''}
								onChange={handleChange}
								required></input>
						</div>
						<br></br>
						<div className={Classes.modalFooter}>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
								עדכון
							</Button>
							<button className={Classes.closeModal} style={{ display: 'none' }} onClick={() => closeHandler()}>
								ביטול
							</button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
	if (loading) {
		return <Spinner />;
	}
	return (
		<div className={Classes.card} dir='rtl'>
			<span className={Classes.cardTitle}>פרטי האתר</span>
			<table className={cn('table', 'table-borderless', Classes.mobileTable)}>
				<thead>
					<tr>
						<th scope='col'>חברה</th>
						<th scope='col'>שם האתר</th>
						<th scope='col'>כתובת</th>
						<th scope='col'>איש קשר</th>
						<th scope='col'>טלפון</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{props.site.company.name}</td>
						<td>{props.site.name}</td>
						<td>{props.site.address}</td>
						<td>{props.site.manager?.name}</td>
						<td>{props.site.manager?.phone}</td>
						<td hidden={!currentUser?.permissions.siteCRUD.update} className={Classes.tcontrols}>
							<div role='button'>
								<img onClick={modalHandler} src={require('../../../assets/icons/edit.svg')} className={Classes.icon}></img>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			{showModal ? modal : null}
		</div>
	);
};

export default siteDetailsTable;
