import { Manager } from './Manager';
import { Site } from './Site';
import axios from 'axios';
import { Crane } from './Crane';
import { Notification } from './Notification';
import { ReturnCranesDeviceIds } from '../services/services';

export class Company {
	/**@type {string} */
	id = null;

	/**@type {string} */
	organization = null;

	/**@type {string} */
	name = null;

	/**@type {string} */
	officeAddress = null;

	/**@type {Manager} */
	manager = null;

	/**@type {Site[]} */
	sites = [];

	/**@type {Crane[]}  */
	cranes = [];

	constructor(data) {
		this.id = data.id;
		this.organization = data.organization;
		this.name = data.name;
		this.sn = data.sn;
		this.officeAddress = data.officeAddress;
		this.manager = data.manager;
		this.sites = data.sites.map(s => {
			const site = new Site(s, this);
			this.cranes = this.cranes.concat(site.cranes);
			return site;
		});
	}

	UpdateDetails = details => {
		return axios.put(`/Company/${this.id}`, details);
	};

	/**@returns {Promise<Notification[]>} */
	GetNotifications() {
		return Notification.FetchLast(this.cranes);
	}

	GetActivitySummary(year) {
		return axios.post(`/Summary/GetMultiple`, ReturnCranesDeviceIds(this.cranes), {
			params: {
				startTime: `${year}-01-01T00:00:00Z`,
				endTime: `${year + 1}-01-01T00:00:00Z`,
			},
		});
	}
}
