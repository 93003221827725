import Classes from "./NotificationListWithPolling.module.css";
import NotificationList from "../NotificationList/NotificationList";
import { NotificationManager } from "../../../DataTypes/NotificationManager";
import { Crane } from "../../../DataTypes/Crane";

/**
 * Does what NotificationListWithPolling should do!
 */
class NotificationListWithPolling extends NotificationList {
	props = {
		/**@inheritdoc */
		notifications: null,
		notificationToItemViewFunc: null,

		/**@type {Crane[]} */
		cranes: null,
		pollingInterval: 2000,
	};

	_pollingHandle = -1;

	constructor(props) {
		super(props);
		this.props = props;

		this.startDataPolling(this.props.cranes);
	}

	/**@override */
	shouldComponentUpdate(nextProps) {
		let changed = this.props.cranes.length != nextProps.cranes.length;

		if (!changed) {
			for (let i = 0; i < this.props.cranes.length; i++) {
				if (this.props.cranes[i].id != nextProps.cranes[i].id) {
					changed = true;
					break;
				}
			}
		}

		if (changed) {
			this.notifications = null;
			clearTimeout(this._pollingHandle);
			this.startDataPolling(nextProps.cranes);
		}
		return changed;
	}

	/**@override */
	componentWillUnmount() {
		if (super.componentWillUnmount) super.componentWillUnmount();
		clearTimeout(this._pollingHandle);
		this._pollingHandle = null;
	}

	/**@param {Crane[]} cranes */
	startDataPolling = async (cranes) => {
		if (this._pollingHandle == null) {
			return;
		}

		this.notifications = await NotificationManager.FetchLast(cranes, 10, 0);

		this.forceUpdate();

		this._pollingHandle = setTimeout(() => this.startDataPolling(cranes), 2000);
	};
}

export default NotificationListWithPolling;
