import React, { useState, useContext, useEffect } from 'react';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { Button, Modal } from 'react-bootstrap';
import { EditCrane } from '../../../DataTypes/Edit/EditCrane';
import { MyProfile } from '../../../DataTypes/MyProfile';
import { CurrentDict } from '../../../services/LocaleDictionary';
import Classes from '../../Tables/CranePositionTable/CranePositionTable.module.scss';

const CranePositionModal = props => {
	const { ShowModal } = useContext(LoadingModalContext);
	const [loading, setLoading] = useState(true);
	/**@type {[MyProfile, any]} */
	const [currentUser, setCurrentUser] = useState(MyProfile.Current);
	/**@type {[EditCrane, any]} */
	const [editCrane, setEditCrane] = useState(new EditCrane(props.crane));

	const handleChange = e => {
		editCrane.Edit(e.target.name, e.target.value);
	};

	const companyObj = companyId => {
		const company = props.hierarchy.companies.find(c => c.id === companyId);
		if (company) {
			return company.id;
		} else {
			return null;
		}
	};

	const siteObj = (companyId, siteId) => {
		const company = props.hierarchy.companies.find(c => c.id === companyId);
		const site = company?.sites?.find(s => s.id === siteId);
		if (site) {
			return site.id;
		} else {
			return null;
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.showModalHandler();
		ShowModal(props.crane.Move(editCrane), CurrentDict.CraneAssignUpdate);
	};

	const companiesDropdown = () => {
		let items = [];
		props.hierarchy.companies.map(c =>
			items.push(
				<option key={c.id} value={c.id}>
					{c.name}
				</option>
			)
		);
		return items;
	};

	const sitesDropdown = companyId => {
		let items = [];
		props.hierarchy.companies.filter(c =>
			c.id === companyId
				? c.sites.map(s =>
						items.push(
							<option key={s.id} value={s.id}>
								{s.name}
							</option>
						)
				  )
				: null
		);
		return items;
	};

	const craneNumbersDropdown = (site, craneNum) => {
		if (site) {
			const numbers = [];
			site.cranes?.forEach(crane => {
				numbers.push(crane.number);
			});
			const items = [];
			for (let i = 1; i < 21; i++) {
				if (numbers.includes(`${i}`) || i === craneNum) {
					items.push(
						<option key={i} value={i} disabled>
							{i}
						</option>
					);
				} else {
					items.push(
						<option key={i} value={i}>
							{i}
						</option>
					);
				}
			}
			return items;
		} else {
			return editCrane?.number;
		}
	};

	return (
		<Modal size='sm' className={Classes.CranePositionModal} aria-labelledby='contained-modal-title-vcenter' show={props.showModal} onHide={props.showModalHandler} centered dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.modalTitle}>עדכון שיוך </div>
				<form onSubmit={handleSubmit} className={Classes.form}>
					<div>
						<label className={Classes.detailsKey}>חברה</label>
						<select name='company' onChange={handleChange} required>
							<option value='' selected disabled>
								{'בחר'}
							</option>
							{companiesDropdown()}
						</select>
					</div>
					<div>
						<label className={Classes.detailsKey}>{'אתר פעילות'}</label>
						<select name='site' onChange={handleChange} required>
							<option value='' selected disabled>
								{'בחר'}
							</option>
							{sitesDropdown(editCrane?.company)}
						</select>
					</div>
					{/* <div>
							<label className={Classes.detailsKey}>{"מס' מנוף"}</label>
							<select name='number' onChange={handleChange} required>
								<option value='' selected disabled>
									{"בחר"}
								</option>
								{craneNumbersDropdown(editCrane?.site, editCrane?.number)}
							</select>
						</div> */}
					<div className={Classes.modalFooter}>
						<p onClick={props.showModalHandler}>ביטול</p>
						<Button variant='outline-primary' type='submit' className={Classes.button}>
							{'שמירה'}
						</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default CranePositionModal;
