import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Classes from './CompanyDetails.module.scss';
import CompanyActiveSitesTable from '../../Tables/CompanyActiveSitesTable/CompanyActiveSitesTable';
import CompanyDetailsTable from '../../Tables/CompanyDetailsTable/CompanyDetailsTable';
import Spinner from '../../UI/Spinner/Spinner';

const CompanyDetails = props => {
	const [value, setValue] = useState('לא ידוע');
	const [loading, setLoading] = useState(true);

	if (!props.company) {
		return <Spinner />;
	}
	return (
		<div className={Classes.wrapper}>
			<div className={Classes.table}>
				<Row>
					<Col lg={12} xl={10}>
						<CompanyDetailsTable company={props.company}></CompanyDetailsTable>
					</Col>
				</Row>
			</div>
			<div className={Classes.table}>
				<Row>
					<Col lg={12} xl={10}>
						<CompanyActiveSitesTable company={props.company}></CompanyActiveSitesTable>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default CompanyDetails;
