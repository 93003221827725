import React, { useState, useContext } from 'react';
import Classes from './NewBlackboxModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const NewBlackboxModal = props => {
	const [blackbox, setBlackbox] = useState({
		friendlyName: null,
		type: null,
	});
	const { ShowModal } = useContext(LoadingModalContext);

	const handleChange = e => {
		const blackbox1 = { ...blackbox };
		blackbox1[e.target.name] = e.target.value;
		setBlackbox(blackbox1);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.addBlackboxModalHandler();
		ShowModal(props.subscriptionCredit.AddBlackbox(blackbox), CurrentDict.BBCreate);
	};

	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.addBlackboxModalHandler} centered dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>{'הוספת קופסה שחורה'}</div>
				<form className={Classes.modalForm} onSubmit={handleSubmit}>
					<div>
						<div className={Classes.key}>{'סוג קופסה'}</div>
						<select name='type' defaultValue={blackbox.type ?? ''} onChange={handleChange} required>
							<option value='' disabled>
								{'בחר'}
							</option>
							<option value='Full'>{'SBC'}</option>
							<option value='Lite'>{'SBL'}</option>
							<option value='Feeder'>{'SBF'}</option>
						</select>
					</div>
					<div>
						<div className={Classes.key}>{"מס' סידורי"}</div>
						<input name='friendlyName' className={Classes.input} type='text' defaultValue={blackbox.sn ?? ''} placeholder={"מס' סידורי"} onChange={handleChange} required></input>
					</div>
					<div className={Classes.modalFooter}>
						<p onClick={props.addBlackboxModalHandler}>ביטול</p>
						<Button variant='outline-primary' type='submit' className={Classes.button}>
						{'הוספת קופסה'}
						</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default NewBlackboxModal;
