import React, { useState, Fragment } from 'react';

import Classes from './MfaModal.module.scss';
import { Modal, Button } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from '../../../assets/icons/sidebar-close.svg';

const MfaModal = props => {
	const [token, setToken] = useState(null);
	const [cellphone, setCellphone] = useState(null);
	const [currentModal, setCurrentModal] = useState(1);

	const prevModalHandler = () => {
		if (currentModal === 2) {
			setCurrentModal(1);
		}
	};

	const sendCodeHandler = async () => {
		const result = await props.sendTokenFunc();
		if (result.status !== 200) {
			window.alert(result);
			console.log(result);
			props.onClosed();
			return;
		}
		setCurrentModal(2);
		setCellphone(result.data.cellphone);
	};

	const verifyHandler = async e => {
		e.preventDefault();

		const result = await props.verifyTokenFunc(token);

		if (result.status !== 200) {
			window.alert(result);
			console.log(result);
			props.onClosed();
			return;
		}
		props.onClosed();
		setCurrentModal(1);
	};

	const resendingHandler = () => {};

	const firstModal = (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.onClosed} centered className={Classes.modalWrapper}>
			<Modal.Header style={{ border: 'none' }} className={Classes.defaultClose} closeButton />
			<div className={Classes.customClose}>
				<CloseIcon onClick={() => props.onClosed()} />
			</div>

			<div className={Classes.modal}>
				<h6 className={Classes.title}>פעולה זו דורשת זיהוי משתמש</h6>
				<p className={Classes.text}>על מנת לאשר את זהותך יישלח אליך קוד חד פעמי למס' הטלפון שהוזן במערכת</p>
				<Button variant='outline-primary' className={Classes.button} onClick={sendCodeHandler}>
					שליחת קוד
				</Button>
			</div>
		</Modal>
	);
	const secondModal = (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} centered className={Classes.modalWrapper}>
			<span role='button' className={Classes.exitButton + ' material-icons'} onClick={prevModalHandler}>
				navigate_next
			</span>
			<div className={Classes.modal2}>
				<h6 className={Classes.title}>אנא הזן את הקוד</h6>
				<br></br>
				<input className={Classes.input} type='text' placeholder='קוד זיהוי' pattern='[0-9]{7}' onChange={e => setToken(e.target.value)} required></input>
				<div className={Classes.retrySms} onClick={resendingHandler}>
					שלח הודעה חוזרת או קבלת קוד בשיחה
				</div>
				<br></br>
				<form onSubmit={event => verifyHandler(event)}>
					<Button variant='outline-primary' className={Classes.button} type='submit'>
						אישור
					</Button>
				</form>
			</div>
		</Modal>
	);

	return (
		<Fragment>
			{currentModal === 1 ? firstModal : null}
			{currentModal === 2 ? secondModal : null}
		</Fragment>
	);
};

export default MfaModal;
