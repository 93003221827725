import axios from "axios";
import { DistributionListItem } from "./DistributionListItem";

export class DistributionList {
	/**@type string */
	id = null;

	/**@type {DistributionListItem[]} */
	items = null;

	/**@type {Date} */
	createdTime = null;

	constructor(data) {
		this.id = data.id;
		this.items = data.items;
		this.createdTime = data.createdTime;
	}

	/**
	 * @returns {Promise<DistributionList>}
	 */
	static GetDistributionList(resourceId) {
		return axios.get(`/DistributionList/${resourceId}`).then(
			(response) => {
				return new DistributionList(response.data);
			},
			(reason) => {
				return null;
			}
		);
	}
	/**
	 * @param {string[]} resourceIds
	 * @returns {Promise<DistributionList[]>}
	 */
	static GetDistributionListMultiple(resourceIds) {
		return Promise.all(resourceIds.map((x) => DistributionList.GetDistributionList(x)));
	}

	/**
	 * @param {DistributionListItem} contact
	 * @returns {Promise<DistributionList>}
	 */
	AddContact(contact) {
		return this._CallContactAPI(null, "POST", contact);
	}

	/**
	 * @param {DistributionListItem} contact
	 * @returns {Promise<DistributionList>}
	 */
	UpdateContact(contact) {
		return this._CallContactAPI(contact.id, "PUT", contact);
	}

	/**
	 * @param {string} contactId
	 * @returns {Promise<DistributionList>}
	 */
	DeleteContact(contactId) {
		return this._CallContactAPI(contactId, "DELETE");
	}

	/**
	 *
	 * @param {string | null} contactId
	 * @param {import("axios").Method} method
	 * @param {DistributionListItem} contact
	 * @returns {Promise<DistributionList>}
	 */
	_CallContactAPI(contactId, method, contact = null) {
		let url = `/DistributionList/${this.id}`;
		if (contactId !== null) {
			url += `/${contactId}`;
		}

		return axios.request({ method: method, url: url, data: contact }).then(
			(response) => {
				this.items = response.data.items;
				return this;
			},
			(reason) => {
				return null;
			}
		);
	}
}
