import React from 'react';

import Classes from './DeleteContactModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from '../../../assets/icons/sidebar-close.svg';
import cn from 'classnames';

const DeleteContactModal = props => {
	return (
		<Modal
			size='sm'
			className={Classes.modalWrapper}
			aria-labelledby='contained-modal-title-vcenter'
			show={props.show}
			onHide={props.closeHandler}
			centered>
			<Modal.Header style={{ border: 'none' }} className={Classes.defaultClose} closeButton />
			<div className={Classes.customClose}>
				<CloseIcon onClick={() => props.closeHandler()} />
			</div>

			<ModalBody>
				<p className={Classes.title}>
					{'האם אתה בטוח שברצונך להסיר איש קשר זה'}
				</p>
				<div className={Classes.buttons}>
					<Button
						variant='outline-danger'
						className={cn(Classes.button, Classes.close)}
						onClick={props.submitHandler}>
						{'כן, בטוח'}
					</Button>
					<Button
						variant='outline-primary'
						className={Classes.button}
						onClick={props.closeHandler}>
						{'לא, בטל'}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default DeleteContactModal;
