import { Hierarchy } from '../DataTypes/Hierarchy';
import CurrentDict from '../services/LocaleDictionary';

class ToolbarView {
	/**@type {Hierarchy}*/
	hierarchy;

	/**@param {Hierarchy} hierarchy*/
	constructor(hierarchy) {
		this.hierarchy = hierarchy;
	}

	/**
	 * Creates a welcome text
	 * @param {string} userName
	 */
	getWelcomeText = userName => {
		const hour = new Date().getHours();
		let welcomeText = '';

		if (hour >= 21 || hour < 6) {
			welcomeText = 'לילה טוב';
		} else if (hour >= 6 && hour < 12) {
			welcomeText = 'בוקר טוב';
		} else if (hour >= 12 && hour < 16) {
			welcomeText = 'צהריים טובים';
		} else if (hour >= 16 && hour < 18) {
			welcomeText = 'אחה"צ טובים';
		} else if (hour >= 18 && hour <= 20) {
			welcomeText = 'ערב טוב';
		} else {
			welcomeText = 'שלום';
		}

		return ` ${welcomeText + ' ' + userName}, `;
	};

	getDisconnectText = () => {
		return 'התנתק';
	};
}

export default ToolbarView;
