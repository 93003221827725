import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const BarChart = props => {
	const data = {
		datasets: [
			// {
			// 	label: "ללא התראות",
			// 	backgroundColor: "rgba(54, 162, 235, 0.4)",
			// 	stack: 1,
			// 	data: [props.data?.unNotificated],
			// 	barPercentage: 1,
			// 	barThickness: 80,
			// },
			{
				label: `מנופים עם התראות ${props.data?.notificated}/${props.data?.total}`,
				backgroundColor: 'rgba(255, 99, 132, 0.4)',
				stack: 1,
				data: [props.data?.notificated],
				barPercentage: 1,
				barThickness: 80,
			},
		],
	};

	return (
		<div className='barchart-wrapper'>
			<Bar
				data={data}
				options={{
					tooltips: {
						enabled: false,
					},
					scales: {
						yAxes: [
							{
								gridLines: {
									display: false,
								},
								ticks: {
									display: false,
								},
							},
						],
					},
					legend: {
						display: true,
						position: 'bottom',
						fullWidth: true,
						rtl: true,
						labels: {
							usePointStyle: true,
						},
					},
					plugins: {
						datalabels: {
							color: 'black',
							textAlign: 'center',
							font: {
								weight: 'bold',
							},
							display: function (context) {
								return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default BarChart;
