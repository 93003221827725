import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import Classes from './CranesSettings.module.scss';
import Spinner from '../../../UI/Spinner/Spinner';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import NewCrane from '../../../NewItem/NewCrane/NewCrane';
import CraneDetailModal from '../../../Modals/CraneDetailModal/CraneDetailModal';
import CraneRemoveModal from '../../../Modals/CraneRemoveModal/CraneRemoveModal';
import CraneEditDetailsModal from '../../../Modals/CraneEditDetailsModal/CraneEditDetailsModal';
import CranePositionModal from '../../../Modals/CranePositionModal/CranePositionModal';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/sidebar-close.svg';
import useOutsideClick from '../../../../utils/useOutsideClick';

const CranesSettings = props => {
	const controlsRef = useRef();
	const [showNewCraneModal, setShowNewCraneModal] = useState(false);
	const [showCraneDetailModal, setShowCraneDetailModal] = useState(false);
	const [showCraneEditModal, setShowCraneEditModal] = useState(false);
	const [showCranePositionModal, setShowCranePositionModal] = useState(false);
	const [showCraneRemoveModal, setShowCraneRemoveModal] = useState(false);
	const [cranes, setCranes] = useState(props.cranes);
	const [form, setForm] = useState(null);
	const [newCrane, setNewCrane] = useState(null);
	const [currentCrane, setCurrentCrane] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const [openControls, setOpenControls] = useState(null);

	useEffect(() => {
		setIsLoading(false);
		setCranes(props.hierarchy.cranes);
	}, [props.hierarchy.cranes]);

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	// changeHandler = (e, index) => {
	// 	const form = [...form];
	// 	form[index][e.target.name] = e.target.value;
	// 	setState({ form: form });
	// };

	// addChangeHandler = (e) => {
	// 	const newcrane = { ...newcrane };
	// 	newcrane[e.target.name] = e.target.value;
	// 	setState({ newcrane: newcrane });
	// };

	// deleteHandler = (craneIndex) => {
	// 	const cranes = [...cranes];
	// 	cranes.splice(craneIndex, 1);
	// 	setState({ cranes: cranes });
	// };

	// submitHandler = (e) => {
	// 	e.preventDefault();
	// 	setState({
	// 		showNewCraneModal: false,
	// 		cranes: form,
	// 	});
	// };

	// addcraneHandler = (e) => {
	// 	e.preventDefault();
	// 	const cranes = [...cranes];
	// 	cranes.push(newcrane);
	// 	setState({ showNewCraneModal: false, cranes: cranes });
	// };

	const newCraneModalHandler = () => {
		const show = showNewCraneModal;
		setShowNewCraneModal(!show);
	};

	const CraneDetailModalHandler = crane => {
		if (crane != null){
			setCurrentCrane(crane)
		}
		const show = showCraneDetailModal;
		setShowCraneDetailModal(!show);
	};
	const CraneEditModalHandler = crane => {
		if (crane != null){
			setCurrentCrane(crane)
		}
		const show = showCraneEditModal;
		setShowCraneEditModal(!show);
	};
	const CranePositionModalHandler = crane => {
		if (crane != null){
			setCurrentCrane(crane)
		}
		const show = showCranePositionModal;
		setShowCranePositionModal(!show);
	};
	const CraneRemoveModalHandler = () => {
		const show = showCraneRemoveModal;
		setShowCraneRemoveModal(!show);
	};

	const [openDistIds, setOpenDistIds] = useState([]);

	if (isLoading || !props.cranes) {
		return <Spinner />;
	}

	const popover = (
		<Popover id='popover-basic' className={Classes.moreActionsPopover}>
			<div className={Classes.moreActions}>
				<div role='button' className={Classes.action} onClick={() => CraneDetailModalHandler(null)}>
					<span>פרטים נוספים</span>
				</div>
				<div role='button' className={Classes.action} onClick={() => CraneEditModalHandler(null)}>
					<span>עריכת פרטי המנוף</span>
				</div>
				<div role='button' className={Classes.action} onClick={() => CranePositionModalHandler(null)}>
					<span>שינוי שיוך המנוף</span>
				</div>
				<div hidden={true} role='button' className={Classes.action} onClick={CraneRemoveModalHandler}>
					<span>הסרת המנוף</span>
				</div>
			</div>
		</Popover>
	);

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	return (
		<>
			{showNewCraneModal ? <NewCrane hierarchy={props.hierarchy} isModalOpen={showNewCraneModal} closeHandler={newCraneModalHandler}></NewCrane> : null}
			{showCraneDetailModal ? <CraneDetailModal show={showCraneDetailModal} closeHandler={CraneDetailModalHandler} crane={currentCrane}></CraneDetailModal> : null}
			{showCraneEditModal ? <CraneEditDetailsModal show={showCraneEditModal} showEditModalHandler={CraneEditModalHandler} crane={currentCrane}></CraneEditDetailsModal> : null}
			{showCranePositionModal ? (
				<CranePositionModal showModal={showCranePositionModal} showModalHandler={CranePositionModalHandler} crane={currentCrane} hierarchy={props.hierarchy}></CranePositionModal>
			) : null}
			{showCraneRemoveModal ? <CraneRemoveModal show={showCraneRemoveModal} showRemoveModalHandler={CraneRemoveModalHandler} crane={currentCrane}></CraneRemoveModal> : null}
			<div className={Classes.tableHeader}>
				<div>
					<FilterIcon/>
					<p>סינון</p>
				</div>
				<div>
					<SearchIcon/>
					<p>חיפוש</p>
				</div>
				<div onClick={newCraneModalHandler}>
					<PlusIcon/>
					<p>הוספה</p>
				</div>
			</div>
			<div className={Classes.card} dir='rtl'>
				<div className={Classes.cardHeader}>
					<div className={Classes.cardTitle}>הגדרות מנופים</div>
					<div className={Classes.addCraneButton} role='button' onClick={newCraneModalHandler}>
						<div className={'material-icons'}>add_circle_outline</div>
					</div>
				</div>
				<table className={cn(Classes.decstopTable , 'table table-borderless') }>
					<thead>
						<tr>
							<th scope='col'>מס' רישוי</th>
							<th scope='col'>דגם</th>
							<th scope='col'>סטטוס</th>
							<th scope='col'>חברה</th>
							<th scope='col'>אתר</th>
							<th scope='col'>מס' מנוף</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{cranes
							? cranes.map((crane, index) => {
									return (
										<tr key={index}>
											<td>{crane.sn}</td>
											<td>{crane.model}</td>
											<td>{crane.GetStatus()}</td>
											<td>{crane.company.name}</td>
											<td>{crane.site.name}</td>
											<td>{crane.number}</td>
											<td className={Classes.button}>
												<OverlayTrigger trigger='click' placement='right' onToggle={() => setCurrentCrane(crane)} overlay={popover} rootClose={true}>
													<div role='button'>
														<img src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon}></img>
													</div>
												</OverlayTrigger>
											</td>
										</tr>

									);
							  })
							: null}
					</tbody>
				</table>

				<table className={cn(Classes.mobileTable , 'table table-borderless fw-bold')}>
					<thead>
						<tr>
							<th scope='col'>מס' רישוי</th>
							<th scope='col'>דגם</th>
							<th scope='col'>סטטוס</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{cranes
							? cranes.map((crane, index) => {
									return (
										<>
											<tr key={index}>
												<td>{crane.sn}</td>
												<td>{crane.model}</td>
												<td>{crane.GetStatus()}</td>
												<td className="text-left" onClick={() => toggleOnetDistId(index)}><ChevronIcon/></td>
											</tr>

											<tr className={cn((openDistIds.includes(index) ? Classes.open : null), Classes.tableExpand)}>
												<td colSpan={2}>
													<div>
														<span className={Classes.distHeader}>חברה</span>
														<span>{crane.company.name}</span>
													</div>
													<div>
														<span className={Classes.distHeader}>אתר</span>
														<span>{crane.site.name}</span>
													</div>
													<div>
														<span className={Classes.distHeader}>מס' מנוף</span>
														<span>{crane.number}</span>
													</div>
												</td>
												<td></td>
												<td className={Classes.mobileButton}>
													<div role='button'>
														<img src={require('../../../../assets/icons/more.svg')} className={Classes.moreIcon} onClick={() => setOpenControls(index)}></img>
													</div>
													<div className={cn(Classes.controls, openControls === index ? Classes.openControls : null)} ref={controlsRef}>
														<CloseIcon className={Classes.close} onClick={() => setOpenControls(null)} />
														<div role='button' className={Classes.action} onClick={() => CraneDetailModalHandler(crane)}>
															<span>פרטים נוספים</span>
														</div>
														<div role='button' className={cn(Classes.action, Classes.centralDiv)} onClick={() => CraneEditModalHandler(crane)}>
															<span>עריכת פרטי המנוף</span>
														</div>
														<div role='button' className={Classes.action} onClick={() => CranePositionModalHandler(crane)}>
															<span>שינוי שיוך המנוף</span>
														</div>
													</div>
												</td>
											</tr>
										</>
									);
							  })
							: null}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default CranesSettings;
