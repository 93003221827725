import React, { useState, useCallback } from 'react';
import { ActionTexts } from '../services/LocaleDictionary';

export const LoadingModalContext = React.createContext();

export default function LoadingModalProvider({ children }) {
	const [show, setShow] = useState(false);
	const [response, setResponse] = useState(null);
	/**@type {[ActionTexts, *]} */
	const [actionTexts, setActionTexts] = useState(null);

	const promiseHandler = async (promise, actionTexts) => {
		setResponse(null);
		setActionTexts(actionTexts);
		setShow(true);

		const response = {
			value: null,
			isError: false,
		};

		response.value = await promise.catch(reason => {
			response.isError = true;
			return reason;
		});

		if (response.value == null) {
			response.isError = true;
		}

		setResponse(response);
		return response;
	};

	const contextValue = {
		response: response,
		show: show,
		toggleShow: () => {
			setShow(!show);
		},
		ShowModal: (promise, actionTexts) => promiseHandler(promise, actionTexts),
		actionTexts: actionTexts,
	};

	return <LoadingModalContext.Provider value={contextValue}>{children}</LoadingModalContext.Provider>;
}
