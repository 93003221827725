import React, { useState, useEffect } from 'react';
import { License } from '../../../DataTypes/License';

import CraneEditDetailsModal from '../../Modals/CraneEditDetailsModal/CraneEditDetailsModal';
import CraneRemoveModal from '../../Modals/CraneRemoveModal/CraneRemoveModal';
import Spinner from '../../UI/Spinner/Spinner';

import Classes from './CraneDetailsTable.module.scss';
import cn from 'classnames';

const CraneDetailsTable = props => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	/**@type {[License, any]} */
	const [license, setLicense] = useState(null);

	useEffect(() => {
		setLicense(findBindBlackbox());
		setIsLoading(false);
	}, []);

	const showEditModalHandler = () => {
		const show = showEditModal;
		setShowEditModal(!show);
	};
	const showRemoveModalHandler = () => {
		const show = showRemoveModal;
		setShowRemoveModal(!show);
	};

	const findBindBlackbox = () => {
		return props.hierarchy.subscriptionCredit?.licenses?.find(l => l.deviceId === props.crane.deviceId);
	};
	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={Classes.card} dir='rtl'>
			<span className={Classes.cardTitle}>פרטי המנוף</span>
			<div role='button' className={Classes.icons} hidden={true}>
				<img onClick={showRemoveModalHandler} src={require('../../../assets/icons/trash.svg')} className={Classes.icon} />
			</div>
			<div role='button' className={Classes.icons}>
				<img onClick={showEditModalHandler} src={require('../../../assets/icons/edit.svg')} className={Classes.icon} />
			</div>
			<div className={Classes.craneDetailsTable}>
				<div className={Classes.craneDataParagraph}>
					<span className={Classes.paragraphTitle}>{'נתוני מנוף'}</span>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'מספר רישוי'}</span>
						<span className={Classes.tableDetailsValue}>{props.crane.sn}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'סוג מנוף'}</span>
						<span className={Classes.tableDetailsValue}>{props.crane.type}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'דגם'}</span>
						<span className={Classes.tableDetailsValue}>{props.crane.model}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'יצרן'}</span>
						<span className={Classes.tableDetailsValue}>{props.crane.manufacturer}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'גובה מנוף'}</span>
						<span className={Classes.tableDetailsValue}>{props.crane.height}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'אורך זרוע'}</span>
						<span className={Classes.tableDetailsValue}>{props.crane.length}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'גובל כבל'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'גובל סיבוב'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'גובל עגלה'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
				</div>
				<div className={Classes.blackboxParagraph} hidden={!license}>
					<span className={Classes.paragraphTitle}>{'קופסה שחורה'}</span>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'סוג קופסה'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'מספר סידורי'}</span>
						<span className={Classes.tableDetailsValue}>{license?.blackboxBinding?.blackbox?.friendlyName}</span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'MAC'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'IP'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'גרסה'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'מספר רישיון'}</span>
						<span className={Classes.tableDetailsValue}>{license?.id}</span>
					</div>
				</div>
				<div className={Classes.testsParagraph}>
					<span className={Classes.paragraphTitle}>{'בדיקות'}</span>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'טסט אחרון'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
					<div className={Classes.details}>
						<span className={Classes.tableDetailsKey}>{'טסט הבא'}</span>
						<span className={Classes.tableDetailsValue}></span>
					</div>
				</div>
			</div>
			{showEditModal ? <CraneEditDetailsModal crane={props.crane} show={showEditModal} showEditModalHandler={showEditModalHandler}></CraneEditDetailsModal> : null}
			{showRemoveModal ? <CraneRemoveModal show={showRemoveModal} showRemoveModalHandler={showRemoveModalHandler}></CraneRemoveModal> : null}
		</div>
	);
};

export default CraneDetailsTable;
