import { Phone } from "../MyProfile";
import axios from "axios";

export class EditUser {
  /**@type {string} */
  id = null;
  /**@type {string} */
  fullName = null;
  /**@type {string} */
  mail = null;
  /**@type {Phone} */
  phone = null;
  /**@type {string} */
  role = null;
  /**@type {string} */
  company = null;
  /**@type {boolean} */
  isPending = false;

  constructor(data) {
    this.id = data?.id;
    this.fullName = data?.fullName;
    this.mail = data?.mail;
    this.phone = new Phone(data?.phone);
    this.role = data?.role;
    this.company = data?.company;
    this.isPending = data?.isPending;
  }

  Edit(key, value) {
    switch (key) {
      case "phone":
        this.phone = new Phone({ number: value, countryCode: "972" });
        break;
      default:
        this[key] = value;
        break;
    }
  }

  CreateNewUser() {
    return axios.post("/User/Invite", {
      mail: this.mail,
      role: this.role,
      company: this.company,
    });
  }

  UpdateUser() {}
  //TODO

  UpdateUserRole() {
    return axios.put(`/User/${this.id}/ChangeRole`, null, { params: { role: this.role } });
  }
}
