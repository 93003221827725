import React from 'react';
import NotificationListWithPolling from '../NotificationListWithPolling/NotificationListWithPolling';
import CraneNotification from './CraneNotification/CraneNotification';
import Classes from './CraneNotifications.module.css';

const CraneNotifications = props => {
	return (
		<div className={Classes.craneNotifications}>
			<div className={Classes.headerAlerts}>התראות אחרונות</div>
			<div className={Classes.headerBody}>
				{
					<div className={Classes.headerBody}>
						{<NotificationListWithPolling pollingInterval={2000} cranes={[props.crane]} notifications={null} notificationToItemViewFunc={n => <CraneNotification notification={n} />} />}
					</div>
				}
			</div>
		</div>
	);
};

export default CraneNotifications;
