import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Classes from "./ErrorModal.module.css";

const ErrorModal = (props) => {
	const [show, setShow] = useState(true);
	const handleClose = () => setShow(false);
	const [showErrorDescription, setShowErrorDescription] = useState(false);

	return (
		<>
			<Modal className={Classes.modal} show={show} onHide={() => handleClose()} backdrop='static' keyboard={false}>
				<Modal.Header className={Classes.modalHeader} closeButton>
					<Modal.Title>Error {`( status code: ${props.statusCode} )`}</Modal.Title>
				</Modal.Header>
				<Modal.Body className={Classes.modalBody}>
					<p className={Classes.errorDescription}>{showErrorDescription ? props.content : null}</p>
					<Button className={Classes.errorDescriptionButton} onClick={() => setShowErrorDescription(!showErrorDescription)}>
						{showErrorDescription ? "Close Error Description" : "Show Error Description"}
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ErrorModal;
