import React from 'react';
import Classes from './OrganizationCranesStatus.module.css';

import CompanyCranesStatus from '../../Company/CompanyCranesStatus/CompanyCranesStatus';
import HierarchyView from '../../../ViewModels/HierarchyView';

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 */
const OrganizationCranesStatus = props => {
	return (
		<div>
			<div className={Classes.wrapperCard}>
				<div className={Classes.cranesCards}>
					{props.hierarchyView.companyViews.map(companyView => (
						<CompanyCranesStatus key={companyView.company.id} view={companyView} />
					))}
				</div>
			</div>
		</div>
	);
};

export default OrganizationCranesStatus;
