import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Classes from './AddContactModal.module.scss';

const AddContactModal = props => {
	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.closeHandler} dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />
			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>הוספת איש קשר חדש</div>
				<div>
					<form onSubmit={event => props.submitHandler(event)} className={Classes.detailsForm}>
						<br />
						<div>
							<label className={Classes.detailsKey}>שם מלא</label>
							<input name='fullName' className={Classes.input} type='text' onChange={event => props.changeHandler(event)} required></input>
						</div>
						<div>
							<label className={Classes.detailsKey}>חברה</label>
							<input name='company' className={Classes.input} type='text' onChange={event => props.changeHandler(event)} required></input>
						</div>
						<div>
							<label className={Classes.detailsKey}>תפקיד</label>
							<input name='role' className={Classes.input} type='text' onChange={event => props.changeHandler(event)} required></input>
						</div>
						<div>
							<label className={Classes.detailsKey}>טלפון</label>
							<input
								name='phone'
								className={Classes.input}
								type='tel'
								pattern='[0][5][0-9]{8}'
								title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
								onChange={event => props.changeHandler(event)}
								required></input>
						</div>
						<br></br>
						<br></br>
						<div className={Classes.modalFooter}>
							<Button type='submit' variant='outline-primary' size='sm' className={Classes.button}>
								הוספה
							</Button>
							<button className={Classes.closeModal} style={{ display: 'none' }} onClick={() => props.closeHandler()}>
								ביטול
							</button>
						</div>
						<br></br>
						<br></br>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AddContactModal;
