import React, { useState, useContext } from 'react';

import Classes from './BBRemoveModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const BBRemoveModal = props => {
	const { ShowModal } = useContext(LoadingModalContext);

	const handleRemove = () => {
		props.removeModalHandler();
		ShowModal(props.blackbox.RemoveBlackbox(), CurrentDict.BBRemove);
	};
	return (
		<Modal className={Classes.modal} size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.removeModalHandler} centered>
			<Modal.Header className={Classes.close} style={{ border: 'none' }} closeButton />
			<ModalBody>
				<p className={Classes.title}>{'האם אתה בטוח שברצונך להסיר קופסה שחורה זו מהמערכת'}</p>
				<div className={Classes.buttons}>
					<Button variant='outline-danger' className={Classes.button} onClick={handleRemove}>
						{'כן, בטוח'}
					</Button>
					<Button variant='outline-primary' className={Classes.button} onClick={props.removeModalHandler}>
						{'לא, בטל'}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default BBRemoveModal;
