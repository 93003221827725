import React, { useEffect, useState } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import hierarchyContext from '../../../contexts/HierarchyContext';

import Spinner from '../../UI/Spinner/Spinner';
import Classes from './Settings.module.scss';
import CranesSettings from './CranesSettings/CranesSettings';
import UsersSettings from './UsersSettings/UsersSettings';
import LicenseSettings from './LicenseSettings/LicenseSettings';
import BlackboxSettings from './BlackboxSettings/BlackboxSettings';
import FormikForm from '../../Modals/FormikForm/FormikForm';
import SteakyHeader from '../../SteakyHeader/SteakyHeader';

const Settings = props => {
	const [cranes, setCranes] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		loadCranes();
		setIsLoading(false);
	}, []);

	const loadCranes = () => {
		let cranesArray = [];
		let craneObj = {};
		props.hierarchy.companies.map(company => {
			company.sites.map(site => {
				site.cranes.map(crane => {
					craneObj = crane;
					craneObj['company'] = { ...company };
					craneObj['site'] = { ...site };
					cranesArray.push(craneObj);
				});
			});
		});
		setCranes([...cranesArray]);
		setIsLoading(false);
	};

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={Classes.wrapper}>
			<Container fluid>	
				<h4><SteakyHeader>הגדרות</SteakyHeader></h4>
				{/* <FormikForm show={true}></FormikForm> */}
				<Row>
					<Col md={4}>
						<div className={Classes.headerCrane}>
							<ul>
								<li>
									<NavLink exact to={'/settings/'} className={Classes.link} activeClassName={Classes.active}>
										מנופים
									</NavLink>
								</li>
								<li>
									<NavLink exact to={'/settings/blackbox'} className={Classes.link} activeClassName={Classes.active}>
										חומרה
									</NavLink>
								</li>
								<li>
									<NavLink exact to={'/settings/license'} className={Classes.link} activeClassName={Classes.active}>
										רשיונות
									</NavLink>
								</li>
								<li>
									<NavLink to={'/settings/users'} className={Classes.link} activeClassName={Classes.active}>
										משתמשים
									</NavLink>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
			<Route exact path={'/settings/'} render={() => <CranesSettings hierarchy={props.hierarchy} cranes={cranes} />} />
			<Route path={'/settings/license'} render={() => <LicenseSettings hierarchy={props.hierarchy} cranes={cranes} />} />
			<Route path={'/settings/blackbox'} render={() => <BlackboxSettings hierarchy={props.hierarchy} />} />
			<Route path={'/settings/users'} render={() => <UsersSettings hierarchy={props.hierarchy} />} />
		</div>
	);
};

export default Settings;
