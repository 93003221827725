import axios from "axios";
import { Phone } from "./MyProfile";

export class User {
  /**@type {string} */
  id = null;
  /**@type {string} */
  fullName = null;
  /**@type {string} */
  mail = null;
  /**@type {Phone} */
  phone = null;
  /**@type {string} */
  role = null;
  /**@type {string} */
  company = null;
  /**@type {boolean} */
  isPending = false;

	constructor(data) {
		this.id = data?.id;
		this.fullName = data?.fullName;
		this.mail = data?.mail;
		this.phone = new Phone(data?.phone);
		this.role = data?.role;
		this.company = data?.company;
		this.isPending = data?.isPending;
	}

  DeleteUser() {
    return axios.delete(`/User/${this.id}`);
  }
}
