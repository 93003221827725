import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Classes from './ContactUpdateModal.module.scss';

const ContactUpdateModal = props => {
	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.closeHandler} dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />
			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>עדכון איש קשר</div>
				<form className={Classes.detailsForm} onSubmit={event => props.submitHandler(event)}>
					<div>
						<label className={Classes.detailsKey}>שם מלא</label>
						<input name='fullName' className={Classes.input} type='text' defaultValue={props.contact?.fullName} onChange={event => props.changeHandler(event)} required></input>
					</div>
					<div>
						<label className={Classes.detailsKey}>חברה</label>
						<input name='company' className={Classes.input} type='text' defaultValue={props.contact?.company} onChange={event => props.changeHandler(event)} required></input>
					</div>
					<div>
						<label className={Classes.detailsKey}>תפקיד</label>
						<input name='role' className={Classes.input} type='text' defaultValue={props.contact?.role} onChange={event => props.changeHandler(event)} required></input>
					</div>
					<div>
						<label className={Classes.detailsKey}>טלפון</label>
						<input
							name='phone'
							className={Classes.input}
							type='tel'
							pattern='[0][5][0-9]{8}'
							title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
							defaultValue={props.contact?.phone?.number}
							onChange={event => props.changeHandler(event)}
							required></input>
					</div>
					<div className={Classes.modalFooter}>
						<Button variant='outline-primary' type='submit' className={Classes.button}>
							עדכון
						</Button>
						<button className={Classes.closeModal} style={{ display: 'none' }} onClick={() => props.closeHandler()}>
							ביטול
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default ContactUpdateModal;
