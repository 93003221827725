import React from 'react';
import Classes from './SiteCranesStatus.module.scss';

import MiniCraneControl from '../../Notification/MiniCraneControl/MiniCraneControl';
import SiteView from '../../../ViewModels/SiteView';

/**
 *
 * @param {Object} props
 * @param {SiteView} props.view
 * @param {boolean} props.wrapped 
 */
const SiteCranesStatus = props => {
	const cranes = (
		<>
			<hr className={Classes.hr} />
			<div className={Classes.siteName}>{props.view.site.name}</div>
			<div className={Classes.wrapper}>
				{props.view.craneViews.map(craneView => (
					<MiniCraneControl key={craneView.crane.id} view={craneView} />
				))}
			</div>
		</>
	);

	const wrapper = props.wrapped ? (
		<div className={Classes.siteWrapper}>
			{cranes}
		</div>
	) : cranes

	return <>{props.view.site.cranes.length != 0 ? wrapper : null}</>;
};

export default SiteCranesStatus;
