import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const PieChart = props => {
	const data = {
		labels: ['תקולים', 'לא פעילים', 'פעילים'],
		datasets: [
			{
				label: 'פעילות מנופים',
				data: props.data,
				backgroundColor: ['rgba(255, 99, 132, 0.4)', 'rgba(54, 162, 235, 0.4)', 'rgba(75, 192, 192, 0.4)'],
				borderColor: ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
				borderWidth: 1,
			},
		],
		text: '15%',
	};

	return (
		<div className='barchart-wrapper'>
			<Doughnut
				data={data}
				options={{
					legend: {
						reverse: true,
						display: true,
						position: 'bottom',
						fullWidth: true,
						// rtl: true,
						labels: {
							usePointStyle: true,
						},
					},
					plugins: {
						datalabels: {
							color: 'black',
							textAlign: 'center',
							font: {
								weight: 'bold',
							},
							display: function (context) {
								return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default PieChart;
