import React, { useState, useContext } from 'react';

import Classes from './BBUnassignmentModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const BBUnassignmentModal = props => {
	const { ShowModal } = useContext(LoadingModalContext);

	const removeHandler = () => {
		props.unassignModalHandler();
		ShowModal(props.license.UnbindCrane(props.blackbox.id, props.companyId, props.siteId, props.craneId), CurrentDict.BBUnassign);
	};

	return (
		<Modal className={Classes.modal} size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.unassignModalHandler} centered>
			<Modal.Header className={Classes.close} style={{ border: 'none' }} closeButton />
			<ModalBody>
				<p className={Classes.title}>
					{'ביטול השיוך למנוף'}
					<br />
					<br />
					{`קופסה זו משויכת למנוף ${props.craneSn} האם אתה בטוח שברצונך לבטל את השיוך`}
				</p>
				<br />
				<div className={Classes.buttons}>
					<Button variant='outline-danger' className={Classes.button} onClick={removeHandler}>
						{'כן, בטוח'}
					</Button>
					<Button variant='outline-primary' className={Classes.button} onClick={props.unassignModalHandler}>
						{'לא, בטל'}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default BBUnassignmentModal;
