import React from 'react';
import Classes from './CraneNotification.module.css';

const CraneNotification = props => {
	return (
		<div className={Classes.notificationCard}>
			<div className={Classes.content}>{props.notification?.data}</div>
			<div className={Classes.time}>{props.notification?.createdTime}</div>
		</div>
	);
};

export default CraneNotification;
