import React, { useState, useEffect } from 'react';
import Classes from './Registration.module.css';
import logo from '../../assets/img/skylinelogo.png';
import Spinner from '../UI/Spinner/Spinner';
import { Modal, Button } from 'react-bootstrap';
import Eula from './Eula';
import { EditRegistrationUser } from '../../DataTypes/Edit/EditRegistrationUser';

const Registration = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [currentScreen, setCurrentScreen] = useState(1);
	const [hiddenPhone, setHiddenPhone] = useState(null);
	const [isShowEulaModal, setIsShowEulaModal] = useState(false);
	/**@type {[EditRegistrationUser, any]} */
	const [user, setUser] = useState(new EditRegistrationUser());
	const [password, setPassword] = useState(null);

	const hidePhone = () => {
		let hide = user.phone?.number?.toString().substring(6, 10);
		hide = '******' + hide;
		setHiddenPhone(hide);
	};

	useEffect(() => {
		user.GetInviteDetails(props.token).then(
			result => {
				console.log('result:');
				console.log(result);
				setUser(new EditRegistrationUser(result.data));
				setIsLoading(false);
			},
			reason => {
				console.log('reason:');
				console.log(reason);
				window.alert(reason);
			}
		);
	}, []);

	const nextScreen = () => {
		switch (currentScreen) {
			case 1:
				setCurrentScreen(2);
				break;

			case 2:
				hidePhone();
				setCurrentScreen(3);

				break;

			case 3:
				break;

			default:
				break;
		}
	};

	const prevScreen = () => {
		switch (currentScreen) {
			case 1:
				break;

			case 2:
				setCurrentScreen(1);

				break;

			case 3:
				setCurrentScreen(2);

			default:
				break;
		}
	};

	const eulaModalHandler = () => {
		const show = isShowEulaModal;
		setIsShowEulaModal(!show);
	};

	const changeHandler = e => {
		user.Edit(e.target.name, e.target.value);
	};

	const screen2SubmitHandler = e => {
		e.preventDefault();
		user.Register().then(
			result => {
				console.log('Register succeeded, result:');
				console.log(result);
				nextScreen();
			},
			reason => {
				console.log('Register failed, reason:');
				console.log(reason);
				window.alert(reason);
			}
		);
	};

	const screen3SubmitHandler = e => {
		e.preventDefault();

		user.RegisterFinish().then(
			result => {
				console.log('RegisterFinish succeeded, result:', result);

				//Redirect to main app
				window.location.href = window.location.origin;
			},
			reason => {
				console.log('RegisterFinish failed, reason:');
				console.log(reason);
				window.alert(reason);
			}
		);
	};

	const screen1 = (
		<div className={Classes.card}>
			<div className={Classes.title}>{'יצירת חשבון'}</div>
			<p className={Classes.subtitle}>{'הוזמנת להצטרף למערכת, יש לוודא ולאשר כי הפרטים הבאים הינם נכונים ותואמים'}</p>
			<div className={Classes.inputs}>
				<div className={Classes.input}>
					<div className={Classes.value}>{user?.mail}</div>
					<span className={Classes.key}>{'מייל'}</span>
				</div>
				<div className={Classes.input}>
					<div className={Classes.value}>{user?.company}</div>
					<span className={Classes.key}>{'חברה'}</span>
				</div>
				<div className={Classes.input}>
					<div className={Classes.value}>{user?.role}</div>
					<span className={Classes.key}>{'תפקיד'}</span>
				</div>
			</div>
			<div className={Classes.resending}>
				{' הפרטים אינם תואמים? '}
				<span role='button'>{' שליחת בקשה לשינוי '}</span>
			</div>
			<Button variant='outline-primary' type='submit' onClick={nextScreen} className={Classes.button}>
				{'אישור פרטים'}
			</Button>
			<div className={Classes.policy}>
				{' לחיצה על אישור פרטים מאשרת שקראת והסכמת '}
				<span role='button' onClick={eulaModalHandler}>
					{' לתנאי השימוש והפרטיות של החברה '}
				</span>
			</div>
		</div>
	);
	const screen2 = (
		<div className={Classes.card}>
			<div role='button' className={Classes.prevButton + ' material-icons'} onClick={prevScreen}>
				navigate_next
			</div>
			<div className={Classes.title}>{'רק עוד כמה פרטים'}</div>

			<form onSubmit={screen2SubmitHandler} className={Classes.inputs2}>
				<div className={Classes.input}>
					<input className={Classes.value} type='text' name='fullName' placeholder='שם מלא' onChange={changeHandler} required></input>
					<span className={Classes.key2}>{'שם מלא'}</span>
				</div>
				<div className={Classes.input}>
					<input
						className={Classes.value}
						type='password'
						name='password'
						placeholder={'סיסמא'}
						onChange={e => {
							changeHandler(e);
							setPassword(e);
						}}
						minLength={8}
						maxLength={64}
						pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
						title='Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
						required></input>
					<span className={Classes.key2}>{'סיסמא'}</span>
				</div>
				<div className={Classes.input}>
					<input
						className={Classes.value}
						type='password'
						name='rePassword'
						placeholder={'וידוא סיסמא'}
						minLength={8}
						maxLength={64}
						disabled={!user.GetPassword()}
						pattern={user.GetPassword()}
						title='הסיסמאות אינן תואמות'
						required></input>
					<span className={Classes.key2}>{'הקלדה חוזרת'}</span>
				</div>
				<div className={Classes.input}>
					<input
						className={Classes.value}
						type='tel'
						name='phone'
						placeholder='נייד'
						pattern='[0][5][0-9]{8}'
						title="נא הכנס מס' טלפון תקין, דוג' [0501234567]"
						onChange={changeHandler}
						required></input>
					<span className={Classes.key2}>{'נייד'}</span>
				</div>

				<div className={Classes.subtitle2}>{' בעזרת הנייד נוכל לעדכן אותך בכל מה שקורה מבלי שתצטרך להתחבר לחשבונך '}</div>
				<Button variant='outline-primary' type='submit' className={Classes.button}>
					{'שליחת קוד אימות'}
				</Button>
			</form>
		</div>
	);
	const screen3 = (
		<div className={Classes.card}>
			<div role='button' className={Classes.prevButton + ' material-icons'} onClick={prevScreen}>
				navigate_next
			</div>
			<form onSubmit={screen3SubmitHandler} className={Classes.inputs}>
				<div className={Classes.title}>{'הכנסת קוד אימות'}</div>
				<span className={Classes.subtitle3}>{`נשלחה אליך הודעה עם קוד אימות חד פעמי למספר הבא `}</span>
				<p>{hiddenPhone}</p>
				<div className={Classes.input}>
					<input className={Classes.value} type='text' name='validationCode' placeholder='קוד אימות' onChange={changeHandler} required></input>
				</div>
				<div className={Classes.resending3}>{' לשליחת קוד חוזרת או קבלת קוד בשיחה'}</div>
				<Button variant='outline-primary' type='submit' className={Classes.button}>
					{'אישור קוד'}
				</Button>
			</form>
		</div>
	);

	const eulaModal = (
		<Modal onClick={eulaModalHandler} size='xl' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' centered show={isShowEulaModal} onHide={eulaModalHandler}>
			{Eula}
		</Modal>
	);

	if (!user || isLoading) {
		return <Spinner />;
	}

	return (
		<div className={Classes.wrapper}>
			<div className={Classes.logo}>
				<img src={logo} />
			</div>
			{currentScreen === 1 ? screen1 : null}
			{currentScreen === 2 ? screen2 : null}
			{currentScreen === 3 ? screen3 : null}
			{eulaModal}
		</div>
	);
};

export default Registration;
