import React, { useState, useContext } from 'react';

import Classes from './CraneRemoveModal.module.css';
import { Button, Modal, ModalBody } from 'react-bootstrap';

const CraneRemoveModal = props => {
	const handleRemove = () => {
		alert('Comming soon');
	};

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.showRemoveModalHandler} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />

			<ModalBody>
				<p className={Classes.title}>{'האם אתה בטוח שברצונך להסיר מנוף זה ולהוציא אותו מהמערכת'}</p>
				<div className={Classes.buttons}>
					<Button variant='outline-danger' className={Classes.button} onClick={handleRemove}>
						{'כן, בטוח'}
					</Button>
					<Button variant='outline-primary' className={Classes.button} onClick={props.showRemoveModalHandler}>
						{'לא, בטל'}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default CraneRemoveModal;
