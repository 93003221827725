import axios from "axios";
import { Phone } from "../MyProfile";

export class EditDistributionListItem {
	/**@type {string} */
	id = null;
	/**@type {string} */
	fullName = null;
	/**@type {string} */
	company = null;
	/**@type {boolean} */
	mail = null;
	/**@type {Phone} */
	phone = null;
	/**@type {string} */
	role = null;

	constructor(data) {
		this.id = data?.id;
		this.fullName = data?.fullName;
		this.mail = data?.mail;
		this.phone = new Phone(data?.phone);
		this.role = data?.role;
		this.company = data?.company;
	}

	Edit(key, value) {
		switch (key) {
			case "id":
				this.id = value;
				break;
			case "fullName":
				this.fullName = value;
				break;
			case "company":
				this.company = value;
				break;
			case "mail":
				this.mail = value;
				break;
			case "phone":
				this.phone.number = value;
				break;
			case "role":
				this.role = value;
				break;
			default:
				null;
		}
	}
}
