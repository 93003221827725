import React, { Component, useState, useEffect } from 'react';

import Classes from './NewItem.module.scss';
import { Modal, Button, Container } from 'react-bootstrap';
import NewCompany from './NewCompany/NewCompany';
import NewSite from './NewSite/NewSite';
import NewCrane from './NewCrane/NewCrane';
import { MyProfile } from '../../DataTypes/MyProfile';
import Spinner from '../UI/Spinner/Spinner';

const NewItem = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [showMainModal, setShowMainModal] = useState(true);
	const [showCrane, setShowCrane] = useState(false);
	const [showSite, setShowSite] = useState(false);
	const [showCompany, setShowCompany] = useState(false);
	/**@type {[MyProfile, any]} */
	const [currentUser, setCurrentUser] = useState(MyProfile.Current);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	const closeHandler = () => {
		setShowCrane(false);
		setShowSite(false);
		setShowCompany(false);
		props.closeHandler();
	};
	if (isLoading) {
		return <Spinner />;
	}
	const mainModal = (
		<div>
			<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.closeHandler} centered>
				<Modal.Header className={Classes.header} style={{ border: 'none' }} closeButton />

				<Modal.Body>
					<div className={Classes.title}>בחר פריט להוספה</div>
					<div className={Classes.buttons}>
						<Button
							variant='outline-primary'
							onClick={() => {
								setShowCrane(true);
								setShowSite(false);
								setShowCompany(false);
							}}
							disabled={!currentUser.permissions.craneCRUD.create}>
							מנוף
						</Button>
						<Button
							variant='outline-primary'
							onClick={() => {
								setShowCrane(false);
								setShowSite(true);
								setShowCompany(false);
							}}
							disabled={!currentUser.permissions.siteCRUD.create}>
							אתר
						</Button>
						<Button
							variant='outline-primary'
							onClick={() => {
								setShowCrane(false);
								setShowSite(false);
								setShowCompany(true);
							}}
							disabled={!currentUser.permissions.companyCRUD.create}>
							חברה
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);

	return (
		<div>
			{showMainModal ? mainModal : null}
			{showCompany ? <NewCompany closeHandler={closeHandler} isModalOpen={showCompany} hierarchy={props.hierarchy}></NewCompany> : null}
			{showSite ? <NewSite closeHandler={closeHandler} isModalOpen={showSite} hierarchy={props.hierarchy}></NewSite> : null}
			{showCrane ? <NewCrane closeHandler={closeHandler} isModalOpen={showCrane} hierarchy={props.hierarchy}></NewCrane> : null}
		</div>
	);
};

export default NewItem;
