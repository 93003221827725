import React, { useState, useEffect } from 'react';
import Classes from './CompanyGeneral.module.scss';
import cn from 'classnames';
import PieChart from '../../Charts/PieChart/PieChart';
import LineChart from '../../Charts/LineChart/LineChart';
import BarChart from '../../Charts/BarChart/BarChart';
import ControlNotification from '../../Notification/ControlNotification/ControlNotification';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from '../../UI/Spinner/Spinner';
import { Company } from '../../../DataTypes/Company';
import { OperationSummary } from '../../../DataTypes/OperationSummary';
import { hoursPerMonthCalc } from '../../../services/services';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import NotificationListWithPolling from '../../Notification/NotificationListWithPolling/NotificationListWithPolling';
import CompanyView from '../../../ViewModels/CompanyView';
import HierarchyView from '../../../ViewModels/HierarchyView';

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 * @param {CompanyView} props.view
 */
const CompanyGeneral = props => {
	const [hoursPerMonth, setHoursPerMonth] = useState(null);
	/**@type {[OperationSummary[], any]} */
	const [activityArr, setActivityArr] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	/**@type {[OperationSummary[],any]} */
	const [operationSummaryArr, setOperationSummaryArr] = useState(null);

	useEffect(() => {
		craneActivityData();
		props.view.company.GetActivitySummary(new Date().getFullYear()).then(
			response => {
				const arr = response.data.sort((a, b) => (a.date < b.date ? 1 : -1)).map(x => new OperationSummary(x));
				setOperationSummaryArr(arr);
				setHoursPerMonth(hoursPerMonthCalc(arr));
				setIsLoading(false);
			},
			reason => {
				console.log(reason);
			}
		);
	}, [props.view.company.id]);

	const craneActivityData = () => {
		let active = 0;
		let inactive = 0;
		let fault = 0;
		props.view.company.sites.map(s =>
			s.cranes.map(c => {
				if (c.status === 'Fault') {
					fault++;
				} else if (c.deviceStatus?.isPowered === true) {
					active++;
				} else {
					inactive++;
				}
			})
		);

		let activityArr = [];
		activityArr.push(fault);
		activityArr.push(inactive);
		activityArr.push(active);
		setActivityArr(activityArr);
		return activityArr;
	};

	const PopulateNotifications = () => {
		let controlNotifications = props.hierarchyView.hierarchy.notificationManager.notifications
			.filter(x => x.crane.site.company.id === props.view.company.id)
			.map(x => <ControlNotification notification={x} />);
		if (controlNotifications !== null) {
			return controlNotifications;
		}
		return 'אין התראות להצגה';
	};
	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	};

	const countNotifications = () => {
		let arr = props.hierarchyView.hierarchy.notificationManager.notifications.filter(x => x.crane.site.company.id === props.view.company.id).map(n => n.deviceId);
		arr = arr.filter(onlyUnique);
		let data = {
			notificated: arr.length,
			total: props.view.company?.cranes?.length,
		};
		return data;
	};

	const yearSelect = e => {
		props.view.company.GetActivitySummary(parseInt(e.target.value)).then(
			response => {
				const arr = response.data.sort((a, b) => (a.date < b.date ? 1 : -1)).map(x => new OperationSummary(x));
				setOperationSummaryArr(arr);
				setHoursPerMonth(arr);
				setIsLoading(false);
			},
			reason => {
				console.log(reason);
			}
		);
	};
	if (isLoading) {
		return <Spinner />;
	}

	return (
		<div className={Classes.wrapperOrg}>
			<Container fluid>
				<Row>
					<Col lg={12} xl={3}>
						<div className={Classes.card}>
							<div className={Classes.bar}>
								<div className={Classes.headerBar}>
									<div className={Classes.title}>התראות מנופים</div>
								</div>
								<div className={Classes.bodyCardBar}>
									<BarChart data={countNotifications()} />
								</div>
							</div>
						</div>
					</Col>
					<Col lg={12} xl={4}>
						<div className={Classes.card}>
							<div className={Classes.pie}>
								<div className={Classes.headerCard}>
									<div className={Classes.title}>פעילות מנופים</div>
								</div>
								<div className={Classes.bodyCardPie}>
									<PieChart data={activityArr} />
								</div>
							</div>
						</div>
					</Col>
					<Col lg={12} xl={5}>
						<div className={Classes.card}>
							<div className={Classes.line}>
								<div className={Classes.headerCard}>
									<div className={Classes.title}>ממוצע פעילות חודשי</div>
								</div>
								<div className={Classes.bodyCardLine}>
									<LineChart data={hoursPerMonth} />
								</div>
								<hr />
								<div className={Classes.footerCardLine}>
									<div className={Classes.footerYear}>
										<form>
											<select
												name='year'
												onChange={e => {
													yearSelect(e);
												}}>
												<option value={parseInt(new Date().getFullYear())}>{new Date().getFullYear()}</option>
												<option value={parseInt(new Date().getFullYear() - 1)}>{new Date().getFullYear() - 1}</option>
												<option value={parseInt(new Date().getFullYear() - 2)}>{new Date().getFullYear() - 2}</option>
											</select>
										</form>
									</div>
									<div className={Classes.footerTitle}>{`סה"כ ${(hoursPerMonth?.reduce((a, b) => a + b, 0) / 12).toFixed(2)} שעות בממוצע`}</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<div className={cn(Classes.card, Classes.transparent)}>
							<div className={Classes.notificationsCard}>
								<div className={Classes.headerCardNotification}>התראות אחרונות</div>
								<div className={Classes.bodyCardNotification}>
									<NotificationListWithPolling
										pollingInterval={2000}
										cranes={props.view.company.cranes}
										notifications={null}
										notificationToItemViewFunc={n => <ControlNotification notification={n} />}
									/>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CompanyGeneral;
