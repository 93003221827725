import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EditCompany } from '../../../DataTypes/Edit/EditCompany';
import { MyProfile } from '../../../DataTypes/MyProfile';
import Spinner from '../../UI/Spinner/Spinner';
import Classes from './CompanyDetailsTable.module.scss';
import cn from 'classnames';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const CompanyDetailsTable = props => {
	const [loading, setLoading] = useState(true);
	/**@type {[MyProfile, any]} */
	const [currentUser, setCurrentUser] = useState(MyProfile.Current);
	/**@type {[EditCompany, any]} */
	const [editCompany, setEditCompany] = useState(new EditCompany(props.company));
	const [showModal, setShowModal] = useState(false);
	const { ShowModal } = useContext(LoadingModalContext);

	useEffect(() => {
		setLoading(false);
	});

	const modalHandler = () => {
		setShowModal(true);
	};

	const closeHandler = () => {
		setShowModal(false);
	};

	const handleChange = e => {
		editCompany.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		setShowModal(false);
		ShowModal(props.company.UpdateDetails(editCompany), CurrentDict.CompanyDetailsUpdate);
	};

	const modal = (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={showModal} onHide={closeHandler} centered dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>עדכון פרטי החברה</div>
				<div className={Classes.modalForm}>
					<form className={Classes.detailsForm} onSubmit={handleSubmit}>
						<br />
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>שם החברה</label>
							<input name='name' className={Classes.input} type='text' defaultValue={editCompany?.name ?? ''} onChange={handleChange} required></input>
						</div>
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>ח"פ</label>
							<input name='sn' className={Classes.input} type='text' readOnly defaultValue={editCompany?.sn ?? ''} onChange={handleChange} disabled></input>
						</div>
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>כתובת</label>
							<input name='officeAddress' className={Classes.input} type='text' defaultValue={editCompany?.officeAddress ?? ''} onChange={handleChange} required></input>
						</div>
						<div className={cn(Classes.row, Classes.topMargin)}>
							<label className={Classes.detailsKey}>איש קשר</label>
							<input name='managerName' className={Classes.input} type='text' defaultValue={editCompany?.manager?.name ?? ''} onChange={handleChange} required></input>
						</div>
						<div className={Classes.row}>
							<label className={Classes.detailsKey}>טלפון</label>
							<input
								name='managerPhone'
								className={Classes.input}
								type='tel'
								pattern='[0][5][0-9]{8}'
								title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
								defaultValue={editCompany?.manager?.phone ?? ''}
								onChange={handleChange}
								required></input>
						</div>
						<br></br>

						<div className={Classes.modalFooter}>
							<Modal.Header className={Classes.closeMobile} style={{ border: 'none' }} closeButton>
								<p>ביטול</p>
							</Modal.Header>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
								עדכון
							</Button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
	if (loading || !props.company) {
		return <Spinner />;
	}
	return (
		<div className={Classes.card} dir='rtl'>
			<span className={Classes.cardTitle}>פרטי החברה</span>
			<table className={cn('table', 'table-borderless', Classes.mobileTable)}>
				<thead>
					<tr>
						<th scope='col'>שם החברה</th>
						<th scope='col'>ח"פ</th>
						<th scope='col'>משרדים</th>
						<th scope='col'>איש קשר</th>
						<th scope='col'>טלפון</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{props.company.name}</td>
						<td>{props.company.sn}</td>
						<td>{props.company.officeAddress}</td>
						<td>{props.company.manager?.name}</td>
						<td>{props.company.manager?.phone}</td>
						<td hidden={!currentUser?.permissions.companyCRUD.update} className={Classes.tcontrols}>
							<div role='button'>
								<img onClick={modalHandler} src={require('../../../assets/icons/edit.svg')} className={Classes.icon}></img>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			{showModal ? modal : null}
		</div>
	);
};

export default CompanyDetailsTable;
