import React, { useState, useContext } from 'react';
import Classes from './CraneDetailModal.module.scss';
import { Modal } from 'react-bootstrap';

const CraneDetailModal = props => {
	const [value, setValue] = useState('');

	return (
		<Modal size='md' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.closeHandler} dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>פרטים נוספים</div>
				<div className={Classes.detailsParagraph}>
					<br></br>
					<div className={Classes.crane}>
						<div>
							<span className={Classes.detailsKey}>כתובת</span>
							<span className={Classes.detailsValue}>{props.crane.site.address}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>מנהל אתר</span>
							<span className={Classes.detailsValue}>{props.crane.site.manager.name}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>טלפון</span>
							<span className={Classes.detailsValue}>{props.crane.site.manager.phone}</span>
						</div>
						<hr></hr>

						<div>
							<span className={Classes.detailsKey}>גובה מנוף</span>
							<span className={Classes.detailsValue}>{props.crane.height + ' מטר'}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>אורך זרוע</span>
							<span className={Classes.detailsValue}>{props.crane.length + ' מטר'}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>גובל כבל</span>
							מ-
							<span className={Classes.detailsValue}>{value}</span>
							עד-
							<span className={Classes.detailsValue}>{value}</span>
							מטר
						</div>
						<div>
							<span className={Classes.detailsKey}>גובל סיבוב</span>
							מ-
							<span className={Classes.detailsValue}>{value}</span>
							עד-
							<span className={Classes.detailsValue}>{value}</span>
							מעלות
						</div>
						<div>
							<span className={Classes.detailsKey}>גובל עגלה</span>
							מ-
							<span className={Classes.detailsValue}>{value}</span>
							עד-
							<span className={Classes.detailsValue}>{value}</span>
							מטר
						</div>
						<hr></hr>
						<div>
							<span className={Classes.detailsKey}>יצרן</span>
							<span className={Classes.detailsValue}>{props.crane.manufacturer}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>בבעלות</span>
							<span className={Classes.detailsValue}>{props.crane.company.name}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>קופסה שחורה</span>
							<span className={Classes.detailsValue}>{props.crane.blackboxId}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>כתובת IP</span>
							<span className={Classes.detailsValue}>{value}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>מספר גרסה</span>
							<span className={Classes.detailsValue}>{value}</span>
						</div>
						<hr></hr>

						<div>
							<span className={Classes.detailsKey}>טסט אחרון</span>
							<span className={Classes.detailsValue}>{value}</span>
						</div>
						<div>
							<span className={Classes.detailsKey}>טסט הבא</span>
							<span className={Classes.detailsValue}>{value}</span>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CraneDetailModal;
