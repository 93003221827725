import React, { useState, useContext } from 'react';

import Classes from './NewSite.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { EditSite } from '../../../DataTypes/Edit/EditSite';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const NewSite = props => {
	/**@type {[EditSite, any]} */
	const [editSite, setEditSite] = useState(new EditSite());
	const { ShowModal } = useContext(LoadingModalContext);

	const handleChange = e => {
		editSite.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.closeHandler();
		ShowModal(props.hierarchy.CreateSite(editSite), CurrentDict.SiteCreate);
	};

	const createSelectCompanies = () => {
		let items = [];
		props.hierarchy.companies.map(c =>
			items.push(
				<option key={c.id} value={c.id}>
					{c.name}
				</option>
			)
		);
		return items;
	};

	return (
		<div>
			<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.isModalOpen} onHide={props.closeHandler} centered dir='rtl'>
				<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

				<Modal.Body className={Classes.modalBody}>
					<div className={Classes.title}>{'הוספת אתר'}</div>
					<div className={Classes.modalForm}>
						<form onSubmit={handleSubmit}>
							<br />

							<div>
								<label className={Classes.modalKey}>{'שם האתר'}</label>
								<input name='name' className={Classes.input} type='text' onChange={handleChange} required></input>
							</div>
							<div>
								<label className={Classes.modalKey}>{'כתובת'}</label>
								<input name='address' className={Classes.input} type='text' onChange={handleChange}></input>
							</div>
							<div className={Classes.company}>
								<label className={Classes.modalKey}>{'בבעלות חברת'}</label>
								<select name='company' onChange={handleChange} required>
									<option disabled selected value=''>
										{'בחר'}
									</option>
									{createSelectCompanies()}
								</select>
							</div>
							<div>
								<label className={Classes.modalKey}>{'מנהל האתר'}</label>
								<input name='managerName' className={Classes.input} type='text' onChange={handleChange} required></input>
							</div>
							<div>
								<label className={Classes.modalKey}>{'טלפון'}</label>
								<input
									name='managerPhone'
									className={Classes.input}
									type='tel'
									pattern='[0][5][0-9]{8}'
									title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
									onChange={handleChange}
									required></input>
							</div>
							<br></br>
							<br></br>
							<div className={Classes.modalFooter}>
								<Modal.Header className={Classes.closeMobile} style={{ border: 'none' }} closeButton>
									<p>ביטול</p>
								</Modal.Header>
								<Button variant='outline-primary' type='submit' className={Classes.button}>
									{'הוסף אתר'}
								</Button>
							</div>
							
							<br></br>
							<br></br>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default NewSite;
