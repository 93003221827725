import { Crane } from '../DataTypes/Crane';

class CraneView {
	/**@type {Crane}*/
	crane;

	/**@type {'Connected' | 'Started' | 'Disconnected'} */
	craneStatus;

	/**@param {Crane} crane*/
	constructor(crane) {
		this.crane = crane;
		this.craneStatus = !crane.deviceStatus?.isConnected ? 'Disconnected' : crane.deviceStatus?.isPowered ? 'Started' : 'Connected';
	}

	getBorderColor() {
		switch (this.craneStatus) {
			case 'Disconnected':
				return 'red-border';
			case 'Connected':
				return 'gray-border';
			case 'Started':
				return 'green-border';

			default:
				return 'red-border';
		}
	}
}

export default CraneView;
