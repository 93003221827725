import React, { useState, useEffect } from 'react';
import { DateToString, DateToHourMinutes, DateToDayMonthYear, CraneActivityTimeCalc, hoursPerMonthCalc } from '../../../services/services';
import { Row, Col } from 'react-bootstrap';

import Classes from '../CraneActivity/CraneActivity.module.scss';
import cn from 'classnames';
import LineChart from '../../Charts/LineChart/LineChart';
import { OperationSummary } from '../../../DataTypes/OperationSummary';
import CraneView from '../../../ViewModels/CraneView';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron.svg';

/**
 *
 * @param {Object} props
 * @param {CraneView} props.view
 */
const CraneActivity = props => {
	const [hoursPerMonth, setHoursPerMonth] = useState(null);
	const [loading, setLoading] = useState(true);
	const [value, setValue] = useState('לא ידוע');
	/**@type {[OperationSummary[], any]} */
	const [operationSummaryArr, setOperationSummaryArr] = useState(null);

	const fakeOperationSummary = [
		{
			deviceId: 1,
			date: new Date(),
			id: 1,
			createdTime: new Date(),
			intervals: [
				{ start: new Date('2021-04-01T00:00:00Z'), end: new Date('2021-04-30T00:00:00Z') },
				{ start: new Date('2021-05-01T00:00:00Z'), end: new Date('2021-05-30T00:00:00Z') },
			],
			secondsOfOperation: 20,
		},
		{
			deviceId: 2,
			date: new Date(),
			id: 2,
			createdTime: new Date(),
			intervals: [{ start: new Date('2021-03-01T00:00:00Z'), end: new Date('2021-03-30T00:00:00Z') }],
			secondsOfOperation: 20,
		},
	];

	useEffect(() => {
		props.view.crane.GetActivitySummary(new Date().getFullYear()).then(
			response => {
				const arr = response.data.sort((a, b) => (a.date < b.date ? 1 : -1)).map(x => new OperationSummary(x));
				setOperationSummaryArr(arr);
				setHoursPerMonth(hoursPerMonthCalc(arr));
				setLoading(false);
			},
			reason => {
				console.log(reason);
			}
		);
	}, []);

	const yearSelect = e => {
		props.view.crane.GetActivitySummary(parseInt(e.target.value)).then(
			response => {
				const arr = response.data.sort((a, b) => (a.date < b.date ? 1 : -1)).map(x => new OperationSummary(x));
				setOperationSummaryArr(arr);
				setHoursPerMonth(hoursPerMonthCalc(arr));
				setLoading(false);
			},
			reason => {
				console.log(reason);
			}
		);
	};

	return (
		<div className={Classes.wrapperCraneActivity}>
			<div className={Classes.bodyCraneActivity}>
				<Row className={Classes.mobileReverse}>
					<Col xl={7}>
						<div className={Classes.card}>
							<span className={Classes.activityTitle}>היסטוריית שיוכי מנוף</span>
							<div className={Classes.activityTable}>
								<CraneActivityTable operationSummaryArr={operationSummaryArr}></CraneActivityTable>
								{/* <CraneActivityTable operationSummaryArr={fakeOperationSummary}></CraneActivityTable> */}
							</div>
						</div>
					</Col>
					<Col xl={5}>
						<Row>
							<Col xl={12}>
								<div className={Classes.card}>
									<div className={Classes.activityGraph}>
										<div className={Classes.title}>ממוצע שעות חודשי</div>
										<LineChart data={hoursPerMonth} />
										<hr />
										<div className={Classes.footerCardLine}>
											<div className={Classes.footerYear}>
												<form>
													<select
														name='year'
														onChange={e => {
															yearSelect(e);
														}}>
														<option value={parseInt(new Date().getFullYear())}>{new Date().getFullYear()}</option>
														<option value={parseInt(new Date().getFullYear() - 1)}>{new Date().getFullYear() - 1}</option>
														<option value={parseInt(new Date().getFullYear() - 2)}>{new Date().getFullYear() - 2}</option>
													</select>
												</form>
											</div>
											<div className={Classes.footerTitle}>{`סה"כ ${(hoursPerMonth?.reduce((a, b) => a + b, 0) / 12).toFixed(1)} שעות בממוצע`}</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xl={12}>
								<div className={Classes.card}>
									<span className={Classes.activityTitle}>תיעוד פעילות</span>
									<div className={Classes.activityHistory}>
										<div className={Classes.title}>היסטוריית מיקומי מנוף</div>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default CraneActivity;

export const CraneActivityTable = props => {
	const [openActivityIds, setOpenActivity] = useState([]);

	const toggleOpenActivityId = id => {
		if (window.innerWidth >= 1200) return;

		if (openActivityIds.includes(id)) {
			setOpenActivity(openActivityIds.filter(_id => _id !== id));
		} else {
			setOpenActivity([...openActivityIds, id]);
		}
	};

	const dateFormat = date => {
		return date.toISOString().slice(0, 10).split('-').join('/');
	};

	return (
		<table className={cn('table', 'table-borderless', Classes.tableBorderless, Classes.mobileActivityTable)}>
			<thead>
				<tr>
					<th scope='col'>תאריך</th>
					<th scope='col'>הופעל</th>
					<th scope='col'>נכבה</th>
					<th scope='col'>שם מפעיל</th>
					<th scope='col'>סה"כ</th>
				</tr>
			</thead>
			<tbody>
				{props.operationSummaryArr?.map((x, xIndex) =>
					x.intervals.map((i, iIndex) => (
						<>
							<tr className={Classes.labelInfo} onClick={() => toggleOpenActivityId('' + xIndex + iIndex)}>
								<td>
									{dateFormat(i.start)} - {dateFormat(i.end)}
								</td>
								<td>
									<ChevronIcon className={cn(openActivityIds.includes('' + xIndex + iIndex) && Classes.open)} />
								</td>
							</tr>
							<tr className={cn(Classes.hiddenInfo, openActivityIds.includes('' + xIndex + iIndex) && Classes.open)}>
								<td>
									<span className={Classes.hiddenTitle}>תאריך</span>
									<span>{DateToDayMonthYear(x.date)}</span>
								</td>
								<td>
									<span className={Classes.hiddenTitle}>הופעל</span>
									<span>{DateToHourMinutes(i.start)}</span>
								</td>
								<td>
									<span className={Classes.hiddenTitle}>נכבה</span>
									<span>{DateToHourMinutes(i.end)}</span>
								</td>
								<td>
									<span className={Classes.hiddenTitle}>שם מפעיל</span>
									<span>{'-'}</span>
								</td>
								<td>
									<span className={Classes.hiddenTitle}>סה"כ</span>
									<span>{CraneActivityTimeCalc(i.start, i.end)}</span>
								</td>
							</tr>
						</>
					))
				)}
			</tbody>
		</table>
	);
};
