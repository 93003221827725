import React, { useState, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';

import Classes from './SiteCranesTable.module.scss';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron-rounded.svg';
import cn from 'classnames';

const SiteCranesTable = props => {
	const [isLoading, setIsLoading] = useState(true);

	const [openCraneIds, setOpenCraneIds] = useState([]);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	const toggleOpenCraneId = id => {
		if (window.innerWidth >= 1200) return;

		if (openCraneIds.includes(id)) {
			setOpenCraneIds(openCraneIds.filter(_id => _id !== id));
		} else {
			setOpenCraneIds([...openCraneIds, id]);
		}
	};

	if (isLoading || !props.site) {
		return <Spinner />;
	}
	return (
		<div className={Classes.card} dir='rtl'>
			<span className={Classes.cardTitle}>מנופים פעילים</span>
			<table className={cn('table', 'table-borderless', Classes.mobileTable)}>
				<thead className={Classes.thead}>
					<tr>
						<th scope='col'>מספר</th>
						<th scope='col'>דגם</th>
						<th scope='col'>סוג מנוף</th>
						<th scope='col'>מספר סידורי</th>
						<th scope='col'>יצרן</th>
						<th scope='col'>קופסה שחורה</th>
					</tr>
				</thead>
				<tbody className={Classes.tbody}>
					{props.site.cranes.map((crane, index) => {
						return (
							<tr key={index} className={cn(Classes.tcard, openCraneIds.includes(crane.id) && Classes.open)}>
								<td className={Classes.tname} onClick={() => toggleOpenCraneId(crane.id)}>
									<ChevronIcon />
									<span>{crane.number}</span>
								</td>
								<td>
									<span className={Classes.theaders}>דגם</span>
									<span>{crane.model}</span>
								</td>
								<td>
									<span className={Classes.theaders}>סוג מנוף</span>
									<span>{crane.type}</span>
								</td>
								<td>
									<span className={Classes.theaders}>מספר סידורי</span>
									<span>{crane.id}</span>
								</td>
								<td>
									<span className={Classes.theaders}>יצרן</span>
									<span>{crane.manufacturer}</span>
								</td>
								<td>
									<span className={Classes.theaders}>קופסה שחורה</span>
									<span>{crane.blackboxId}</span>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default SiteCranesTable;
