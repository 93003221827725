import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Route, NavLink } from 'react-router-dom';

import Classes from './Company.module.css';
import cn from 'classnames';
import CompanyGeneral from './CompanyGeneral/CompanyGeneral';
import CompanyCranesStatus from './CompanyCranesStatus/CompanyCranesStatus';
import CompanyDetails from './CompanyDetails/CompanyDetails';
import DistributionListTable from '../Tables/DistributionListTable/DistributionListTable';
import Spinner from '../UI/Spinner/Spinner';
import CompanyView from '../../ViewModels/CompanyView';
import HierarchyView from '../../ViewModels/HierarchyView';
import SteakyHeader from '../SteakyHeader/SteakyHeader';

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 * @param {CompanyView} props.view
 * @param {any} props.match
 */
const Company = props => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
	});

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className={Classes.wrapperCrane}>
			<Container fluid>
				<h3 className="container-title"><SteakyHeader>{props.view.company.name}</SteakyHeader></h3>
				<Row>
					<Col md={4}>
						<div className={Classes.headerCrane}>
							<ul className="container-tabs">
								<li>
									<NavLink exact to={'/company/' + props.match.params.id} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										כללי
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/company-cranes-status'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										סטטוס מנופים
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/company-details'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										פרטי החברה
									</NavLink>
								</li>
								<li>
									<NavLink to={props.match.url + '/company-distribution-list'} className={Classes.link} activeClassName={cn(Classes.active, 'container-active-link')}>
										רשימת תפוצה
									</NavLink>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
			<Route exact path={'/company/' + props.match.params.id} render={() => <CompanyGeneral hierarchyView={props.hierarchyView} view={props.view} />} />
			<Route path={props.match.url + '/company-cranes-status'} render={() => <CompanyCranesStatus view={props.view} />} />
			<Route path={props.match.url + '/company-details'} render={() => <CompanyDetails company={props.view.company} />} />
			<Route path={props.match.url + '/company-distribution-list'} render={() => <DistributionListTable item={props.view.company} />} />
		</div>
	);
};

export default Company;
