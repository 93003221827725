import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import Classes from './CranePositionTable.module.scss';
import cn from 'classnames';
import CranePositionModal from '../../Modals/CranePositionModal/CranePositionModal';

const CranePositionTable = props => {
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setLoading(false);
	});

	const showModalHandler = () => {
		const show = showModal;
		setShowModal(!show);
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className={Classes.card} dir='rtl'>
			<span className={Classes.cardTitle}>{'שיוך המנוף'}</span>
			<table className={cn('table table-borderless ', Classes.moveCraneTable, Classes.mobileTable)}>
				<thead>
					<tr>
						<th scope='col'>חברה</th>
						<th scope='col'>אתר פעילות</th>
						<th scope='col'>כתובת האתר</th>
						<th scope='col'>מס' מנוף</th>
						<th scope='col'>סטטוס</th>
						<th scope='col'>מנהל אחראי</th>
						<th scope='col'>טלפון</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<span className={Classes.mobileTitle}>חברה</span>
							<span>{props.crane.company.name}</span>
						</td>
						<td>
							<span className={Classes.mobileTitle}>אתר פעילות</span>
							<span>{props.crane.site.name}</span>
						</td>
						<td>
							<span className={Classes.mobileTitle}>כתובת האתר</span>
							<span>{props.crane.site.address}</span>
						</td>
						<td>
							<span className={Classes.mobileTitle}>מס' מנוף</span>
							<span>{props.crane.number}</span>
						</td>
						<td>
							<span className={Classes.mobileTitle}>סטטוס</span>
							<span>{props.crane.GetStatus()}</span>
						</td>
						<td>
							<span className={Classes.mobileTitle}>מנהל אחראי</span>
							<span>{props.crane.site.manager?.name}</span>
						</td>
						<td>
							<span className={Classes.mobileTitle}>טלפון</span>
							<span>{props.crane.site.manager?.phone}</span>
						</td>
						<td>
							<div role='button'>
								<img onClick={showModalHandler} src={require('../../../assets/icons/edit.svg')} className={Classes.craneIcon} />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			{showModal ? <CranePositionModal hierarchy={props.hierarchy} crane={props.crane} showModal={showModal} showModalHandler={showModalHandler} /> : null}
		</div>
	);
};

export default CranePositionTable;
