import { Phone } from "./MyProfile";

export class DistributionListItem {
	/**@type {string} */
	id = null;

	/**@type {string} */
	fullName = null;

	/**@type {string} */
	company = null;

	/**@type {string} */
	role = null;

	/**@type {Phone} */
	phone = null;

	/**@type {Date} */
	createdTime = null;

	constructor(data) {
		this.id = data?.id;
		this.fullName = data?.fullName;
		this.company = data?.company;
		this.role = data?.role;
		this.phone = new Phone(data?.phone);
		this.createdTime = data?.createdTime;
	}
}
