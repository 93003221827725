import React, { useState, useContext } from 'react';
import Classes from './CraneDetails.module.scss';
import CraneDetailsTable from '../../Tables/CraneDetailsTable/CraneDetailsTable';
import CranePositionTable from '../../Tables/CranePositionTable/CranePositionTable';
import CraneView from '../../../ViewModels/CraneView';
import HierarchyView from '../../../ViewModels/HierarchyView';

/**
 *
 * @param {Object} props
 * @param {HierarchyView} props.hierarchyView
 * @param {CraneView} props.view
 */
const CraneDetails = props => {
	return (
		<div className={Classes.wrapper}>
			<CranePositionTable hierarchy={props.hierarchyView.hierarchy} crane={props.view.crane}></CranePositionTable>
			<CraneDetailsTable hierarchy={props.hierarchyView.hierarchy} crane={props.view.crane}></CraneDetailsTable>
		</div>
	);
};

export default CraneDetails;
