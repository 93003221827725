export class DeviceStatus {
	/**@type {string} */
	deviceId = '';

	/**@type {boolean} */
	isConnected = null;

	/**@type {boolean} */
	isPowered = null;

	/**@type {boolean} */
	isReleased = null;

	/**@type {boolean} */
	isLocked = null;

	/**@type {boolean} */
	isOperatorPresent = null;

	/**@type {Date} */
	lastOperated = null;

	/**@type {Wind} */
	wind = null;

	/**@type {AnalogInputValue[]} */
	analogInputValues = [];

	/**@type {DigitalInputValue[]} */
	digitalInputValues = [];

	/**@type {DigitalFeedback[]} */
	digitalFeedbacks = [];

	constructor(data) {
		this.deviceId = data.deviceId;
		this.isConnected = data.isConnected;
		this.isPowered = data.isPowered;
		this.isReleased = data.isReleased;
		this.isLocked = data.isLocked;
		this.isOperatorPresent = data.isOperatorPresent;

		this.lastOperated = new Date(data.lastOperated);

		this.analogInputValues = data.analogInputValues.map(i => new AnalogInputValue(i));
		this.digitalInputValues = data.digitalInputValues.map(i => new DigitalInputValue(i));
		this.digitalFeedbacks = data.digitalFeedbacks.map(i => new DigitalFeedback(i));

		this.wind = new Wind(this.GetInputValueInUnits('Wind'), this.GetInputValueInUnits('Wind_direction'));
	}

	/** Finds input value by name
	 * @param {string} name
	 * @returns {number} The value in units for the InputValue
	 */
	GetInputValueInUnits(name, fallbackValue = 0) {
		let found = this.analogInputValues.find(x => x.name == name);
		if (found == null) {
			return fallbackValue;
		}

		return found.valueInUnits;
	}
}

class Wind {
	/**@type {number} */
	speed = null;

	/**@type {number} */
	direction = null;

	constructor(speed, direction) {
		this.speed = speed;
		this.direction = direction;
	}
}

class AnalogInputValue {
	/**@type {string} */
	name = '';

	/**@type {number} */
	value01 = null;

	/**@type {number} */
	valueInUnits = null;

	constructor(data) {
		this.name = data.name;
		this.value01 = data.value01;
		this.valueInUnits = data.valueInUnits;
	}
}

class DigitalInputValue {
	/**@type {string} */
	name = '';

	/**@type {boolean} */
	value = false;

	constructor(data) {
		this.name = data.name;
		this.value = data.value;
	}
}

class DigitalFeedback {
	/**@type {string} */
	name = '';

	/**@type {number} */
	speed = 0;

	constructor(data) {
		this.name = data.name;
		this.speed = data.speed;
	}
}
