import React, { useState, useContext } from 'react';
import Classes from './NewCompany.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { EditCompany } from '../../../DataTypes/Edit/EditCompany';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';

const NewCompany = props => {
	/**@type {[EditCompany, any]} */
	const [editCompany, setEditCompany] = useState(new EditCompany());
	const { ShowModal } = useContext(LoadingModalContext);

	const handleChange = e => {
		editCompany.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.closeHandler();
		ShowModal(props.hierarchy.CreateCompany(editCompany), CurrentDict.CompanyCreate);
	};

	return (
		<Modal size='sm' className={Classes.modal} aria-labelledby='contained-modal-title-vcenter' show={props.isModalOpen} onHide={props.closeHandler} centered dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.title}>הוספת חברה</div>
				<div className={Classes.formContainer}>
					<form className={Classes.modalForm} onSubmit={handleSubmit}>
						<br />
						<div>
							<label className={Classes.modalKey}>שם החברה</label>
							<input name='name' className={Classes.input} type='text' onChange={handleChange} required></input>
						</div>
						<div>
							<label className={Classes.modalKey}>ח"פ</label>
							<input name='sn' className={Classes.input} type='text' pattern='[5][1][0-9]{7}' title="נא הזן מספר ח''פ תקין, לדוג': 510000000" onChange={handleChange} required></input>
						</div>
						<div className={Classes.address}>
							<label className={Classes.modalKey}>כתובת</label>
							<input name='officeAddress' className={Classes.input} type='text' onChange={handleChange}></input>
						</div>
						<div>
							<label className={Classes.modalKey}>איש קשר</label>
							<input name='managerName' className={Classes.input} type='text' onChange={handleChange} required></input>
						</div>
						<div>
							<label className={Classes.modalKey}>טלפון</label>
							<input
								name='managerPhone'
								className={Classes.input}
								type='tel'
								pattern='[0][5][0-9]{8}'
								title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
								onChange={handleChange}
								required></input>
						</div>
						<br />
						<div className={Classes.modalFooter}>
							<Modal.Header className={Classes.closeMobile} style={{ border: 'none' }} closeButton>
								<p>ביטול</p>
							</Modal.Header>
							<Button variant='outline-primary' type='submit' className={Classes.button}>
								הוסף חברה
							</Button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default NewCompany;
