import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Classes from './ReadNotification.module.scss';
import { ReactComponent as AlertIcon } from '../../../assets/icons/notification-alert-circle.svg';

const ReadNotification = props => {
	return (
		<div className={Classes.notificationCard}>
			<Row className={Classes.card}>
				<Col className={Classes.right} md={1}>
					<div className={Classes.rightSection}>
						<AlertIcon className={Classes.alertIcon} />
						<div className={Classes.crane}>{' מנוף ' + props.notification?.crane?.number}</div>
						<div className={Classes.site}>{props.notification.crane?.site?.name}</div>
					</div>
				</Col>
				<Col className={Classes.left} md={11}>
					<div className={Classes.leftSection}>
						<div className={Classes.content}>{props.notification?.data}</div>
						<div className={Classes.time}>{props.notification?.createdTime}</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default ReadNotification;
