import React, { useEffect, useState } from 'react';
import styles from './Snapshots.module.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Snapshots.css';
import Spinner from '../UI/Spinner/Spinner';

// const imagesArr = [
// 	{
// 		original: 'https://picsum.photos/id/1018/1000/600/',
// 		thumbnail: 'https://picsum.photos/id/1018/250/150/',
// 	},
// 	{
// 		original: 'https://picsum.photos/id/1015/1000/600/',
// 		thumbnail: 'https://picsum.photos/id/1015/250/150/',
// 	},
// 	{
// 		original: 'https://picsum.photos/id/1019/1000/600/',
// 		thumbnail: 'https://picsum.photos/id/1019/250/150/',
// 	},
// ];

/**
 * Desc
 * @param {Object} props
 * @param {()=>Promise<string[]>} props.snapshotsPromise
 * @returns
 */
const Snapshots = props => {
	const [images, setImages] = useState(null);

	useEffect(() => {
		async function fetchSnapshotImages() {
			const imagePaths = await props.snapshotsPromise();
			const images = imagePaths?.map(img => {
				return { original: img, thumbnail: img };
			});
			setImages(images);
		}

		fetchSnapshotImages();
	}, []);

	if (!images || images?.length === 0) {
		return <Spinner />;
	}
	return (
		<div className={styles.snapshotsWrapper}>
			<ImageGallery items={images} isRTL showFullscreenButton={false} showPlayButton={false} additionalClass={styles.image} />
		</div>
	);
};

export default Snapshots;
