import { Crane } from '../DataTypes/Crane';
import { OperationSummary } from '../DataTypes/OperationSummary';

/**
 * convert UTC date to string
 *  @param {Date} date *
 *  @returns {string} **/
export function DateToString(date) {
	if (!date) {
		return '';
	}
	return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}   ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() - 2000}`;
}

/**
 * convert UTC date to {HH:MM} string
 *  @param {Date} date *
 *  @returns {string} **/
export function DateToHourMinutes(date) {
	if (!date) {
		return '';
	}
	return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
}

/**
 * convert UTC date to {DD/MM/YYYY} string
 *  @param {Date} date *
 *  @returns {string} **/
export function DateToDayMonthYear(date) {
	if (!date) {
		return '';
	}
	return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() - 2000}`;
}

/**
 * calculating hours between two dates
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {number}
 */
export function CraneActivityTimeCalc(startTime, endTime) {
	let res = (endTime.getTime() - startTime.getTime()) / (1000 * 60);
	if (res < 60) {
		return res.toFixed(0) + ' דקות ';
	} else return (res / 60).toFixed(1) + ' שעות ';
}

/**
 * filtering DeviceIds from crane array
 * @param {Crane[]} cranes
 * @return {string[]}
 */
export function ReturnCranesDeviceIds(cranes) {
	return cranes.filter(c => c.deviceId != null).map(c => c.deviceId);
}

/**
 * return months array with operation summary of each month
 * @param {OperationSummary[]} arr
 * @return {number[]}
 */
export function hoursPerMonthCalc(arr) {
	let hoursPerMonth = [];

	for (let i = 0; i < 12; i++) {
		let hours = 0;

		arr?.filter(x => x.date.getMonth() == i).forEach(e => {
			hours += e.secondsOfOperation / 3600;
		});

		hoursPerMonth.push(hours);
	}
	return hoursPerMonth;
}

/**
 * converting number to direction string
 * @param {number} direction
 */
export function Compass(direction) {
	while (direction >= 360) direction -= 360;
	Math.abs(direction);
	if (direction == 0) return ' צפון ';
	else if (direction > 0 && direction < 90) return ' צפון-מזרח ';
	else if (direction == 90) return ' מזרח ';
	else if (direction > 90 && direction < 180) return ' דרום-מזרח ';
	else if (direction == 180) return ' דרום ';
	else if (direction > 180 && direction < 270) return ' דרום-מערב ';
	else if (direction == 270) return ' מערב ';
	else if (direction > 270 && direction < 360) return ' צפון-מערב ';
}
