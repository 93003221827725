import React, { useState, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import { Notification } from '../../../DataTypes/Notification';
import ReadNotification from '../../Notification/ReadNotification/ReadNotification';
import Classes from './NotificationsPage.module.scss';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import NotificationList from '../../Notification/NotificationList/NotificationList';
import { NotificationManager } from '../../../DataTypes/NotificationManager';
import { Button } from 'react-bootstrap';
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg';
import SteakyHeader from '../../SteakyHeader/SteakyHeader';

const NotificationsPage = props => {
	/**@type {[boolean, *]} */
	const [isLoading, setIsLoading] = useState(true);

	/**@type {[boolean, *]} */
	const [loadingMore, setLoadingMore] = useState(false);

	/**@type {[Number, *]} */
	const [skip, setSkip] = useState(0);

	/**@type {[Hierarchy, *]} */
	const [hierarchy, setHierarchy] = useState(props.hierarchy);

	/**@type {[Notification[], *]} */
	const [notifications, setNotifications] = useState(null);

	/**@type {[boolean, *]} */
	const [showFilter, setShowFilter] = useState(false);

	useEffect(() => {
		setHierarchy(props.hierarchy);
		hierarchy.notificationManager.newNotificationsCount = 0;
		setNotifications([
			...props.hierarchy.notificationManager.notifications,
		]);
		hierarchy.notificationManager.NotificationReceived.AddHandler(
			NotificationReceived
		);
		updateLastReadingTime();
		setIsLoading(false);

		return () => {
			hierarchy.notificationManager.NotificationReceived.RemoveHandler(
				NotificationReceived
			);
			updateLastReadingTime();
		};
	}, []);

	const updateLastReadingTime = () => {
		hierarchy.notificationManager.lastViewDate = new Date();
	};

	const NotificationReceived = (s, n) => {
		notifications.push(n);
	};

	const loadOldNotifications = async () => {
		setLoadingMore(true);
		const amount = 10;

		setNotifications(
			notifications.concat(
				await NotificationManager.FetchLast(
					props.hierarchy.cranes,
					amount,
					skip
				)
			)
		);
		setLoadingMore(false);
		setSkip(skip + amount);
		scroll({ top: 99999 });
		setShowFilter(true);
	};

	if (isLoading) {
		return <Spinner />;
	} else {
		return (
			<div className={Classes.main}>
				<h3><SteakyHeader>התראות</SteakyHeader></h3>
				{showFilter ? <p className={Classes.filter}><Filter/> סינון</p> : null}
				{
					<NotificationList
						xxx={2}
						notifications={notifications}
						notificationToItemViewFunc={n => (
							<ReadNotification notification={n} />
						)}
					/>
				}
				{loadingMore ? (
					<Spinner />
				) : (
					<Button
						variant='outline-primary'
						className={Classes.button}
						onClick={loadOldNotifications}>
						טען התראות קודמות
					</Button>
				)}
			</div>
		);
	}
};

export default NotificationsPage;
