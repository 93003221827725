import React, { useState, useContext } from 'react';
import Classes from './CraneEditDetailsModal.module.scss';
import { Button, Modal } from 'react-bootstrap';
import { EditCrane } from '../../../DataTypes/Edit/EditCrane';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { CurrentDict } from '../../../services/LocaleDictionary';
import cn from 'classnames';

const CraneEditDetailsModal = props => {
	/**@type {[EditCrane,any]} */
	const [editCrane, setEditCrane] = useState(new EditCrane(props.crane));

	const { ShowModal } = useContext(LoadingModalContext);

	const handleChange = e => {
		editCrane.Edit(e.target.name, e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.showEditModalHandler();
		ShowModal(props.crane.UpdateDetails(editCrane), CurrentDict.CraneUpdate);
	};

	return (
		<Modal size='sm' className={Classes.editDetailsModal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.showEditModalHandler} centered dir='rtl'>
			<Modal.Header className={Classes.modalHeader} style={{ border: 'none' }} closeButton />

			<Modal.Body className={Classes.modalBody}>
				<div className={Classes.modalTitle}>{'עדכון פרטי מנוף'}</div>
				<form className={cn(Classes.modalForm, Classes.detailsForm)} onSubmit={handleSubmit}>
					<div>
						<label className={Classes.detailsKey}>{'סוג מנוף'}</label>
						<select name='type' className={Classes.detailsValue} defaultValue={editCrane?.type ?? ''} onChange={handleChange} required>
							<option value='' disabled>
								{'בחר'}
							</option>
							<option value='Tower'>{'עגורן'}</option>
							<option value='Feeder'>{'פידר'}</option>
							<option value='Other'>{'אחר'}</option>
						</select>
					</div>
					<div>
						<label className={Classes.detailsKey}>{'סטטוס מנוף'}</label>
						<select name='status' className={Classes.detailsValue} defaultValue={editCrane?.status ?? ''} onChange={handleChange} required>
							<option value='' disabled>
								{'בחר'}
							</option>
							<option value='Active'>{'פעיל'}</option>
							<option value='Inactive'>{'לא פעיל'}</option>
							<option value='Fault'>{'תקול'}</option>
						</select>
					</div>
					<div>
						<label className={Classes.detailsKey}>{'דגם'}</label>
						<input name='model' type='text' defaultValue={editCrane?.model ?? ''} onChange={handleChange} required></input>
					</div>
					<div>
						<label className={Classes.detailsKey}>{'שם היצרן'}</label>
						<input name='manufacturer' type='text' defaultValue={editCrane?.manufacturer ?? ''} onChange={handleChange} required></input>
					</div>
					<div>
						<label className={Classes.detailsKey}>{'גובה מנוף'}</label>
						<input name='height' type='number' defaultValue={editCrane?.height ?? ''} onChange={handleChange} min='0' max='500'></input>
						מטר
					</div>
					<div>
						<label className={Classes.detailsKey}>{'אורך זרוע'}</label>
						<input name='length' type='number' defaultValue={editCrane?.length ?? ''} onChange={handleChange} min='0' max='100'></input>
						מטר
					</div>
					{/* <div>
							<label className={Classes.detailsKey}>{"גובל כבל"}</label>
							{"מ-"}
							<input
								name='liftMinLimit'
								type='number'
								defaultValue={value}
								onChange={handleChange}
								min={0}
								max={form.maxCableLimit ?? 100}></input>
							{"עד-"}
							<input
								name='liftMaxLimit'
								type='number'
								defaultValue={value}
								onChange={handleChange}
								min={form.minCableLimit ?? 0}
								max={100}></input>
							{"מטר"}
						</div>
						<div>
							<label className={Classes.detailsKey}>{"גובל סיבוב"}</label>
							{"מ-"}
							<input name='turnMinLimit' type='number' defaultValue={value} onChange={handleChange} min={-720} max={720}></input>
							{"עד-"}
							<input name='turnMaxLimit' type='number' defaultValue={value} onChange={handleChange} min={-720} max={720}></input>
							{"מעלות"}
						</div>
						<div>
							<label className={Classes.detailsKey}>{"גובל עגלה"}</label>
							{"מ-"}
							<input
								name='cartMinLimit'
								type='number'
								defaultValue={value}
								onChange={handleChange}
								min={0}
								max={form.maxCartLimit ?? 100}></input>
							{"עד-"}
							<input
								name='cartMaxLimit'
								type='number'
								defaultValue={value}
								onChange={handleChange}
								min={form.minCartLimit ?? 0}
								max={100}></input>
							{"מטר"}
						</div>
						<div>
							<label className={Classes.detailsKey}>{"טסט אחרון"}</label>
							<input name='lastTest' type='date' defaultValue={value} onChange={handleChange}></input>
						</div>
						<div>
							<label className={Classes.detailsKey}>{"טסט הבא"}</label>
							<input name='nextTest' type='date' defaultValue={value} onChange={handleChange}></input>
						</div> */}
					<div className={Classes.modalFooter}>
						<p onClick={props.showEditModalHandler}>ביטול</p>
						<Button variant='outline-primary' type='submit' className={Classes.button}>
							{'עדכון'}
						</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default CraneEditDetailsModal;
