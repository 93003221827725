/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { MyProfile } from '../../../DataTypes/MyProfile';
import { NotificationConfig } from '../../../DataTypes/NotificationConfig';
import Spinner from '../../UI/Spinner/Spinner';
import Classes from './UserProfile.module.scss';
import ApiResponseModal from '../../UI/ApiResponseModal/ApiResponseModal';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import cn from 'classnames';
import SteakyHeader from '../../SteakyHeader/SteakyHeader';

/**
 * Displays current users profile info
 * @param {Object} props
 * @param {Hierarchy} props.hierarchy
 */
const UserProfile = props => {
	/**@type {[boolean, *]} */
	const [isLoading, setIsLoading] = useState(null);

	/**@type {[boolean, *]} */
	const [configLoading, setConfigLoading] = useState(null);

	/**@type {[MyProfile, *]} */
	const [myProfile, setMyProfile] = useState(MyProfile.Current);

	/**@type {[NotificationConfig, *]} */
	const [notificationConfig, setNotificationConfig] = useState(null);

	//Find users company
	let userCompany = props.hierarchy.companies.find(x => x.organization == props.hierarchy.id);

	useEffect(() => {
		setMyProfile(MyProfile.Current);

		axios.get('/NotificationConfig').then(response => {
			setNotificationConfig(Object.assign(new NotificationConfig(), response.data));
			setConfigLoading(false);
			setIsLoading(false);
		});
	}, []);

	// onClick={editButtonClickedHandler}

	const detailsParagraph = (
		<div className={Classes.detailsParagraph}>
			<div className={Classes.header}>
				<span className={Classes.title}>פרטים אישיים</span>
				{/* <span role='button' className={Classes.editButton} > 
						<span className={Classes.materialIcons + " material-icons"}>create</span>
						עריכה
					</span> */}
			</div>
			<br></br>
			<div className={Classes.details}>
				<div>
					<span className={Classes.detailsKey}>שם מלא</span>
					<span className={Classes.detailsValue}>{myProfile.fullName}</span>
				</div>
				<div>
					<span className={Classes.detailsKey}>אימייל</span>
					<span className={Classes.detailsValue}>{myProfile.mail}</span>
				</div>
				<div>
					<span className={Classes.detailsKey}>חברה</span>
					<span className={Classes.detailsValue}>{userCompany?.name ?? '-'}</span>
				</div>
				<div>
					<span className={Classes.detailsKey}>תפקיד</span>
					<span className={Classes.detailsValue}>{myProfile.role}</span>
				</div>
				<div>
					<span className={Classes.detailsKey}>טלפון</span>
					<span className={Classes.detailsValue}>{myProfile.phone?.number}</span>
				</div>
			</div>
		</div>
	);

	// const detailsForm = (
	// 	<Modal>
	// 		<div className={Classes.detailsForm}>
	// 			<div className={Classes.title}>פרטים אישיים</div>
	// 			<form action=''>
	// 				<br />
	// 				<div>
	// 					<label className={Classes.detailsKey}>שם מלא </label>
	// 					<input
	// 						className={Classes.detailsValue}
	// 						type='text'
	// 						name='fullName'
	// 						defaultValue={myProfile.fullName}
	// 						onChange={e => {
	// 							setState({ value: e.target.value });
	// 						}}></input>
	// 				</div>
	// 				<div>
	// 					<label className={Classes.detailsKey}>אימייל</label>
	// 					<input
	// 						className={Classes.detailsValue}
	// 						type='text'
	// 						name='mail'
	// 						defaultValue={myProfile.mail}></input>
	// 				</div>
	// 				<div>
	// 					<label className={Classes.detailsKey}>חברה</label>
	// 					<input type='text' defaultValue=''></input>
	// 				</div>
	// 				<div>
	// 					<label className={Classes.detailsKey}>תפקיד</label>
	// 					<input
	// 						type='text'
	// 						defaultValue={myProfile.role}></input>
	// 				</div>
	// 				<div>
	// 					<label className={Classes.detailsKey}>טלפון</label>
	// 					<input
	// 						type='tel'
	// 						name='phone'
	// 						pattern='[0][5][0-9]{8}'
	// 						title="נא הכנס מס' טלפון תקין, דוג' [0521122334]"
	// 						defaultValue={myProfile.phone?.number}></input>
	// 				</div>
	// 				<br></br>
	// 				<div
	// 					role='button'
	// 					className={Classes.saveButton}
	// 					onClick={saveButtonClickedHandler}>
	// 					שמירה
	// 				</div>
	// 				<br></br>
	// 			</form>
	// 		</div>
	// 	</Modal>
	// );

	// const configParagraph = configLoading ? (
	// 	<Spinner />
	// ) : (
	// 	<div className={Classes.configParagraph}>
	// 		<div className={Classes.title}>הגדרות</div>
	// 		<div className={Classes.subtitle}>התראות</div>
	// 		<div className={Classes.checkboxParagraph}>
	// 			<input
	// 				type='checkbox'
	// 				className={Classes.checkbox}
	// 				checked={notificationConfig?.isSendEmail}></input>
	// 			<span>אימייל</span>
	// 			<input
	// 				type='checkbox'
	// 				className={Classes.checkbox}
	// 				checked={notificationConfig?.isSendSms}></input>
	// 			<span>הודעת טקסט</span>
	// 		</div>
	// 		<div className={Classes.subtitle}>סיסמא</div>

	// 		<div className={Classes.password}>
	// 			<a href=''>שינוי סיסמא</a>
	// 		</div>
	// 	</div>
	// );

	if (isLoading) {
		return <Spinner />;
	} else {
		return (
			<div className={Classes.wrapper}>
				<Row className={Classes.mainDiv}> 
					<Col lg={4} className={Classes.col}>
						<h3><SteakyHeader>פרופיל</SteakyHeader></h3>
						<div className={Classes.card}>{detailsParagraph}</div>
						{/* <div className={Classes.card}>{configParagraph}</div> */}
						<ApiResponseModal title={'title'} message={'message'} type={'success'} />
					</Col>
				</Row>
			</div>
		);
	}
};

export default UserProfile;
