import React, { useEffect, useState } from 'react';
import Classes from './SteakyHeader.module.scss';

const SteakyHeader = props => {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }, []);

    return (
        <>
            <div className={offset > 100 ? Classes.steakyHeader : null}>
                {props.children}
            </div>
        </>
    )
}

export default SteakyHeader