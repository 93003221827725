import { Blackbox } from "./Blackbox";
import axios from "axios";

export class License {
	/**@type {string} */
	deviceId = null;

	/**@type {"Full" | "Feeder" | "Lite"} */
	type = null;

	/**@type {String} */
	id = null;

	/**@type {number} */
	durationInDays = null;

	/**@type {string} */
	createdTime = null;

	/**@type {BlackboxBinding} */
	blackboxBinding = null;

	constructor(data) {
		this.deviceId = data.deviceId;
		this.type = data.type;
		this.id = data.id;
		this.durationInDays = data.durationInDays;
		this.createdTime = data.createdTime;
		this.blackboxBinding = data.blackboxBinding;
	}

	BindBlackbox(blackboxId) {
		return axios.post(
			`/License/${this.id}/Blackbox/${blackboxId}/BindBlackbox`
		);
	}

	UnbindBlackbox() {
		return axios.post(
			`/License/${this.id}/Blackbox/${this.blackboxBinding.blackbox.id}/UnbindBlackbox`
		);
	}

	BindCrane(blackboxId, companyId, siteId, craneId) {
		return axios.post(
			`/License/${this.id}/Blackbox/${blackboxId}/BindCrane/${companyId}/${siteId}/${craneId}`
		);
	}

	UnbindCrane(blackboxId, companyId, siteId, craneId) {
		return axios.post(
			`/License/${this.id}/Blackbox/${blackboxId}/unbindCrane/${companyId}/${siteId}/${craneId}`
		);
	}
}

/**
 * @typedef {Object} CraneBinding
 * @property {string} craneId
 * @property {string} bindTime
 */

/**
 * @typedef {Object} BlackboxBinding
 * @property {Blackbox} blackbox
 * @property {string} bindTime
 * @property {CraneBinding} craneBinding
 */
