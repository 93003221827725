import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Classes from './SiteDetails.module.scss';
import SiteCranesTable from '../../Tables/SiteCranesTable/SiteCranesTable';
import SiteDetailsTable from '../../Tables/SiteDetailsTable/SiteDetailsTable';

const SiteDetails = props => {
	return (
		<div className={Classes.wrapper}>
			<div className={Classes.table}>
				<Row>
					<Col lg={12} xl={10}>
						<SiteDetailsTable site={props.site}></SiteDetailsTable>
					</Col>
				</Row>
			</div>
			<div className={Classes.table}>
				<Row>
					<Col lg={12} xl={10}>
						<SiteCranesTable site={props.site}></SiteCranesTable>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SiteDetails;
