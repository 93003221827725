import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Registration from './components/Registration/Registration';
import * as Auth from './auth';

if (window.location.pathname == '/Invite') {
	let params = new URL(window.location).searchParams;
	let token = params.get('token');
	ReactDOM.render(<Registration token={token} />, document.getElementById('root'));
} else {
	const acc = Auth.MsalObj.getAccount();
	if (acc) {
		ReactDOM.render(<App account={acc} />, document.getElementById('root'));
	} else {
		Auth.LoginRedirect();
	}

	// Auth.LoginRedirect()
	// 	.then(loginResponse => {
	// 		console.log('loginResponse:');
	// 		console.log(loginResponse);

	// 		const acc = Auth.MsalObj.getAccount();
	// 		if (acc) {
	// 			ReactDOM.render(<App account={acc} />, document.getElementById('root'));
	// 		}
	// 	})
	// 	.catch(error => {
	// 		console.log('login error:');
	// 		console.log(error);

	// 		// Error handling
	// 		if (error.errorMessage) {
	// 			// Check for forgot password error
	// 			// Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
	// 			if (error.errorMessage.indexOf('AADB2C90118') > -1) {
	// 				Auth.ForgotPasswordPopup().then(loginResponse => {
	// 					console.log(loginResponse);
	// 					window.location = window.location;
	// 				});
	// 			}
	// 		}
	// 	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
